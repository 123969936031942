import React, { useState, useEffect } from "react";
import { Row, Col, Image, Card, Button } from "antd";
import { FaUnlock, FaLock } from "react-icons/fa";
import * as getServices from "../../services/getServices";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const RecentCourse = () => {
    const userData = useSelector((state) => state.user.userData);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [courses, setCourses] = useState([]);
    const [paymentDetails, setPaymentDetails] = useState([]);

    useEffect(() => {
        dispatch(getServices?.getCourses(""))
            .then((response) => setCourses(response?.data))
            .catch((err) => console.error({ err }));

        if (userData?.user?.id) {
            dispatch(getServices?.getPaymentDetails(userData?.user?.id))
                .then((response) => setPaymentDetails(response?.payments))
                .catch((err) => console.error({ err }));
        }
    }, [dispatch, userData?.user?.id]);



    const DateFormatter = (date) => {
        const dateObj = new Date(date);
        const monthNames = [
            "January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];

        return `${dateObj.getDate()} ${monthNames[dateObj.getMonth()]} ${dateObj.getFullYear()}`;
    };

    const sortedCourses = courses.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

    const handlePurchase = (courseData) => {
        navigate(`/payment/${userData?.user_id}/${courseData?.id}`, { state: { courseData } });
    };

    const handleCourseData = (courseData) => {
        navigate(`/course/${courseData?.id}/${courseData?.title}`, { state: { course_id: courseData?.id } });
    };
   
    return (
        <div className="recentCourseWrapper">
            {sortedCourses.length > 0 && (
                <div className="container">
                    <Row className="headerRow">
                        <Col md={24}>
                            <div className="header">
                                <h4 className="border-bottom-heading bottom-border-center">Recently Added</h4>
                            </div>
                        </Col>
                    </Row>
                    <Row className="mainContent-recent-course">
                        <Col md={12} className="leftSection px-1">
                            <Card>
                                <div className="imgWrapper">
                                    {sortedCourses[0]?.parent_category_name ? (
                                        <h5 className="main-category">{sortedCourses[0]?.parent_category_name}</h5>
                                    ) : (<></>)}
                                    <Image
                                        src={sortedCourses[0]?.image || "https://foundr.com/wp-content/uploads/2023/04/How-to-create-an-online-course.jpg.webp"}
                                        alt="Not Found"
                                        className="img-fluid"
                                        preview={false}
                                    />
                                    {sortedCourses[0]?.trial === 1 ? (
                                        <div className="trail-available-container">
                                            <p className="trial-available-text">Trial Available</p>
                                        </div>
                                    ) : (<></>)}

                                    <div className="start-end-date-container">
                                        <p className="start-enddate">{`${DateFormatter(sortedCourses[0]?.course_start)} - ${DateFormatter(sortedCourses[0]?.course_end)}`}</p>
                                    </div>
                                    <div className="cart-lock-div">
                                        {paymentDetails.some((item) => item?.course_id === sortedCourses[0]?.id) ? (
                                            <FaUnlock color="#fff" />
                                        ) : (
                                            <FaLock color="#fff" />
                                        )}
                                    </div>
                                </div>
                                <div className="infoSection">
                                    <div className="text">

                                        <h3>{sortedCourses[0]?.title}</h3>

                                        {sortedCourses[0]?.approach === 1 ? <Button>Online</Button> : sortedCourses[0]?.approach === 2 ? <Button>Physical</Button> : sortedCourses[0]?.approach === 3 ? <Button>Combined</Button> : ""}

                                        <p>{sortedCourses[0]?.about_course}</p>
                                    </div>
                                    {sortedCourses[0]?.parent_category_name ? (
                                        <div className="tags">
                                            <span className="one">{sortedCourses[0]?.parent_category_name}</span>
                                        </div>
                                    ) : (<></>)}
                                    <div className="link">
                                        <Button className="view-course-recent-course-button" onClick={() => handleCourseData(sortedCourses[0])}>View Details</Button>
                                    </div>
                                </div>
                            </Card>
                        </Col>

                        <Col md={12} className="rightSection px-1">
                            <div className="splitRowWrapper">
                                {sortedCourses[1] && (
                                    <div className="topSection">
                                        <Card>
                                            <div className="imgWrapper">
                                                {sortedCourses[1]?.parent_category_name ? (
                                                    <h5 className="main-category">{sortedCourses[1]?.parent_category_name}</h5>
                                                ) : (<></>)}
                                                <Image
                                                    src={sortedCourses[1]?.image || "https://foundr.com/wp-content/uploads/2023/04/How-to-create-an-online-course.jpg.webp"}
                                                    alt="Not Found"
                                                    className="img-fluid"
                                                    preview={false}
                                                />
                                                {sortedCourses[1]?.trial === 1 ? (
                                                    <div className="trail-available-container">
                                                        <p className="trial-available-text">Trial Available</p>
                                                    </div>
                                                ) : (<></>)}

                                                <div className="start-end-date-container">
                                                    <p className="start-enddate">{`${DateFormatter(sortedCourses[1]?.course_start)} - ${DateFormatter(sortedCourses[1]?.course_end)}`}</p>
                                                </div>
                                                <div className="cart-lock-div">
                                                    {paymentDetails.some((item) => item?.course_id === sortedCourses[1]?.id) ? (
                                                        <FaUnlock color="#fff" />
                                                    ) : (
                                                        <FaLock color="#fff" />
                                                    )}
                                                </div>
                                            </div>
                                            <div className="rightCardSection">
                                                <div className="text">
                                                    <h4>{sortedCourses[1]?.title}</h4>
                                                </div>
                                                {sortedCourses[1]?.approach === 1 ? <Button>Online</Button> : sortedCourses[1]?.approach === 2 ? <Button>Physical</Button> : sortedCourses[1]?.approach === 3 ? <Button>Combined</Button> : ""}
                                                <div className="tags">
                                                    {sortedCourses[1]?.parent_category_name ? (
                                                        <span className="one">{sortedCourses[1]?.parent_category_name}</span>
                                                    ) : (<></>)}
                                                </div>
                                                <div className="link">
                                                    <Button className="view-course-recent-course-button" onClick={() => handleCourseData(sortedCourses[1])}>View Details</Button>
                                                </div>
                                            </div>
                                        </Card>
                                    </div>
                                )}

                                {sortedCourses[2] && (
                                    <div className="BottomSection">
                                        <Card>
                                            <div className="imgWrapper">
                                                {sortedCourses[2]?.parent_category_name ? (

                                                    <h5 className="main-category">{sortedCourses[2]?.parent_category_name}</h5>
                                                ) : (<></>)}
                                                <Image
                                                    src={sortedCourses[2]?.image || "https://chennai.vit.ac.in/wp-content/uploads/2021/06/Cyber-Security-Certification-Course-Computer-Science-Certification-Courses.jpg"}
                                                    alt="Not Found"
                                                    className="img-fluid"
                                                    preview={false}
                                                />
                                                {sortedCourses[2]?.trial === 1 ? (
                                                    <div className="trail-available-container">
                                                        <p className="trial-available-text">Trial Available</p>
                                                    </div>
                                                ) : (<></>)}

                                                <div className="start-end-date-container">
                                                    <p className="start-enddate">{`${DateFormatter(sortedCourses[2]?.course_start)} - ${DateFormatter(sortedCourses[2]?.course_end)}`}</p>
                                                </div>
                                                <div className="cart-lock-div">
                                                    {paymentDetails.some((item) => item?.course_id === sortedCourses[2]?.id) ? (
                                                        <FaUnlock color="#fff" />
                                                    ) : (
                                                        <FaLock color="#fff" />
                                                    )}
                                                </div>
                                            </div>

                                            <div className="rightCardSection">
                                                <div className="text">
                                                    <h4>{sortedCourses[2]?.title}</h4>
                                                </div>
                                                {sortedCourses[2]?.approach === 1 ? <Button>Online</Button> : sortedCourses[2]?.approach === 2 ? <Button>Physical</Button> : sortedCourses[2]?.approach === 3 ? <Button>Combined</Button> : ""}
                                                {sortedCourses[2]?.parent_category_name ? (
                                                    <div className="tags">
                                                        <span className="one">{sortedCourses[2]?.parent_category_name}</span>
                                                    </div>
                                                ) : (<></>)}
                                                <div className="link">
                                                    <Button className="view-course-recent-course-button" onClick={() => handleCourseData(sortedCourses[2])}>View Details</Button>
                                                </div>
                                            </div>
                                        </Card>
                                    </div>
                                )}
                            </div>
                        </Col>
                    </Row>
                </div>
            )}
        </div>
    );
};

export default RecentCourse;
