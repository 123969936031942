import React, { useEffect, useState } from "react";
import { Avatar } from "../../constant/images";
import { Image, Row, Col, Popover, Modal, Upload, Button, Drawer } from "antd";
import * as updateServices from "../../services/updateServices";
import Sidebar from "../Sidebar/sidebar";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import * as getServices from "../../services/getServices";
import { useNavigate } from "react-router-dom";
import { CgMenuLeft } from "react-icons/cg";
import Notifications from "react-notifications-menu";
import {
    setUserData,
    setUserLoggedIn,
    setVendorLoggedIn,
    setToken
} from "../../store/actions/user";
import {
    CloseOutlined,
} from '@ant-design/icons';
import { FaFileUpload } from "react-icons/fa";
import { FaUser } from "react-icons/fa";
import { RiLockPasswordFill } from "react-icons/ri";
import { IoLogOut } from "react-icons/io5";
import { BellIcon } from "../../constant/images"

const Index = () => {


    const navigate = useNavigate();
    const vendordata = useSelector((state) => state.user.userData);
    const notificationData = useSelector((state) => state.user.notificationData);

    const dispatch = useDispatch();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const [fileList, setFileList] = useState([]);
    const [open, setOpen] = useState(false);
    const [notifications, setNotifications] = useState([]);
    const logo = useSelector((state) => state?.user?.logoData)
    const showDrawer = () => {
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
    };
    const handleChange = ({ fileList: newFileList }) => {
        setFileList(newFileList);
        if (newFileList[0]?.status === "error" || newFileList[0]?.status == "success") {

            onFinish(newFileList);
        }
    };
    useEffect(() => {
        dispatch(getServices.getNotifications())
            .then((response) => {
                const formattedNotifications = response?.data.map(notification => ({
                    ...notification,
                    isRead: false
                }));
                setNotifications(formattedNotifications);
            })
            .catch((err) => {
                console.error({ err });
            });
    }, []);

    const handleMarkAllAsRead = (e) => {
        const isChecked = e.target.checked;
        const newNotifications = notifications.map(notification => ({
            ...notification,
            isRead: isChecked
        }));
        setNotifications(newNotifications);
    };
    const DateFormatter = (date) => {
        const inputDate = date;

        const dateObj = new Date(inputDate);

        const monthNames = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
        ];

        const day = dateObj.getDate();
        const month = monthNames[dateObj.getMonth()];
        const year = dateObj.getFullYear();

        const formatDate = `${day} ${month} ${year}`;
        return formatDate;
    };

    const uploadButton = (
        <div>
            <div className="upload-icon-container">
                <FaFileUpload />
            </div>
            <div className="upload-drag-text" style={{ marginTop: 8 }}>Upload aur drag image</div>
        </div>
    );

    const isVendorLoggedIn = useSelector((state) => state.user.isVendorLoggedIn);

    const handleCancel = () => {

        setIsModalOpen(false);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const showModal = () => {
        if (isVendorLoggedIn) {
            navigate('/vendor/my-profile')
        } else {
            navigate('/user/my-profile')

        }

    };

    const onFinish = (newFileList) => {
        if (!newFileList.length) {
            return;
        }

        const formData = new FormData();
        const userId = vendordata?.user?.id;

        if (!userId) {
            return;
        }
        formData.append("images", newFileList[0]?.originFileObj);

        dispatch(updateServices.updateProfileImage(formData))
            .then(() => {
                setIsModalOpen(false)
                Swal.fire(
                    "Profile Updated Successfully!",
                    "You have successfully updated your profile",
                    "success"
                ).then(() => {
                    if (isVendorLoggedIn) {
                        dispatch(getServices.getVendorDetails(userId))
                            .then((response) => {
                                dispatch(setUserData(response));
                                window.location.reload();
                            })
                            .catch((error) => {
                                console.error("Error updating profile image:", error);
                            });
                    } else {
                        dispatch(getServices.getUserDetails(userId))
                            .then((response) => {
                                dispatch(setUserData(response));
                                window.location.reload();
                            })
                            .catch((error) => {
                                console.error("Error updating profile image:", error);
                            });
                    }
                });
            })
            .catch((error) => {
                setIsModalOpen(false)
                // console.error("Error updating profile image:", error);

                Swal.fire("Failed", error?.response?.data?.message, "erroe");
            });
    };

    const handleLoggout = () => {
        localStorage.removeItem("token");
        dispatch(setToken(null));
        localStorage.removeItem('email');
        localStorage.removeItem('password');
        sessionStorage.removeItem('email');
        sessionStorage.removeItem('password');
        dispatch(setVendorLoggedIn(false));
        dispatch(setUserLoggedIn(false));
        dispatch(setUserData(""));
        localStorage?.removeItem("scrollTo")
        localStorage?.removeItem("activeRoute")
        localStorage?.removeItem("previoursPage")
        localStorage?.removeItem("forgetEmail")
        navigate("/login");
    };

    const renderContent = () => (
        <>
            <div className="edit-profile-container">
                <p className="edit-profile" onClick={showModal}>
                    <span><FaUser /></span>Edit Profile
                </p>
                {isVendorLoggedIn ? (
                    <p className="edit-profile" onClick={() => navigate("/vendor/change-password")}>
                        <span><RiLockPasswordFill /></span> Change Password
                    </p>
                ) : ("")}

                <p className="logout-button" onClick={handleLoggout}>
                    <span><IoLogOut /></span>Logout
                </p>
            </div>
        </>
    );

    const updatedNotifications = (notificationData ? notificationData : notifications)?.map(notification => ({
        message: (
            <>
                <div className="notification-message-container" style={notification?.isRead != true || notificationData?.isRead != true ? { color: '#cecece' } : { color: '#000' }}>
                    <div className="notification-title-time">
                        <p className="notification-title" style={notification?.isRead == true || notificationData?.isRead != true ? { color: '#cecece' } : { color: '#000' }}>{notification?.title}</p>
                        <p className="notification-time">{DateFormatter(notification?.created_at)}</p>
                    </div>
                    <div className="notification-message-container">
                        <p className="notification-message" style={notification?.isRead == true || notificationData?.isRead != true ? { color: '#cecece' } : { color: '#000' }}>{notification?.message}</p>
                    </div>
                </div>
            </>

        ),
        isRead: notification.isRead,

    }));

    return (
        <>
            <div className="topNavContainer">
                <Row className="top-nav-row">
                    <Col className="top-nav-col-one" md={4}>
                        <Image
                            style={{ cursor: "pointer" }}
                            onClick={() => navigate("/")}
                            preview={false}
                            className="well-of-logo-image"
                            src={logo?.front_logo}
                        />
                    </Col>
                    <Col className="top-nav-col-two" md={20}>
                        <div style={{ marginRight: "1rem" }}>
                            <Button onClick={(e) => navigate("/")} style={{ backgroundColor: "#106ab3", color: "#fff" }}> Back to home</Button>
                        </div>
                        <div className="flag-icon-profile-container">
                            <Notifications
                                data={updatedNotifications}
                                header={{
                                    option: {
                                        text: (
                                            <div className="mark-all-container">
                                                <input
                                                    className="mark-all-checkbox"
                                                    type="checkbox"
                                                    onChange={handleMarkAllAsRead}

                                                />
                                                <span className="mark-all-text">Mark all as read.</span>
                                            </div>
                                        ),
                                    },
                                }}
                                icon={BellIcon}
                            />
                            <div className="profile-container">
                                <Popover
                                    content={renderContent()}
                                    title=""
                                    trigger="click"
                                    placement="bottomRight"
                                >
                                    <div className="profile-image-name-container">
                                        <Image
                                            style={{ cursor: "pointer", objectFit: "cover" }}
                                            preview={false}
                                            width={37}
                                            height={37}
                                            src={
                                                vendordata?.user?.images
                                                    ? vendordata?.user?.images
                                                    : vendordata?.vendor?.logo
                                                        ? vendordata?.vendor?.logo
                                                        : Avatar
                                            }
                                        />
                                        <p
                                            style={{ cursor: "pointer" }}
                                            className="owner-name"
                                        >
                                            {vendordata?.user?.name}
                                        </p>

                                    </div>
                                </Popover>


                            </div>
                            <Button className="d-xl-none d-md-block menu-bar-icon" type="primary" onClick={showDrawer}>
                                <CgMenuLeft />
                            </Button>
                            <Drawer className="mobile-menu-dasboard" title="Menu" onClose={onClose} open={open} placement={"left"} key={"left"} width={375}>
                                <Sidebar />
                            </Drawer>
                        </div>
                    </Col>
                </Row>
            </div>
            <div className="modalWrapper">
                <Modal
                    open={isModalOpen}
                    onOk={handleOk}
                    className="imgUploadModal"
                >
                    <div className="modalCloseBtn" onClick={handleCancel}>
                        <CloseOutlined />
                    </div>
                    <div className="modalBody">
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                marginBottom: "1rem",
                            }}
                        >
                            <Upload
                                action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
                                listType="picture-card"
                                fileList={fileList}
                                onChange={handleChange}
                            >
                                {fileList.length >= 1 ? null : uploadButton}
                            </Upload>
                        </div>
                    </div>
                </Modal>
            </div>
        </>
    );
};

export default Index;
