import React, { useState, useEffect } from "react";
import { Tabs, Upload, Modal, Image, Row, Col } from "antd";
import BasicInformation from "./basicInformation";
import SchoolInformation from "./schoolInformation";
import BankingInformations from "./bankingInformations";
import Sidebar from "../../../components/Sidebar/sidebar";
import { useDispatch } from "react-redux";
import * as VendorServices from "../../../services/vendorServices";
import { useSelector } from "react-redux";
import * as getServices from "../../../services/getServices";
import { setUserData } from "../../../store/actions/user";
import Swal from "sweetalert2";
import { Avatar } from "../../../constant/images";
import * as updateServices from "../../../services/updateServices";
import { FiEdit } from "react-icons/fi";

export default function MyProfile() {
	const userData = useSelector((state) => state.user.userData);
	const isVendorLoggedIn = useSelector((state) => state.user.isVendorLoggedIn);

	const dispatch = useDispatch();
	const [activeStep, setActiveStep] = useState("1");
	const [vendorDetails, setVendorDetails] = useState()
	const [basicInfoData, setBasicInfoData] = useState()
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [fileList, setFileList] = useState([]);
	const [userID, setUserID] = useState('');
	const [uploadOnce, setUploadOnce] = useState(true);
	const [loader, setLoader] = useState(false);

	const showModal = () => {
		setIsModalOpen(true);
	};

	const handleOk = () => {
		setIsModalOpen(false);
	};

	const handleCancel = () => {
		setIsModalOpen(false);
	};

	const getUser = () => {
		dispatch(getServices.getVendorDetails(userID))
			.then((response) => {
				dispatch(setUserData(response));
				setIsModalOpen(false)
			})
			.catch((error) => {
				console.error("Error updating profile image:", error);
			});
	}
	const getVendor = () => {
		dispatch(getServices.getUserDetails(userID))
			.then((response) => {
				dispatch(setUserData(response));
				setIsModalOpen(false)
			})
			.catch((error) => {
				console.error("Error updating profile image:", error);
			});
	}
	const onProfileFinish = (newFileList) => {
		if (!newFileList.length) {
			console.error("File list is empty");
			return;
		}

		const formData = new FormData();
		const userId = userData?.user?.id

		if (!userId) {
			console.error("User ID is missing");
			return;
		}

		formData.append("user_id", userId);
		formData.append("images", newFileList[0]?.originFileObj);
		setUserID(userId)
		dispatch(updateServices.updateProfileImage(formData))
			.then((response) => {

				setUploadOnce(false);
				Swal.fire(
					"Profile Updated Successfully!",
					"You have successfully updated your profile",
					"success"
				).then((result) => {
					if (result.isConfirmed) {
						if (isVendorLoggedIn) {
							getUser()
						} else {
							getVendor()
						}
					}
				});
			})
			.catch((error) => {
				console.error("Error updating profile image:", error);
				Swal.fire(
					"Failed",
					error?.response?.data?.message,
					"erroe"
				)

			});
	};

	const onChangeProfileImage = ({ fileList: newFileList }) => {
		setFileList(newFileList);
		if (uploadOnce) {
			onProfileFinish(newFileList);
		}
	};

	const onPreview = async (file) => {
		let src = file.url;
		if (!src) {
			src = await new Promise((resolve) => {
				const reader = new FileReader();
				reader.readAsDataURL(file.originFileObj);
				reader.onload = () => resolve(reader.result);
			});
		}
		const image = new Image();
		image.src = src;
		const imgWindow = window.open(src);
		imgWindow?.document.write(image.outerHTML);
	};


	const onFinish = (bacicInfoData, schoolInfoData, bankInfoData) => {
		setLoader(true)
		let formData;
		formData = {
			name: bacicInfoData?.fullName,
			email: bacicInfoData?.email,
			phone: bacicInfoData?.phone_number,
			school: schoolInfoData?.school_name,
			website_url: schoolInfoData?.website_url,
			address: schoolInfoData?.address,
			logo: "",
			business_type: schoolInfoData?.business_type,
			business_identification_type: schoolInfoData?.business_identification_type,
			business_identification_no: schoolInfoData?.business_identification_no,
			gst: schoolInfoData?.gst,
			category: schoolInfoData?.category,
			sub_category: schoolInfoData?.sub_categories,
			email_notification: schoolInfoData?.email_for_notification,
			about_school: schoolInfoData?.about_school,
		}

		dispatch(VendorServices.vendorUpdate(formData)).then((response) => {
			setLoader(false)
			dispatch(setUserData(response))
			Swal.fire(
				"Updated Successful!",
				"You have successfully updated Your Profile!",
				"success"
			).then((result) => {
				if (result.isConfirmed) {
					getUser()
				}
			});
		}).catch((err) => {
			setLoader(false)
			console.error({ err })
		})
	};

	const goBack = () => {
		if (activeStep === "2") {
			setActiveStep("1");
		} else if (activeStep === "3") {
			setActiveStep("2");
		}
	};

	const goNext = (e) => {
		if (activeStep === "1") {
			setActiveStep("2");
		} else if (activeStep === "2") {
			setActiveStep("3");
		}
	};

	useEffect(() => {
		dispatch(VendorServices.vendorDetails(userData?.user?.id)).then((response) => {
			setVendorDetails(response)
		}).catch((err) => {
			console.error({ err })
		})
	}, [dispatch]);

	const handleBasicInfoData = (data) => {
		setBasicInfoData(data)
		goNext();
	}
	const handleSchoolInfoData = (data, about_school) => {
		data.about_school = about_school;
		onFinish(basicInfoData, data)
	}
	const items = [
		{
			key: "1",
			label: "Basic Information",
			children: (
				<>
					<div className="vendor-profile-outer-conatiner">
						<div className="vendor-profile-conatiner">
							<div className="edit-profile-icon-container" onClick={showModal}>
								<FiEdit color="#fff" className="edit-profile-icon" />
							</div>
							<Image style={{ width: "300px", height: "300px" }} preview={false}
								src={
									userData?.user?.images
										? userData?.user?.images
										: userData?.vendor?.logo
											? userData?.vendor?.logo
											: Avatar}
							/>
						</div>
					</div>
					<BasicInformation onData={handleBasicInfoData} vendorDetails={vendorDetails} />
				</>
			),
		},
		{
			key: "2",
			label: "Organisation  Information",
			children: <SchoolInformation onData={handleSchoolInfoData} loader={loader} vendorDetails={vendorDetails} goBack={goBack} />,
		},
		{
			key: "3",
			label: "Payment Details",
			children: <BankingInformations vendorDetails={vendorDetails} goBack={goBack} />,
		},
	];

	const onChange = (key) => {
		setActiveStep(key);
	};

	return (
		<>
			<Row>
				<Col xl={4} className="d-xl-block d-md-none">
					<Sidebar />
				</Col>
				<Col xl={20} md={24}>
					<div className="mainContent vendor-profile">
						<div className="my-profile-container">
							<Tabs
								activeKey={activeStep}
								className="my-profile-vendor-tab"
								defaultActiveKey="1"
								items={items}
								onChange={onChange}
							/>
							<Modal title="Basic Modal" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} className="profileModel">
								<p>Upload Profile Image</p>
								<Upload
									listType="picture-card"
									fileList={fileList}
									onChange={onChangeProfileImage}
									onPreview={onPreview}
								>
									{fileList.length < 5 && '+ Upload'}
								</Upload>
							</Modal>

						</div>
					</div>
				</Col>
			</Row>
		</>
	);
}
