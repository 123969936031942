import React, { useEffect, useState, useContext } from "react";
import {
    Row,
    Col,
    Input,
    Card,
    Pagination,
    Collapse,
    Image,
    Tag,
    Flex,
    Checkbox,
    Slider,
    Button,
    Drawer,
    Modal,
    InputNumber,
    DatePicker,
    Spin
} from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import { arrowUpRight, featureImg2 } from "../../constant/images";
import * as getServices from "../../services/getServices";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { FaLock } from "react-icons/fa";
import { FaUnlock } from "react-icons/fa";
import { FaRegHeart } from "react-icons/fa6";
import { GoHeartFill } from "react-icons/go";
import { LuFilter } from "react-icons/lu";
import { useLocation, useNavigate } from "react-router-dom";
import * as uploadServices from "../../services/uploadServices";
import Swal from "sweetalert2";
import CourseEnquiry from "../courseEnquiry";
import { add_To_Cart } from "../../store/actions/user";
import { useAtom } from "jotai";
import { cartCount } from "../../Helpers/atoms/freeCoursesAtom"
import { CartContext } from "../../Helpers/CartContext"
import { Logo } from "../../constant/images";
import { IoIosArrowRoundBack } from "react-icons/io";

export default function CourseListing() {
    let { state } = useLocation();
    const userData = useSelector((state) => state.user.userData);
    const categories = useSelector((state) => state.user.categoriesData);
    const isUserLoggedIn = useSelector((state) => state.user.isUserLoggedIn);
    const isVendorLoggedIn = useSelector((state) => state.user.isVendorLoggedIn);
    const cartItems = useSelector((state) => state.user.cart);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { Panel } = Collapse;
    const [current, setCurrent] = useState(1);
    const [courses, setCourses] = useState([]);
    //eslint-disable-next-line
    const [filterCategory, setFilterCategory] = useState([]);
    const [paymentDetails, setPaymentDetails] = useState();
    const [open, setOpen] = useState(false);
    const [distance, setDistance] = useState('');
    const [wishlistData, setWishlistData] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [category, setCategory] = useState([])
    const [subCategoryCategory, setSubCategoryCategory] = useState([])
    const [childCategoryCategory, setChildCategoryCategory] = useState([])
    const [sorting, setSorting] = useState('')
    const [minAge, setMinAge] = useState('');
    const [maxAge, setMaxAge] = useState('');
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [price, setPrice] = useState('')
    const [banner, setBanner] = useState([])
    const [loader, setLoader] = useState(true)
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(6);
    const [showSearchData, setShowSearchData] = useState('')
    const [cartData, setCartData] = useState([]);
    const [searchString, setSearchString] = useState('')
    const [cartCountValue, setCartCountValue] = useAtom(cartCount)
    const { addItemToCart } = useContext(CartContext);
    const [courseDetails, setCourseDetails] = useState([])
    const [selectedKey, setSelectedKey] = useState('');
    const locations = useLocation()
    const { Search } = Input;
    const pageSize = 6;

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };
    const typeofCourse = locations?.state?.type

    const [location, setLocation] = useState({ latitude: null, longitude: null });
    const [error, setError] = useState(null);
    useEffect(() => {
        if (state?.querystring === null || state?.querystring?.includes("paid")) {
            setLoader(true)
            dispatch(getServices.getCourses(state?.querystring ? state?.querystring : "")).then((response) => {
                setLoader(false)
                setShowSearchData(response?.data)
            }).catch((err) => {
                setLoader(false)
                console.error({ err });
            })
        }
    }, [state?.querystring])

    const getLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setLocation({
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude,
                    });
                },
                (error) => {
                    setError(error.message);
                }
            );
        } else {
            setError("Geolocation is not supported by this browser.");
        }
    };

    const getWishlistData = () => {
        dispatch(getServices.GetWistlistDataUser(userData?.user?.id)).then((response) => {
            setWishlistData(response?.course_details)
        }).catch((error) => {
            console.error({ error });
        })
    }

    const fetchCartItems = async () => {
        try {
            const response = await dispatch(getServices.getCartData());
            addItemToCart(response?.data?.length);
            setCartCountValue(response?.data?.length || 0)
            setCartData(response?.data || []);
        } catch (error) {
            console.error("Error fetching cart data", error);
        }
    };

    useEffect(() => {
        getLocation();
        if (isUserLoggedIn) {
            dispatch(
                getServices?.getPaymentDetails(userData?.user?.id)
            )
                .then((response) => {
                    setPaymentDetails(response?.payments);
                })
                .catch((err) => {
                    console.error({ err });
                });
            getWishlistData()
            fetchCartItems()
        }
    }, []);

    useEffect(() => {
        dispatch(getServices?.getBanner('course-listing'))
            .then((result) => {
                setBanner(result?.banners)
                // dispatch(setSettingLogo(result))
            })
            .catch((err) => {
                console.error({ err });
            }).finally(() => {
                setLoader(false)
            })
    }, []);

    const handleWishlistList = (course) => {
        let formData = new FormData();
        formData.append("course_id", course?.id)

        try {

            dispatch(uploadServices.addRemoveCourseWishlist(formData)).then((response) => {
                if (isUserLoggedIn) {
                    getWishlistData()
                }
                Swal.fire(
                    "Success!",
                    `${response?.message}`,
                    "success"
                )
            }).catch((error) => {
                Swal.fire(
                    "Failed",
                    "Unable to add item to wishlist.",
                    "error"
                )
            })

        } catch (e) {
            Swal.fire(
                "Failed",
                "Unable to add course to wishlist.",
                "error"
            )
        }
    }
    useEffect(() => {
        const categoryIds = courses.map((course) => course.category_id);
        const uniqueCategoryIds = [...new Set(categoryIds)];
        setFilterCategory(uniqueCategoryIds);
    }, [courses]);

    useEffect(() => {
        const categoryIds = courses.map((course) => course.category_id);
        const uniqueCategoryIds = [...new Set(categoryIds)];
        setFilterCategory(uniqueCategoryIds);
    }, [courses]);

    const startIndex = (current - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    const displayedCourses = courses.slice(startIndex, endIndex);


    const Category = (category_id) => {
        const category = categories?.find(
            (category) => category?.id === category_id
        );
        return category?.name;
    };

    const settingFilterValues = (id, isChecked, subCategories) => {
        setCategory((prev) => {
            if (isChecked) {
                setSubCategoryCategory((prevChild) => {
                    const updatedSubCategories = [...prevChild];
                    subCategories.forEach(subCat => {
                        if (!updatedSubCategories.includes(subCat.id)) {
                            updatedSubCategories.push(subCat.id);
                        }
                    });
                    return updatedSubCategories;
                });
                return prev.includes(id) ? prev : [...prev, id];
            } else {
                setSubCategoryCategory((prevSubCat) => {
                    return prevSubCat.filter(subCatID => !subCategories.some(subCat => subCat.id === subCatID));
                });
                return prev.filter((item) => item !== id);
            }
        });
    };

    const settingFilterSubCatValues = (subID, isChecked) => {
        setSubCategoryCategory((prev) => {
            let updatedSubCategoryCategory = prev;

            if (isChecked) {
                updatedSubCategoryCategory = prev.includes(subID) ? prev : [...prev, subID];
            } else {
                updatedSubCategoryCategory = prev.filter((item) => item !== subID);
            }
            categories.forEach((parentCategory) => {
                if (parentCategory.sub_categories.some((subCat) => subCat.id === subID)) {
                    const allSelected = parentCategory.sub_categories.every((subCat) =>
                        updatedSubCategoryCategory.includes(subCat.id)
                    );

                    if (!allSelected) {
                        setCategory((prevCategories) => prevCategories.filter((cat) => cat !== parentCategory.id));
                    } else if (isChecked) {
                        setCategory((prevCategories) =>
                            prevCategories.includes(parentCategory.id) ? prevCategories : [...prevCategories, parentCategory.id]
                        );
                    }
                }
            });

            return updatedSubCategoryCategory;
        });
    };

    useEffect(() => {
        setShowSearchData(state?.cartegoryPageData)
        setCategory([])
        setSubCategoryCategory([])
        if (state?.categoryArray?.includes("parent_category")) {
            const queryString = state?.categoryArray;
            const params = new URLSearchParams(queryString);
            const parentCategoryID = params?.get('parent_category')?.replace(/[\[\]]/g, '');
            const parentCategory = categories.find(cat => cat?.id === parseInt(parentCategoryID));
            if (parentCategory) {
                settingFilterValues(parentCategory?.id, true, parentCategory?.sub_categories);
            }
        } else if (state?.categoryArray?.includes("sub_category")) {
            const queryString = state?.categoryArray;
            const params = new URLSearchParams(queryString);
            const subCategoryID = params.get('sub_category')?.replace(/[\[\]]/g, '');
            settingFilterSubCatValues(parseInt(subCategoryID), true);
        } else if (state?.searchString) {
            setSearchString(state?.searchString)
        }
    }, [state]);

    const fetchData = async () => {
        setLoader(true)
        let data = {};

        if (searchString !== '') {
            data.search = searchString
        }
        if (selectedKey) {
            data.approach = selectedKey
        }

        if (category.length > 0) {
            data.parent_category = JSON.stringify(category);
        }
        if (subCategoryCategory.length > 0) {
            data.sub_category = JSON.stringify(subCategoryCategory);
        }
        if (sorting) {
            data.sorting = sorting;
        }
        if (minAge) {
            data.min_age = minAge;
        }
        if (maxAge) {
            data.max_age = maxAge;
        }
        if (startDate) {
            data.start_date = startDate;
        }
        if (endDate) {
            data.end_date = endDate;
        }
        if (distance) {
            data.longitude = location?.longitude
            data.latitude = location?.latitude
            data.distance = distance
        }
        if (price) {
            data.max_fee = price?.maxPrice
            data.min_fee = price?.minPrice
        }
        try {
            let quesryString = state?.querystring === "" || state?.querystring === undefined || state?.querystring === null ? `?` + new URLSearchParams(data).toString() : state?.querystring + `&` + new URLSearchParams(data).toString()
            await dispatch(getServices.getCourses(quesryString))
                .then((response) => {
                    setLoader(false)
                    if (response?.data) {
                        setShowSearchData(response?.data);

                    }
                    else {
                        setLoader(false)
                        Swal.fire({
                            title: response?.message,
                            icon: "error",
                        })
                    }

                }).catch((error) => {
                    setLoader(false)
                    Swal.fire({
                        title: error?.response?.data?.message,
                        icon: "error",
                    })
                })




        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const handleSorting = (label) => {
        setSorting((prev) => (prev === label ? '' : label));

        // fetchData()
    }

    const priceFilter = (min_max_price) => {
        setPrice(min_max_price)
    };

    const handleMinAgeChange = (maxage) => {
        setMinAge(maxage);
    };

    const handleMaxAgeChange = (minage) => {

        setMaxAge(minage);
    };

    const onStartChange = (date, dateString) => {
        setStartDate(dateString)
    };

    const onEndDataChange = (date, dateString) => {
        setEndDate(dateString);
    };
    const applyFilters = () => {
        if (searchString == '') {
            Swal.fire({
                title: "Provide  search term",
                icon: "info",
                allowEnterKey: false,
            });
        } else {
            fetchData();
        }

    };

    const sort = [
        {
            value: "Oldest first ",
            label: "asc",
        },
        {
            value: "Latest first",
            label: "desc",
        }
    ]

    const checkChildCategory = (item) => {
        if (childCategoryCategory?.length > 0) {
            return childCategoryCategory.includes(item);
        }
        return false;
    };

    const checkSubCategory = (item) => {
        if (subCategoryCategory?.length > 0) {
            return subCategoryCategory.includes(item);
        }
        return false;
    };



    const checkedMainCategory = (item) => {
        if (category?.length > 0) {
            return category.includes(item);
        }
    }

    const handleAddToCart = (data, text) => {

        if (!isUserLoggedIn && !isVendorLoggedIn) {
            dispatch(add_To_Cart(data))
        } else {
            const addToCart = (data) => {
                const formData = new FormData()
                formData.append("course_id", data?.id)
                formData.append("course_price", data?.fee ? data?.fee : 0)
                dispatch(uploadServices.addToCart(formData)).then((response) => {
                    if (isUserLoggedIn) {
                        fetchCartItems()
                        if (text === "purchase") {
                            navigate("/checkout")
                        }
                    }
                    Swal.fire(
                        "Success",
                        `${response.message}`,
                        "success"
                    )
                }).catch((error) => {
                    Swal.fire("Failed", error.message);
                })
            }
            addToCart(data)
        }
    }
    const options = [
        { label: 'Online', value: 1 },
        { label: 'Physical', value: 2 },
        { label: 'Combined', value: 3 },
    ];

    const handleApproachChange = (value) => {
        setSelectedKey(value);
    };
    console.log(selectedKey, "selectedKEy")

    const items = [
        {
            key: "1",
            label: "Category",
            children: (
                <>
                    {categories &&
                        categories.map((item) => (
                            <div key={item?.id} className="mb-2">
                                <div className="d-flex align-items-center">
                                    <Checkbox
                                        value={item?.id}
                                        checked={checkedMainCategory(item?.id)}
                                        onChange={(e) => {
                                            settingFilterValues(item?.id, e.target.checked, item.child_category);
                                        }}
                                        style={{ paddingRight: "10px" }}
                                    />
                                    <p style={{ marginBottom: 0 }}>{item?.name}</p>
                                </div>
                                <div className="px-4">
                                    {item?.sub_categories?.map((subCat) => (
                                        <>
                                            <div key={subCat?.id} className="d-flex align-items-center mb-2">
                                                <Checkbox
                                                    value={subCat?.id}
                                                    checked={checkSubCategory(subCat?.id)}
                                                    onChange={(e) => {
                                                        settingFilterSubCatValues(subCat?.id, e.target.checked);
                                                    }}
                                                    style={{ paddingRight: "10px" }}
                                                />
                                                <p style={{ marginBottom: 0 }}>{subCat?.name}</p>
                                            </div>
                                        </>
                                    ))}

                                </div>
                            </div>
                        ))
                    }
                </>
            ),
        },
        {
            key: "2",
            label: "Sort",
            children: (
                <>
                    {sort.map((item) => {
                        return (
                            <div className="col-sm-12 px-3 py-2">
                                <Checkbox key={item.value} onChange={() => handleSorting(item.label)}
                                    checked={sorting === item.label}
                                >
                                    {item.value}
                                </Checkbox>
                            </div>
                        );
                    })}
                </>
            )
        },
        {
            key: "3",
            label: "Price",
            children: (
                <>
                    <Slider range defaultValue={[0, 20]} onChange={(e) => priceFilter({ minPrice: e[0], maxPrice: e[1] })} />
                    <div className="slider-content-container">
                        <p className="slider-des-text">0k</p>
                        <p className="slider-des-text">50k</p>
                    </div>
                </>
            ),
        },
        {
            key: "4",
            label: "Distance",
            children: (
                <>
                    <Slider className="distance-slider" onChange={(e) => setDistance(e)} defaultValue={0} />
                    <div className="slider-content-container">
                        <p className="slider-des-text">5km</p>
                        <p className="slider-des-text">70km</p>
                    </div>
                </>
            ),
        },
        {
            key: "5",
            label: "Age",
            children: (
                <>
                    <div className="row d-flex flex-wrap justify-between">


                        <label className="age-label col-6">
                            <span className="d-block w-100 mb-1">
                                <b> Min Age:</b></span>

                            <InputNumber className="w-100" onChange={handleMinAgeChange} />

                        </label>
                        <label className="age-label col-6">
                            <span className="d-block w-100 mb-1">
                                <b>Max Age:</b></span>
                            <InputNumber className="w-100" onChange={handleMaxAgeChange} />

                        </label>
                    </div>
                </>


            )

        },
        {
            key: "6",
            label: "Date",
            children:
                (
                    <>
                        <div className="row d-flex flex-wrap justify-between">
                            <label className="date-label col-6">
                                <b>Start Date:</b>

                                <DatePicker className="w-100" onChange={onStartChange} />

                            </label>
                            <label className="date-label col-6">
                                <b>End Date:</b>
                                <DatePicker className="w-100" onChange={onEndDataChange} />

                            </label>
                        </div>
                    </>

                )
        },
        {
            key: "7",
            label: "Approach",
            children:
                (
                    <>
                        <div className="row d-flex flex-wrap justify-between">
                            {options.map(option => (
                                <Checkbox
                                    key={option.value}
                                    checked={selectedKey === option.value}
                                    onChange={() => handleApproachChange(option.value)}
                                >
                                    {option.label}
                                </Checkbox>
                            ))}
                        </div>
                    </>

                )
        },
    ];




    const handleCourseData = (values) => {
        navigate(`/course/${values?.id}/${values?.title}`, {
            state: { course_id: values?.id },
        });
    };

    // const handlePurchase = (coursedata) => {
    //     if (isUserLoggedIn || isVendorLoggedIn) {
    //         navigate(`/payment/${userData?.user?.id}/${coursedata?.id}`, {
    //             state: { courseData: coursedata },
    //         });
    //     } else {
    //         navigate("/login");
    //     }
    // };

    const DateFormatter = (date) => {
        const inputDate = date;

        const dateObj = new Date(inputDate);

        const monthNames = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
        ];

        const day = dateObj.getDate();
        const month = monthNames[dateObj.getMonth()];
        const year = dateObj.getFullYear();

        const formatDate = `${day} ${month} ${year}`;
        return formatDate;
    };

    const handleCourseEnquiry = (course) => {

        if (isUserLoggedIn || isVendorLoggedIn) {
            setCourseDetails([{ course_id: course?.id, vendor_id: course?.vendor?.id }])
            setModalOpen(true);
        } else {
            navigate("/login")
        }
    }

    const totalItem = (showSearchData && showSearchData.length > 0) ? showSearchData.length : courses?.length;;
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems =
        showSearchData?.length > 0
            ? showSearchData?.slice(indexOfFirstItem, indexOfLastItem)
            : courses?.slice(indexOfFirstItem, indexOfLastItem)


    const handlePageChange = (page) => {
        setCurrentPage(page);
    };
    const handleBack = () => {
        navigate("/");
    }

    return (
        <>
            <div className="course-listing-container">


                {
                    banner.length > 0 ? (
                        banner.map((item) => (
                            <>

                                <div className="course-listing-banner-container" style={{ backgroundImage: `url(${item?.image})` }} key={item?.id}>

                                    <Button className="back-to-button-course-listing back-to-button" onClick={handleBack}><IoIosArrowRoundBack />Back to home</Button>
                                    <div className="container">
                                        <p className="course-listing-banner-sub-heading">
                                            {item?.title}
                                        </p>
                                        <h2 className="course-listing-banner-heading">{item?.sub_title}</h2>
                                    </div>

                                </div>
                            </>
                        ))
                    ) : (
                        <div className="blog-banner-container">
                            <div className="blog-cover-text">
                                <h2 className="blog-cover-text-heading">Browse All Courses</h2>
                                <p className="blog-cover-text-subhead">
                                    Explore Our Comprehensive Course Catalogue
                                </p>
                            </div>
                        </div>
                    )
                }

                <Row className="courses-with-filter-container customRowComponent">
                    <Col className="course-list-child-one px-4 py-4 pe-5" xl={18} md={24}>
                        <div className="course-list-child-one-head d-flex justify-content-between align-items-center mobile-none">
                            <p class="border-bottom-heading bottom-border-left ">
                                {
                                    typeofCourse === 1
                                        ? 'Free/Trial Items'
                                        : 'All Items'
                                }
                            </p>
                            <div className="right-div d-flex flex-wrap justify-content-end">
                                <Button className="d-xl-none filters-button w-100" type="button" onClick={showDrawer}>
                                    <LuFilter />Filters
                                </Button>

                                <Drawer title="Filters" onClose={onClose} open={open}>
                                    <Row className="courses-with-filter-container-Drawer">
                                        <Col className="course-list-child-two" xl={6} md={24}>


                                            <div className="course-filter-container">
                                                <div className="course-filter-head">
                                                    <p className="course-filter-heading">All Filters</p>
                                                    <Button className="course-filter-count">Apply</Button>
                                                </div>

                                                <Collapse defaultActiveKey={["1"]} className="custom-collapse">
                                                    {items?.map((item) => (
                                                        <Panel
                                                            header={
                                                                <div className="panel-header">
                                                                    <span className="panel-label">{item.label}</span>
                                                                </div>
                                                            }
                                                            key={item.key}
                                                        >
                                                            <p className="panel-description">{item.children}</p>
                                                        </Panel>
                                                    ))}
                                                </Collapse>
                                            </div>
                                        </Col>
                                    </Row>
                                </Drawer>
                                <Search className="search-box" onChange={(e) => setSearchString(e.target.value)} placeholder="Search" onSearch={applyFilters} enterButton />
                            </div>

                        </div>
                        {loader ? (
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}>
                                <Spin indicator={<LoadingOutlined style={{ fontSize: 28 }} spin />} />
                            </div>
                        ) : (
                            currentItems?.length > 0 ? (
                                <>
                                    <Row className="">
                                        {currentItems?.length > 0 &&
                                            currentItems?.map((course, index) => {

                                                return (
                                                    <Col
                                                        key={course?.id || index}
                                                        className={(index + 1) % 2 === 0 ? "ps-3 mb-4 mt-4" : "pe-3 mb-4 mt-4"}
                                                        style={{ padding: "0 0.5rem" }}
                                                        md={12}
                                                    >
                                                        {
                                                            course?.parent_category_name !== null ? <h5 className="main-category mb-0">{course?.parent_category_name}</h5 > : ''
                                                        }
                                                        {
                                                            course?.approach !== 0 ? <h5 className="course-approach mb-0">{course?.approach === 1 ? "Online" : course?.approach === 2 ? "Physical" : "Combined"}</h5 > : ''
                                                        }

                                                        <Card
                                                            className="feature-card"
                                                            hoverable
                                                            cover={
                                                                <img
                                                                    className={course?.approach !== 0 ? "image-radius" : ""}
                                                                    onClick={(e) => handleCourseData(course)}
                                                                    style={{ height: "250px" }}
                                                                    src={course?.image ? course?.image : featureImg2}
                                                                    alt="text"
                                                                />
                                                            }
                                                        >
                                                            <div className="wishlist-icon-div" >
                                                                {
                                                                    isUserLoggedIn ? (
                                                                        <button
                                                                            type="button"
                                                                            className="btn wishlist-feature-button add-to-wishlist"
                                                                            onClick={() => handleWishlistList(course)}
                                                                        >
                                                                            {wishlistData?.some(
                                                                                (wish) => parseInt(wish?.id) === parseInt(course?.id)
                                                                            ) ? (
                                                                                <GoHeartFill />
                                                                            ) : (
                                                                                <FaRegHeart />
                                                                            )}
                                                                        </button>
                                                                    ) : " "
                                                                }

                                                            </div>
                                                            <div>
                                                                <div className="card-position-div">
                                                                    <div
                                                                        style={{
                                                                            zIndex: "99",
                                                                            padding: "10px 15px",
                                                                            backgroundColor: "#106ab3",
                                                                            borderRadius: "0 10px 10px 0",
                                                                        }}
                                                                        className="expire-container"
                                                                    >
                                                                        {paymentDetails?.some(
                                                                            (item) => item?.course_id == course?.id
                                                                        ) ? (
                                                                            <span className="Lock-icon">
                                                                                <FaUnlock color="#fff" />
                                                                            </span>
                                                                        ) : (
                                                                            <span className="Lock-icon">
                                                                                <FaLock color="#fff" />
                                                                            </span>
                                                                        )}
                                                                    </div>
                                                                    <div
                                                                        style={{
                                                                            zIndex: "99",
                                                                            padding: "10px 10px",
                                                                            backgroundColor: "#106ab3",
                                                                            borderRadius: "10px 0 0 10px",
                                                                        }}
                                                                        className="expire-container"
                                                                    >
                                                                        <div className="start-end-date-container">
                                                                            <p
                                                                                style={{ color: "#fff", margin: 0 }}
                                                                                className="start-enddate"
                                                                            >
                                                                                {course?.course_start && course?.course_end
                                                                                    ? `${DateFormatter(course?.course_start)} - ${DateFormatter(
                                                                                        course?.course_end
                                                                                    )}`
                                                                                    : "12 July 2024 - 15 August 2025"}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div style={{ cursor: "pointer" }} onClick={(e) => handleCourseData(course)}>
                                                                    <div style={{ cursor: "pointer" }}>
                                                                        <Flex gap="middle" className="feature-course-subhead-price">

                                                                            <div>
                                                                                {
                                                                                    course?.is_premium == "paid" ? (
                                                                                        <p className="wof-certified-logo"><span><img style={{ width: "20px", height: "20px" }} src={Logo} /></span>WOF Certified</p>
                                                                                    ) : ''
                                                                                }

                                                                                <p
                                                                                    style={{
                                                                                        color: "#106AB3",
                                                                                        fontWeight: 700,
                                                                                    }}
                                                                                >
                                                                                    {Category(course?.category_id)}
                                                                                </p>
                                                                            </div>
                                                                            <div>
                                                                                <p
                                                                                    style={{
                                                                                        color: "#1EAA39",
                                                                                        fontWeight: 700,
                                                                                    }}
                                                                                >


                                                                                    {course?.fee !== 0 && course?.fee !== null ? `$${course.fee}` : 'Free'}
                                                                                </p>
                                                                            </div>
                                                                        </Flex>
                                                                        <Flex className="feature-course-subhead-price">
                                                                            <h4>{course?.title}</h4>
                                                                            <img
                                                                                style={{ width: "25px", height: "auto" }}
                                                                                src={arrowUpRight}
                                                                                alt="text"
                                                                            />
                                                                        </Flex>
                                                                    </div>
                                                                    {/* <Row>
                                                                        <Col span={8}>
                                                                           {course?.approach === 1 ? <Button>Online</Button> : course?.approach === 2 ? <Button>Physical</Button> : course?.approach === 3 ? <Button>Combined</Button> : ""}
                                                                        </Col>
                                                                    </Row> */}
                                                                    <div>
                                                                        <p className="course-description">
                                                                            {course?.about_course !== 'undefined' ? course?.about_course : ''}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <Flex className="feature-course-subhead-price">
                                                                {paymentDetails?.some(
                                                                    (item) => item?.course_id === course?.id
                                                                ) || userData?.vendor?.id === course?.vendor_id ? (
                                                                    <>
                                                                        <button
                                                                            type="button"
                                                                            class="btn feature-button view-course-button"
                                                                            onClick={(e) => handleCourseData(course)}
                                                                        >
                                                                            View Items
                                                                        </button>
                                                                    </>
                                                                ) : (
                                                                    <>

                                                                        {
                                                                            isUserLoggedIn || !isVendorLoggedIn ? (
                                                                                <>
                                                                                    {cartData && cartData.some(cartItem => cartItem?.course?.id === course?.id) ? (
                                                                                        <button
                                                                                            type="button"
                                                                                            className="btn add-to-cart-button"
                                                                                            onClick={() => navigate("/cart")}
                                                                                        >
                                                                                            Go to cart
                                                                                        </button>
                                                                                    ) : (
                                                                                        <button
                                                                                            type="button"
                                                                                            className="btn add-to-cart-button"
                                                                                            onClick={() => handleAddToCart(course)}
                                                                                        >
                                                                                            Add to cart
                                                                                        </button>
                                                                                    )}

                                                                                    {isUserLoggedIn ? (
                                                                                        <button
                                                                                            type="button"
                                                                                            className="btn enroll-button"
                                                                                            onClick={() => handleAddToCart(course, "purchase")}
                                                                                        >
                                                                                            Purchase Now
                                                                                        </button>
                                                                                    ) : (<></>)}
                                                                                </>

                                                                            ) : (
                                                                                <></>

                                                                            )
                                                                        }
                                                                        <button
                                                                            type="button"
                                                                            class="btn add-to-cart-button"
                                                                            onClick={() => handleCourseEnquiry(course)}
                                                                        >
                                                                            Enquiry Now
                                                                        </button>
                                                                    </>
                                                                )}
                                                            </Flex>
                                                        </Card>
                                                    </Col>
                                                );


                                            })}
                                    </Row>
                                    <Pagination
                                        className="mt-4"
                                        current={currentPage}
                                        pageSize={itemsPerPage}
                                        total={totalItem}
                                        onChange={handlePageChange}
                                    />
                                </>
                            ) : (
                                <p>No Items found.</p>
                            )
                        )}

                    </Col>
                    <Col className="course-list-child-two mobile-none" xl={6} md={24}>


                        <div className="course-filter-container">
                            <div className="course-filter-head">
                                <p className="course-filter-heading">All Filters</p>
                                {/* <p className="course-filter-count">Applied (0)</p> */}
                                <Button className="course-filter-count" onClick={fetchData}>Apply</Button>
                            </div>

                            <Collapse defaultActiveKey={["1"]} className="custom-collapse">
                                {items?.map((item) => (
                                    <Panel
                                        header={
                                            <div className="panel-header">
                                                <span className="panel-label">{item.label}</span>
                                            </div>
                                        }
                                        key={item.key}
                                    >
                                        <p className="panel-description">{item.children}</p>
                                    </Panel>
                                ))}
                            </Collapse>
                        </div>


                    </Col>
                </Row>
            </div>
            <Modal
                centered
                open={modalOpen}
                onOk={() => setModalOpen(false)}
                onCancel={() => setModalOpen(false)}
                className="course-enquiry-modal"
            >
                <CourseEnquiry course_details={courseDetails} setModalOpen={setModalOpen} />
            </Modal>

        </>
    );
}
