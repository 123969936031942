// userActions.js
export const setUserType = (userType) => ({
  type: "SET_USER_TYPE",
  payload: userType,
});

export const setUserID = (userID) => ({
  type: "SET_USER_ID",
  payload: userID,
});

export const setUserData = (userData) => ({
  type: "SET_USER_DATA",
  payload: userData,
});

export const setUserLoggedIn = (isLoggedIn) => ({
  type: "IS_USER_LOGGED_IN",
  payload: isLoggedIn,
});

export const setVendorLoggedIn = (isLoggedIn) => ({
  type: "IS_VENDOR_LOGGED_IN",
  payload: isLoggedIn,
});

export const setNotification = (notification) => ({
  type: "NOTIFICATION_DATA",
  payload: notification,
})

export const setToken = (token) => ({
  type: "TOKEN",
  payload: token,
})
export const setSettingLogo = (logo) => ({
  type: "LOGO",
  payload: logo,
})

export const add_To_Cart  = (product) => ({
  type: "ADD_TO_CART",
  payload: product,
})

export const removeFromCart   = (id) => ({
  type: "REMOVE_FROM_CART",
  payload: id,
})


export const setCategoriesData = (categoriesData) => ({
  type: "SET_CATEGORIES_DATA",
  payload: categoriesData,
});

