import React, { useEffect, useState } from 'react'
import Sidebar from '../../components/Sidebar/sidebar';
import { Row, Col, Table, Pagination, Spin, Button } from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import * as getServices from "../../services/getServices";
import { useDispatch, useSelector } from "react-redux"

export default function CourseInquiries() {
    const dispatch = useDispatch();
    const vendordata = useSelector((state) => state.user.userData);
    const pageSize = 5;
    const [currentPage, setCurrentPage] = useState(1);
    const [loader, setLoader] = useState(false)
    const [enquiryData, setEnquiryData] = useState([])

    const DateFormatter = (date) => {
        const inputDate = date;
        const dateObj = new Date(inputDate);
        const monthNames = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
        ];

        const day = dateObj.getDate();
        const month = monthNames[dateObj.getMonth()];
        const year = dateObj.getFullYear();

        const formatDate = `${day} ${month} ${year}`;
        return formatDate;
    };

    const columns = [
        {
            title: "S.No.",
            dataIndex: "sno",
            key: "sno",
            render: (index) => (
                <p className="sno-text">
                    {String(index + 1).padStart(2, "0")}
                </p>
            ),
        },
        {
            title: "Student name",
            dataIndex: "name",
            key: "name",
            render: (text, record, index) => (
                <p className="student-name">
                    {record?.user?.name}
                </p>
            ),
        },
        {
            title: "Student email",
            dataIndex: "email",
            key: "email",
        },
        {
            title: "Course name",
            dataIndex: "course-name",
            key: "course-name",
            render: (text, record, index) => (
                <p className="course-name">
                    {record?.course_name}
                </p>
            ),
        },
        {
            title: "Query subject",
            dataIndex: "type-of-enquiry",
            key: "type-of-enquiry",
            render: (text, record, index) => (
                <p className="type-of-enquiry">
                    {record?.typeOfEnquiry}
                </p>
            ),
        },
        {
            title: "Query message",
            dataIndex: "message",
            key: "message",
        },
        {
            title: "Date of submission",
            dataIndex: "date-of-submission",
            key: "date-of-submission",
            render: (text, record, index) => (
                <p className="date-of-submission">
                    {DateFormatter(record?.created_at)}
                </p>
            ),
        }
    ]

    const handleChangePage = (page) => {
        setCurrentPage(page);
    };

    const fetchEnquiries = () => {
        try {
            setLoader(true)
            dispatch(getServices.getEnquiriesByVendorID(vendordata?.vendor?.id)).then((response) => {
                setLoader(false)
                setEnquiryData(response?.contacts)
            }).catch((err) => {
                setLoader(false)
            })
        } catch (err) {
            console.error("Error fetching enquiries", err)
        }
    }

    useEffect(() => {
        fetchEnquiries()
    }, [])

    const exportToExcel = (data, fileName) => {
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        const excelBuffer = XLSX.write(workbook, {
            bookType: "xlsx",
            type: "array",
        });
        const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
        saveAs(blob, `${fileName}.xlsx`);
    };

    const transformData = (data) => {
        return data.map((item, index) => ({
            "S.No.": index + 1,
            "Student name": item?.user?.name,
            "Student email": item?.user?.email,
            "Course name": item?.course_name,
            "Query subject": item?.typeOfEnquiry,
            "Query message": item?.message,
            "Date of submission": item?.created_at,
        }));
    };
    return (
        <>
            <Row>
                <Col xl={4} className="d-xl-block d-md-none">
                    <Sidebar />
                </Col>
                <Col xl={20} md={24}>
                    <div className="mainContent course-inquiry">
                        <div className='all-enquiry-container'>
                            <div className="download-enquiry-container">
                                <p className='enquiry-heading total-transaction'>All Enquiries</p>
                                <Button
                                    className="download-enquiry-button download-csv-button"
                                    onClick={() => exportToExcel(transformData(enquiryData), "Enquiries")}
                                >
                                    {loader ? <Spin indicator={<LoadingOutlined style={{ color: "#fff" }} spin />} /> : "Download Enquiries in xlsx/csv"}
                                </Button>
                            </div>
                            <div className='enquiry-header d-flex justify-content-between flex-wrap'>
                                <p className='total-enquiries total-transaction-count'>Total Enquiries <span className='total-customer-number'>({enquiryData.length})</span></p>
                            </div>


                        </div>
                        <Table
                            className="all-reviews-table table-responsive"
                            columns={columns}
                            pagination={false}
                            dataSource={enquiryData}
                        />
                        <Pagination
                            className="mt-4"
                            current={currentPage}
                            pageSize={pageSize}
                            onChange={handleChangePage}
                        />
                    </div>
                </Col>
            </Row>
        </>
    )
}
