import React, { useState, useEffect } from "react";
import Sidebar from "../../components/Sidebar/sidebar";
import { Table, Rate, Button, Spin, Row, Col, Pagination, Tooltip } from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from "react-redux";
import * as getServices from "../../services/getServices";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import { FaCircleInfo } from "react-icons/fa6";
export default function AllReviews() {
	const vendorData = useSelector((state) => state.user.userData);
	const dispatch = useDispatch();
	const [reviews, setReviews] = useState([]);
	const [loader, setLoader] = useState(false)
	const pageSize = 5;
	const [currentPage, setCurrentPage] = useState(1);

console.log(vendorData?.vendor?.id , "dataa"
)
	useEffect(() => {
		setLoader(true);
		dispatch(
			getServices?.getReviews(
				vendorData?.vendor?.id
			)
		).then((response) => {
			if (response?.reviews.length > 0) {
				setReviews(response?.reviews);
				setLoader(false);	
			} else {
				setReviews([]);
                setLoader(false);
			}
		
		}).catch((err) => {
			setLoader(false)
		});
	}, []);

	const DateFormatter = (date) => {
		const inputDate = date;

		const dateObj = new Date(inputDate);

		const monthNames = [
			"January",
			"February",
			"March",
			"April",
			"May",
			"June",
			"July",
			"August",
			"September",
			"October",
			"November",
			"December",
		];

		const day = dateObj.getDate();
		const month = monthNames[dateObj.getMonth()];
		const year = dateObj.getFullYear();

		const formatDate = `${day} ${month} ${year}`;
		return formatDate;
	};

	const columns = [
		{
			title: "S.No.",
			dataIndex: "sno",
			key: "sno",
			render: (text, record, index) => (
				<p className="review-sno">
					{String(index + 1 + (currentPage - 1) * pageSize).padStart(2, "0")}
				</p>
			),
		},

		{
			title: "Customer Name",
			dataIndex: "customerName",
			key: "customerName",
			render: (text, record) => (
				<p className="review-customerName">{record?.name}</p>
			),
		},
		{
			title: "Item",
			dataIndex: "course",
			key: "course",
			render: (text, record) => (
				<p className="review-course">{record?.course?.title}</p>
			),
		},
		{
			title: "Reviews",
			dataIndex: "reviews",
			key: "reviews",
			width: "18%",
			sorter: true,
			render: (text, record) => (
				<>
					<p className="review-reviews">{record?.comment}</p>
				</>
			),
		},

		{
			title: "Ratings",
			dataIndex: "ratings",
			key: "ratings",
			filters: [
				{
					text: "5 ratings",
					value: 5,
				},
				{
					text: "4 ratings",
					value: 4,
				},
				{
					text: "3 ratings",
					value: 3,
				},
				{
					text: "2 ratings",
					value: 2,
				},
				{
					text: "1 rating",
					value: 1,
				},
			],
			onFilter: (value, record) => Math.floor(record?.rating) === value,
			render: (text, record) => (
				<div className="review-ratings-container">
					<p className="review-ratings">{(record?.rating % 1) !== 0 ? record?.rating : record?.rating + ".0"}</p>
					<p className="ratings-star">
						<Rate
							className="star-rating-ant"
							disabled={true}
							defaultValue={record?.rating}
						/>
					</p>
				</div>
			),
		},
		{
			title: "Date",
			dataIndex: "created_at",
			key: "date",
			sorter: (a, b) => (a.created_at) - (b.created_at),
			render: (text, record) => (
				<p className="review-date">{DateFormatter(record?.created_at)}</p>
			),
		},

		{
			title: "Status",
			dataIndex: "status",
			key: "status",
			render: (text, record) => (
				<>
					{record?.status === "approved" ? (
						<>
							<p className="approved-review">
								Approved
								<Tooltip
									className="customTooltip"
									placement="topLeft"
									arrow={false}
									title={
										<p className="hover-content">
											Review has been approved by admin.
										</p>
									}
								>
									<FaCircleInfo />
								</Tooltip>
							</p>
						</>
					) : (
						<>
							{record?.status === "rejected" ? (
								<>
									<p className="failed-review">
										Rejected
										<Tooltip
											style={{ background: "black", opacity: "0" }}
											placement="topLeft"
											arrow={false}
											title={
												<p className="hover-content">
													Review has been rejected by admin.
												</p>
											}
										>
											<FaCircleInfo />
										</Tooltip>
									</p>
								</>
							) : (
								<>
									<p className="pending-review">
										Pending
										<Tooltip
											style={{ background: "black", opacity: "0" }}
											placement="topLeft"
											arrow={false}
											title={
												<p className="hover-content">
													Your review is still pending from admin
													side.
												</p>
											}
										>
											<FaCircleInfo />
										</Tooltip>
									</p>
								</>
							)}
						</>
					)}
				</>
			),
		},
	];

	const exportToExcel = (data, fileName) => {
		const worksheet = XLSX.utils.json_to_sheet(data);
		const workbook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
		const excelBuffer = XLSX.write(workbook, {
			bookType: "xlsx",
			type: "array",
		});
		const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
		saveAs(blob, `${fileName}.xlsx`);
	};

	const transformData = (data) => {
		return data.map((item, index) => ({
			"S.NO": index + 1,
			Name: item.user.name,
			Email: item.email,
			Course: item.course.title,
			Review: item.comment,
			Rating: item.rating,
			"Review Date": DateFormatter(item.created_at),
		}));
	};
	const totalItems = reviews && reviews.length > 0 ? reviews.length : 0;
	const sortedCourses = reviews?.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
	const startIndex = (currentPage - 1) * pageSize;
	const endIndex = Math.min(startIndex + pageSize, totalItems);
	const displayedItems = sortedCourses.slice(startIndex, endIndex);
	const handleChangePage = (page) => {
		setCurrentPage(page);
	};

	return (
		<>
			<Row>
				<Col xl={4} className="d-xl-block d-md-none">
					<Sidebar />
				</Col>
				<Col xl={20} md={24}>
					<div className="mainContent vendor-review">
						<div className="all-reviews-container">
							<div className="download-reviews-container">
								<p className="all-reviews-heading">All Reviews</p>
								<Button
									className="download-reviews-button"
									onClick={() => exportToExcel(transformData(reviews), "ratings")}
								>
									{loader ? <Spin indicator={<LoadingOutlined style={{ color: "#fff" }} spin />} size="small" /> : "Download Ratings in xlsx/csv"}
								</Button>
							</div>
							<div className="all-reviews-head-container">
								<p className="total-review">
									Total reviews{" "}
									<span className="total-review-number">({reviews.length})</span>
								</p>
							</div>
							<div className="all-reviews-table-container table-responsive">
								{loader ? (
									<div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}>
										<Spin indicator={<LoadingOutlined style={{ fontSize: 48, }} spin />} />
									</div>
								) : (
									<>
										<Table
											className="all-reviews-table"
											columns={columns}
											pagination={false}

											dataSource={displayedItems}
										/>
										<Pagination
											className="mt-4"
											current={currentPage}
											pageSize={pageSize}
											total={totalItems}
											onChange={handleChangePage}
										/>
									</>
								)}

							</div>
						</div>
					</div>
				</Col>
			</Row>
		</>
	);
}
