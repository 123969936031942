import React, { useEffect, useState, useContext } from "react";
import { Flex, Card, Spin, message, Modal, Row, Col, Button } from "antd";
import * as getServices from "../../services/getServices";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Slider from "react-slick";
import { featureImg2, Logo } from "../../constant/images";
import { FaLock } from "react-icons/fa";
import { FaRegHeart } from "react-icons/fa6";
import { GoHeartFill } from "react-icons/go";
import { FaUnlock } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import * as uploadService from "../../services/uploadServices";
import Swal from "sweetalert2";
import { LoadingOutlined } from '@ant-design/icons';
import { add_To_Cart } from "../../store/actions/user";
import CourseEnquiry from "../courseEnquiry";
import { useAtom } from "jotai";
import { cartCount } from "../../Helpers/atoms/freeCoursesAtom"
import { CartContext } from "../../Helpers/CartContext";

const FeaturesCourses = () => {
    const isUserLoggedIn = useSelector((state) => state.user.isUserLoggedIn);
    const isVendorLoggedIn = useSelector((state) => state.user.isVendorLoggedIn);
    const cartItems = useSelector((state) => state.user.cart);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userData = useSelector((state) => state.user.userData);
    const [paymentDetails, setPaymentDetails] = useState();
    const [sponsoredCourses, setSponsoredCourses] = useState([]);
    const [loader, setLoader] = useState(false)
    const [wishlistData, setWishlistData] = useState([])
    const [cartData, setCartData] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [cartCountValue, setCartCountValue] = useAtom(cartCount)
    const { addItemToCart } = useContext(CartContext);
    const [courseDetails, setCourseDetails] = useState([]);

    useEffect(() => {
        if (userData?.user?.id !== undefined) {
            dispatch(
                getServices?.getPaymentDetails(userData?.user?.id)
            )
                .then((response) => {
                    setPaymentDetails(response?.payments);
                })
                .catch((err) => {
                    console.error({ err });
                });
        }
        if (isUserLoggedIn) {
            getWishlistData()
            fetchCartItems()
        } else {
            addItemToCart(cartItems?.length)
        }
    }, []);

    const getWishlistData = () => {

        dispatch(getServices.GetWistlistDataUser(userData?.user?.id)).then((response) => {
            setWishlistData(response?.course_details)
        }).catch((error) => {
            console.error({ error });
        })
    }

    const fetchCartItems = async () => {
        try {
            const response = await dispatch(getServices.getCartData());
            addItemToCart(response?.data?.length);
            setCartCountValue(response?.data?.length || 0)
            setCartData(response?.data || []);
        } catch (error) {
            console.error("Error fetching cart data", error);
        }
    };

    useEffect(() => {
        const fetchSponsoredCourses = async () => {
            setLoader(true)
            try {
                const queryString = `?course_type=sponsored`
                let response = await dispatch(getServices?.getCourses(queryString));
                let courses = response?.data || [];
                setSponsoredCourses(courses);
            } catch (err) {
                console.error({ err });
            }
            setLoader(false);
        };

        fetchSponsoredCourses();
    }, []);

    const handleWishlistList = (course) => {

        let formData = new FormData();
        formData.append("course_id", course?.id)

        try {

            dispatch(uploadService.addRemoveCourseWishlist(formData)).then((response) => {
                if (isUserLoggedIn) {
                    getWishlistData()
                }
                Swal.fire(
                    "Success!",
                    `${response?.message}`,
                    "success"
                )
            }).catch((error) => {
                Swal.fire(
                    "Failed",
                    "Unable to add items to wishlist.",
                    "error"
                )
            })

        } catch (e) {
            Swal.fire(
                "Failed",
                "Unable to add item to wishlist.",
                "error"
            )
        }

    }

    const handleCourseData = (values) => {

        navigate(`/course/${values?.id}/${values?.title}`, {
            state: { course_id: values?.id },
        });
    };

    const settingsFeature = {
        dots: false,
        speed: 500,
        arrow: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: false,
        autoplay: false,
        autoplaySpeed: 1000,
        arrows:
            sponsoredCourses && sponsoredCourses?.filter((course) => course?.length > 3),
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    const DateFormatter = (date) => {
        const inputDate = date;

        const dateObj = new Date(inputDate);

        const monthNames = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
        ];

        const day = dateObj.getDate();
        const month = monthNames[dateObj.getMonth()];
        const year = dateObj.getFullYear();

        const formatDate = `${day} ${month} ${year}`;
        return formatDate;
    };


    const handleAddToCart = (data, text) => {

        if (!isUserLoggedIn && !isVendorLoggedIn) {
            dispatch(add_To_Cart(data))
        } else {
            const addToCart = (data) => {
                const formData = new FormData()
                formData.append("course_id", data?.id)
                formData.append("course_price", data?.fee)
                dispatch(uploadService.addToCart(formData)).then((response) => {
                    if (isUserLoggedIn) {
                        fetchCartItems();
                        if (text === "purchase") {
                            navigate("/checkout")
                        }
                    }
                    message.success(`${response.message}`);
                }).catch((error) => {
                    message.error(`${error.message}`);
                })
            }
            addToCart(data)
        }

    }

    const handleCourseEnquiry = (course) => {

        if (isUserLoggedIn || isVendorLoggedIn) {

            setCourseDetails([{ course_id: course?.id, vendor_id: course?.vendor_id }])
            setModalOpen(true);
        } else {
            navigate("/login")
        }
    }

    return (
        <>
            <div className="container" >
                <div className="sponsoredCourses-section" >
                    <h1 className="feature-header border-bottom-heading bottom-border-center">
                        Sponsored Items
                    </h1>
                </div>
            </div>
            <div className="container" style={{ width: "100%" }}>
                {loader ? (
                    <div className="d-flex justify-content-center align-items-center mt-4 mb-4">
                        <Spin
                            indicator={
                                <LoadingOutlined
                                    style={{
                                        fontSize: 48,
                                    }}
                                    spin
                                />

                            }
                        />
                    </div>
                ) : (
                    <>
                        {sponsoredCourses.length > 0 ? (

                            <Slider className="slider-outer-2 row" {...settingsFeature}>
                                {(sponsoredCourses)
                                    ?.filter((courseData) => courseData !== null)
                                    ?.map((course) => (
                                        <>
                                            <div className="card-top-head d-flex jaustify-content-between row">

                                                <div className="col-8">
                                                    {course?.parent_category_name !== null ? <h5 className="main-category mb-0">{course?.parent_category_name}</h5 > : ''}
                                                </div>

                                                {/* <h5 className="main-category mb-0">Event</h5 > */}

                                                <div className="col-4">
                                                    {course?.approach !== 0 ? <h5 className="course-approach mb-0">{course?.approach === 1 ? "Online" : course?.approach === 2 ? "Physical" : "Combined"}</h5 > : ''}
                                                </div>

                                            </div>


                                            <Card
                                                className="feature-card home-card"
                                                hoverable
                                                cover={
                                                    <img
                                                        className={course?.approach !== 0 ? "image-radius" : ""}
                                                        onClick={(e) => handleCourseData(course)}
                                                        style={{ height: "250px", cursor: "pointer" }}
                                                        src={course?.image ? course?.image : featureImg2}
                                                        alt="Course Image"
                                                    />

                                                }
                                            >
                                                <div className="wishlist-icon-div">


                                                    {
                                                        isUserLoggedIn ? (
                                                            <button
                                                                type="button"
                                                                className="btn wishlist-feature-button add-to-wishlist"
                                                                onClick={() => handleWishlistList(course)}
                                                            >
                                                                {wishlistData?.some(
                                                                    (wish) => parseInt(wish?.id) === parseInt(course?.id)
                                                                ) ? (
                                                                    <GoHeartFill />
                                                                ) : (
                                                                    <FaRegHeart />
                                                                )}
                                                            </button>
                                                        ) : " "
                                                    }
                                                </div>
                                                <div className="card-position-div">
                                                    <div
                                                        style={{
                                                            zIndex: "99",
                                                            padding: "10px 15px",
                                                            // backgroundColor: "#106ab369",
                                                            backgroundColor: "#106ab3",
                                                            borderRadius: "0 10px 10px 0",
                                                        }}
                                                        className="expire-container"
                                                    >
                                                        {paymentDetails?.some(
                                                            (item) => item?.course_id == course?.id
                                                        ) ? (
                                                            <span className="Lock-icon">
                                                                <FaUnlock color="#fff" />
                                                            </span>
                                                        ) : (
                                                            <span className="Lock-icon">
                                                                <FaLock color="#fff" />
                                                            </span>
                                                        )}
                                                    </div>
                                                    <div
                                                        style={{
                                                            zIndex: "99",
                                                            padding: "10px 10px",
                                                            backgroundColor: "#106ab3",
                                                            borderRadius: "10px 0 0 10px",
                                                        }}
                                                        className="expire-container"
                                                    >
                                                        <div className="start-end-date-container">
                                                            <p
                                                                style={{ color: "#fff", margin: 0 }}
                                                                className="start-enddate"
                                                            >
                                                                {course?.course_start && course?.course_end
                                                                    ? `${DateFormatter(
                                                                        course?.course_start
                                                                    )} - ${DateFormatter(course?.course_end)}`
                                                                    : "12 July 2024 - 15 August 2025"}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style={{ cursor: "pointer" }} onClick={(e) => handleCourseData(course)}>

                                                    <div

                                                        style={{ cursor: "pointer" }}
                                                    >
                                                        <Flex
                                                            gap="middle"
                                                            className="feature-course-subhead-price"
                                                        >
                                                            <div>
                                                                <p className="wof-certified-logo"><span><img style={{ width: "20px", height: "20px" }} src={Logo} /></span>WOF Certified</p>
                                                                {course?.parent_category_name ? (
                                                                    <p className="course-Name" >
                                                                        {course?.parent_category_name}
                                                                    </p>
                                                                ) : (
                                                                    <p className="course-category-blank"> Blank</p>
                                                                )}



                                                            </div>

                                                            <div>
                                                                <p
                                                                    className="course-fee"

                                                                >
                                                                    {course?.fee !== 0 ? `$${course?.fee}` : 'Free'}

                                                                </p>
                                                            </div>
                                                        </Flex>
                                                        {/* <Row>
                                                                <Col span={8}>
                                                                    {course?.approach === 1 ? <Button>Online</Button> : course?.approach === 2 ? <Button>Physical</Button> : course?.approach === 3 ? <Button>Combined</Button> : ""}
                                                                </Col>
                                                            </Row> */}
                                                        <Flex className="feature-course-subhead-price">
                                                            <h4>{course?.title}</h4>
                                                        </Flex>
                                                    </div>
                                                    <div>
                                                        <p>
                                                            <p className="description-of-course">
                                                                {course?.about_course}
                                                            </p>
                                                        </p>
                                                    </div>
                                                </div>
                                                <Flex className="feature-course-subhead-price customButtonSection">
                                                    {paymentDetails?.some(
                                                        (item) => item?.course_id == course?.id
                                                    ) || userData?.vendor?.id === course?.vendor_id ? (
                                                        <>
                                                            <button
                                                                type="button"
                                                                class="btn feature-button view-course-button"
                                                                onClick={(e) => handleCourseData(course)}
                                                            >
                                                                View Items
                                                            </button>
                                                        </>
                                                    ) : (
                                                        <>
                                                            {
                                                                isUserLoggedIn || !isVendorLoggedIn ? (
                                                                    <>
                                                                        {cartData && cartData.some(cartItem => cartItem?.course?.id === course?.id) ? (
                                                                            <button
                                                                                type="button"
                                                                                className="btn add-to-cart-button"
                                                                                onClick={() => navigate("/cart")}
                                                                            >
                                                                                Go to cart
                                                                            </button>
                                                                        ) : (
                                                                            <button
                                                                                type="button"
                                                                                className="btn add-to-cart-button"
                                                                                onClick={() => handleAddToCart(course)}
                                                                            >
                                                                                Add to cart
                                                                            </button>
                                                                        )}
                                                                        {isUserLoggedIn ? (
                                                                            <button
                                                                                type="button"
                                                                                className="btn enroll-button"
                                                                                onClick={() => handleAddToCart(course, "purchase")}
                                                                            >
                                                                                Purchase Now
                                                                            </button>
                                                                        ) : (<></>)}

                                                                    </>

                                                                ) : (
                                                                    <></>

                                                                )
                                                            }
                                                            <button
                                                                type="button"
                                                                class="btn feature-button add-to-cart-button"
                                                                onClick={() => handleCourseEnquiry(course)}
                                                            >
                                                                Enquiry
                                                            </button>
                                                        </>
                                                    )}
                                                </Flex>
                                            </Card>

                                        </>
                                    ))}
                            </Slider>

                        ) : (
                            <p style={{
                                fontSize: "24px",
                                fontWeight: "600",
                                marginTop: "20px",
                                fontFamily: 'Poppins',
                                lineHeight: "150%"
                            }} className="text-center">No Item Found</p>
                        )}
                    </>
                )}
            </div>
            <Modal
                centered
                open={modalOpen}
                onOk={() => setModalOpen(false)}
                onCancel={() => setModalOpen(false)}
                className="course-enquiry-modal"
            >
                <CourseEnquiry course_details={courseDetails} setModalOpen={setModalOpen} />
            </Modal>

        </>
    );
};

export default FeaturesCourses;
