import React, { useState, useEffect } from "react";
import { Row, Col, Input, Button, Space, Image } from "antd";
import Sidebar from "../../../components/Sidebar/sidebar";
import { RewardTrophy } from "../../../constant/images";
import * as getServices from "../../../services/getServices";
import { useDispatch, useSelector } from "react-redux"

export default function MyRewards() {

  const userData = useSelector((state) => state.user.userData);
  const dispatch = useDispatch();

  const [paymentDetails, setPaymentDetails] = useState([]);
  const [totalPoints, setTotalPoints] = useState(0);
  const [latestReward, setLatestReward] = useState(null);

  useEffect(() => {
    dispatch(
      getServices.getPaymentDetails(userData?.user?.id)
    )
      .then((response) => {
        setPaymentDetails(response?.payments);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [dispatch, userData])



  useEffect(() => {
    // Calculate total points
    const points = paymentDetails.reduce((sum, payment) => {
      return payment?.reward ? sum + payment?.reward?.points : sum;
    }, 0);
    setTotalPoints(points);

    // Find the latest reward
    const latest = paymentDetails.reduce((latest, payment) => {
      if (!payment?.reward) return latest;
      return new Date(payment?.reward?.created_at) > new Date(latest?.reward?.created_at) ? payment : latest;
    }, paymentDetails[0]);
    setLatestReward(latest?.reward);
  }, [paymentDetails]);

  return (
    <>
      <Row>
        <Col xl={4} className="d-xl-block d-md-none">
          <Sidebar />
        </Col>
        <Col xl={20} md={24}>
          <div className="mainContent">
            <Row className="my-rewards-container-main">
              <Col className="rewards-good" md={12}>
                <p className="reward-my-reward">My Rewards</p>
                <p className="good-morning-name">Good Morning {userData?.user?.name}!</p>
              </Col>
              <Col className="redeem-button-top" md={12}>
                <button type="button" className="btn btn-success redeem-button">
                  Redeem Now
                </button>
              </Col>
            </Row>
            <Row className="my-reward-content">
              <Col className="input-container" md={24}>
                <p className="voucher-code">Have an voucher code?</p>
                <Space.Compact className="input-my-reward">
                  <Input defaultValue="Enter voucher/Coupon code here" />
                  <Button className="input-button-my-reward" type="primary">Apply Coupon</Button>
                </Space.Compact>
              </Col>
              <Row className="reward-point-section-earned">
                <Col className="reward-col-section" md={8}>
                  <p className="two-hund">{latestReward?.points}</p>
                  <p className="rew-point">Reward Points Earned</p>
                  <button type="button" class="btn btn-success redeem-button">
                    Redeem Now
                  </button>
                </Col>
                <Col className="total-reward-trphy-image" md={8}>
                  <p className="total">You have total</p>
                  <p className="reward-points">{totalPoints} Reward Points</p>
                </Col>
                <Col className="total-reward-trphy-image" md={8}>
                  <Image
                    src={RewardTrophy}
                    alt="Not Found"
                    className="img-fluid"
                    preview={false}
                  />
                </Col>
              </Row>
            </Row>
          </div>
        </Col>
      </Row>
    </>
  );
}
