import React, { useState, useEffect } from 'react'
import * as getServices from "../../services/getServices";
import { useDispatch } from 'react-redux';
import { Card, Carousel } from 'antd';



const Offer = () => {

    const dispatch = useDispatch();
    const [offer, setOffer] = useState([]);
    const [loader, setLoader] = useState(false)

    useEffect(() => {
        setLoader(true)
        dispatch(getServices.getOffer())
            .then((res) => {
                console.log(res, "res offer")
                setOffer(res?.offers);
            })
            .catch((err) => {
                setLoader(false)
                console.error(err);
            });
    }, []);
    return (
        <>
            <Carousel autoplay autoplaySpeed={2000} style={{ margin: '10px auto', width: '15%', }} className='offers_slider_zone'>
                {offer.map(offerItem => (
                    <div key={offerItem.id} style={{ height: '200px', overflow: 'hidden' }}>
                        <Card hoverable style={{ borderRadius: '8px', height: '100%', overflow: 'hidden' }}>
                            <img alt={offerItem.title} src={offerItem.image} style={{ objectFit: 'contain', height: '100%', width: '100%' }}
                            />
                            <Card.Meta
                                title={offerItem.title}
                                // description={`Active: ${offerItem.start_date} to ${offerItem.end_date}`}
                                style={{ padding: '8px', textAlign: 'center' }} 
                            />
                        </Card>
                    </div>
                ))}
            </Carousel>
        </>
    )
}
export default Offer