import Http from "../Http";
import { DEMO_URL } from "../Helper";

export function addNewCourse(value) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            Http.post(DEMO_URL + "/api/course", value)
                .then((res) => {
                    return resolve(res.data);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
}

export function forgetPassword(value) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            Http.post(DEMO_URL + "/api/forget-password", value)
                .then((res) => {
                    return resolve(res.data);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
}

export function resetPassword(value) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            Http.post(DEMO_URL + "/api/reset-password", value)
                .then((res) => {
                    return resolve(res.data);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
}

export function payment(value) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            Http.post(DEMO_URL + "/api/payment", value)
                .then((res) => {
                    return resolve(res.data);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
}

export function contactAdmin(value) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            Http.post(DEMO_URL + "/api/contact-admin", value)
                .then((res) => {
                    return resolve(res.data);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
}

export function postReview(value) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            Http.post(DEMO_URL + "/api/reviews", value)
                .then((res) => {
                    return resolve(res.data);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
}

export function addCourseSlots(value) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            Http.post(DEMO_URL + "/api/course-slots", value)
                .then((res) => {
                    return resolve(res.data);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
}
export function getAllBookingData(value) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            Http.post(DEMO_URL + `/api/get-booking-data`, value)
                .then((res) => {
                    return resolve(res.data);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
}
export function getEnrollmentsData(value) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            Http.post(DEMO_URL + `/api/enrollments`, value)
                .then((res) => {
                    return resolve(res.data);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
}
export function getSalesData(value) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            Http.post(DEMO_URL + `/api/course-sale-summary`, value)
                .then((res) => {
                    return resolve(res.data);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
}

export function addClasses(value) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            Http.post(DEMO_URL + `/api/create-classes`, value)
                .then((res) => {
                    return resolve(res.data);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
}

export function getCoursesByCategoryID(value) {

    return (dispatch) =>
        new Promise((resolve, reject) => {
            Http.post(DEMO_URL + `/api/particular-category-course`, value)
                .then((res) => {
                    return resolve(res.data);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
}
export function getAllClasses(value) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            Http.post(DEMO_URL + `/api/vendor-classes`, value)
                .then((res) => {
                    return resolve(res.data);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
}

export function getCoursesByDate(value) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            Http.post(DEMO_URL + `/api/course-filter${value}`, )
                .then((res) => {
                    return resolve(res.data);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
}

export function getCalculatedCustom(value) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            Http.post(DEMO_URL + `/api/calculate-custom-sponsored`, value)
                .then((res) => {
                    return resolve(res.data);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
}

// export function getCour(value) {
//   return (dispatch) =>
//     new Promise((resolve, reject) => {
//       Http.post(DEMO_URL + `/api/vendor-classes`, value)
//         .then((res) => {
//           return resolve(res.data);
//         })
//         .catch((err) => {
//           return reject(err);
//         });
//     });
// }

export function PaymentIntent(value) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            Http.post(DEMO_URL + `/api/order/pay`, value)
                .then((res) => {
                    return resolve(res.data);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
}

export function SponsoredCoursesPayment(value) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            Http.post(DEMO_URL + `/api/sotre-vendor-payment`, value)
                .then((res) => {
                    return resolve(res.data);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
}


export function uploadStriptConnectId(value) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            Http.post(DEMO_URL + `/api/stripe-connect-account-id`, value)
                .then((res) => {
                    return resolve(res.data);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
}


export function getCoursesFromDistance(value) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            Http.post(DEMO_URL + `/api/filter-sponsored-course`, value)
                .then((res) => {
                    return resolve(res.data);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
}

export function addRemoveCourseWishlist(value) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            Http.post(DEMO_URL + `/api/add-wishlist`, value)
                .then((res) => {
                    return resolve(res.data);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
}

export function uploadCoursesCSV(value) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            Http.post(DEMO_URL + `/api/courses-upload_csv`, value)
                .then((res) => {
                    return resolve(res.data);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
}

export function applyCoupon(value) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            Http.post(DEMO_URL + `/api/apply-coupon`, value)
                .then((res) => {
                    return resolve(res.data);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
}

export function addToCart(value) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            Http.post(DEMO_URL + `/api/add-to-cart`, value)
                .then((res) => {
                    return resolve(res.data);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
}

export function uploadCoursesAtOnce(value) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            Http.post(DEMO_URL + `/api/update-cart-after-login`, value)
                .then((res) => {
                    return resolve(res.data);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
}

export function addBlogComment(value) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            Http.post(DEMO_URL + `/api/add-blog-comment`, value)
                .then((res) => {
                    return resolve(res.data);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
}

export function uploadEnquiry(value) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            Http.post(DEMO_URL + `/api/add-blog-comment`, value)
                .then((res) => {
                    return resolve(res.data);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
}