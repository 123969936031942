const initialState = {
    userType: null,
    userID: null,
    userData: null,
    isUserLoggedIn: false,
    isVendorLoggedIn: false,
    notificationData: null,
    token: null,
    logoData: null,
    cart: [],
    total_item: 0,
    total_amount: 0,
    categoriesData:[]
};

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case "SET_USER_TYPE":
            return {
                ...state,
                userType: action.payload,
            };
        case "SET_USER_ID":
            return {
                ...state,
                userID: action.payload,
            };
        case "SET_USER_DATA":
            return {
                ...state,
                userData: action.payload,
            };
        case "IS_USER_LOGGED_IN":
            return {
                ...state,
                isUserLoggedIn: action.payload,
            };
        case "IS_VENDOR_LOGGED_IN":
            return {
                ...state,
                isVendorLoggedIn: action.payload,
            };
        case "NOTIFICATION_DATA":
            return {
                ...state,
                notificationData: action.payload,
            };
        case "TOKEN":
            return {
                ...state,
                token: action.payload,
            };
        case "LOGO":
            return {
                ...state,
                logoData: action.payload,
            };
        case "ADD_TO_CART":
            return {
                ...state,
                cart: state.cart === undefined ? [action.payload]:[...state.cart,action.payload],
                total_item: state.total_item + 1,
                total_amount: parseInt(state.total_amount) + parseInt(action.payload.fee)
            };
        case "REMOVE_FROM_CART":
            const itemToRemove = state.cart.find(item => item.id === action.payload);
            const updatedCart = state.cart.filter(item => item.id !== action.payload);
            return {
                cart: updatedCart,
                total_item: state.total_item - 1,
                total_amount: state.total_amount - (itemToRemove ? itemToRemove.fee : 0)
            };
        case "SET_CATEGORIES_DATA":
            return {
                    ...state,
                    categoriesData: action.payload,
            };
        default:
            return state;
    }
};

export default userReducer;
