import React, { useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {Button } from "antd";
import RecentCourse from "./RecentCourse";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import * as getServices from "../../services/getServices";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import BannerSection from "./banner";
import CategorySlider from "./categorySlider";
import SponsoredCourses from "./sponsoredCourese";


export default function Home() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);
    // const [paymentCourseData, setPaymentCourseData] = useState();


    // const handleOk = () => {
    //     setIsModalOpen(false);
    // };
    // const handleCancel = () => {
    //     setIsModalOpen(false);
    // };

    const handleLoadMoreButton = () => {
        navigate(`/course-listing`, { state: { querystring:null } });
    }
    return (
        <>
            <BannerSection />
            <div className="homePageCarousal">
                <CategorySlider />
        
                {/* <SponsoredCourses isModalOpen={isModalOpen}
                    setIsModalOpen={setIsModalOpen} setPaymentCourseData={setPaymentCourseData} /> */}
                <SponsoredCourses isModalOpen={isModalOpen}/>
                <div className="load-more-container">
                    <Button onClick={handleLoadMoreButton} className="load-more-button">View more...</Button>
                </div>
            </div>
            <RecentCourse  />
            {/* <Modal
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                className="paymentModal"
                centered
                width={1140}
                footer={false}
            >
                <Payment paymentCourseData={paymentCourseData} />
            </Modal> */}
        </>
    );
}
