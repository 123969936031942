import Http from "../Http";
import { DEMO_URL } from "../Helper";

export function getCategories(value) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            Http.get(DEMO_URL + "/api/category")
                .then((res) => {
                    return resolve(res.data);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
}

export function getSecondLevelCategories(value) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            Http.get(DEMO_URL + `/api/get-second-level-category/${value}`)
                .then((res) => {
                    return resolve(res.data);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
}


export function getSubCategories(value) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            Http.get(DEMO_URL + "/api/sub-category")
                .then((res) => {
                    return resolve(res.data);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
}

export function getCourseByVendorID(value) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            Http.get(DEMO_URL + `/api/get-courses/${value}`)
                .then((res) => {
                    return resolve(res.data);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
}
export function getCourseByCourseID(value) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            Http.get(DEMO_URL + `/api/get-course/${value}`)
                .then((res) => {
                    return resolve(res.data);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
}

export function getVendorDetails(value) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            Http.get(DEMO_URL + `/api/vendor-detail/${value}`)
                .then((res) => {
                    return resolve(res.data);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
}


export function getUserDetails(value) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            Http.get(DEMO_URL + `/api/user-detail/${value}`)
                .then((res) => {
                    return resolve(res.data);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
}

// export function getAllCourses(value) {
//     return (dispatch) =>
//         new Promise((resolve, reject) => {
//             Http.get(DEMO_URL + `/api/get-courses?filter_type=${value}`)
//                 .then((res) => {
//                     return resolve(res.data);
//                 })
//                 .catch((err) => {
//                     return reject(err);
//                 });
//         });
// }

export function getPaymentDetails(value) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            Http.get(DEMO_URL + `/api/get-payment/${value}`)
                .then((res) => {
                    return resolve(res.data);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
}

export function getCOntactInformation(value) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            Http.get(DEMO_URL + "/api/settings")
                .then((res) => {
                    return resolve(res.data);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
}
// export function getSetting(value) {
//     return (dispatch) =>
//         new Promise((resolve, reject) => {
//             Http.get(DEMO_URL + "/api/settings")
//                 .then((res) => {
//                     return resolve(res.data);
//                 })
//                 .catch((err) => {
//                     return reject(err);
//                 });
//         });
// }

export function getPageData(value) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            Http.get(DEMO_URL + "/api/pages/1")
                .then((res) => {
                    return resolve(res.data);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
}

export function getAllPages() {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            Http.get(DEMO_URL + "/api/pages")
                .then((res) => {
                    return resolve(res.data);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
}

export function getPageDetails(value) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            Http.get(DEMO_URL + `/api/pages/${value}`)
                .then((res) => {
                    return resolve(res.data);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
}

export function getFAQ(value) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            Http.get(DEMO_URL + "/api/faq")
                .then((res) => {
                    return resolve(res.data);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
}

export function getBlogs(take,offset,order) {
  if(take!==undefined && offset!==undefined && order!==undefined){
        return (dispatch) =>
            new Promise((resolve, reject) => {
                Http.get(DEMO_URL + `/api/blog?take=${take}&offset=${offset}&order=${order}`)
                    .then((res) => {
                        return resolve(res.data);
                    })
                    .catch((err) => {
                        return reject(err);
                    });
            });
    }else{
        return (dispatch) =>
            new Promise((resolve, reject) => {
                Http.get(DEMO_URL + `/api/blog`)
                    .then((res) => {
                        return resolve(res.data);
                    })
                    .catch((err) => {
                        return reject(err);
                    });
            });
    }
    
}

export function getCustomer(value) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            Http.get(DEMO_URL + `/api/getCustomer?vendor_id=${value}`)
                .then((res) => {
                    return resolve(res.data);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
}

export function getReviews(value) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            Http.get(DEMO_URL + `/api/reviews?vendor_id=${value}`)
                .then((res) => {
                    return resolve(res.data);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
}

export function getUserBookings(value) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            Http.get(DEMO_URL + `/api/get-courses?user_id=${value}`)
                .then((res) => {
                    return resolve(res.data);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
}

export function getUserReviews(value) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            Http.get(DEMO_URL + `/api/reviews-user?user_id=${value}`)
                .then((res) => {
                    return resolve(res.data);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
}

export function getClasses(value) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            Http.get(DEMO_URL + `/api/classes`)
                .then((res) => {
                    return resolve(res.data);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
}

export function getParticularCourseReviews(value) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            Http.get(DEMO_URL + `/api/course-reviews/${value}`)
                .then((res) => {
                    return resolve(res.data);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
}

export function getAllRewards() {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            Http.get(DEMO_URL + `/api/rewards`)
                .then((res) => {
                    return resolve(res.data);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
}

export function getUserDataByCourseID(value) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            Http.get(DEMO_URL + `/api/get-course-payment/${value}`)
                .then((res) => {
                    return resolve(res.data);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
}

export function getConfig() {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            Http.get(DEMO_URL + `/api/config`)
                .then((res) => {
                    return resolve(res.data);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
}

export function getSponsorshipData() {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            Http.get(DEMO_URL + `/api/sponsoreship`)
                .then((res) => {
                    return resolve(res.data);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
}

export function getStripeOnboardLink() {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            Http.get(DEMO_URL + `/api/strip-onbording-redirect-link`)
                .then((res) => {
                    return resolve(res.data);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
}

export function getSponsoredCourses(value) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            Http.get(DEMO_URL + `/api/vendors-sponsored-course?filter=${value}`)
                .then((res) => {
                    return resolve(res.data);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
}


export function getVendorBookings(value) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            Http.get(DEMO_URL + `/api/get-sales-summary?vendor_id=${value}`)
                .then((res) => {
                    return resolve(res.data);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
}



export function getLatLongPostalCode(value) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            Http.post(DEMO_URL + `/api/get-lat-long`, value)
                .then((res) => {
                    return resolve(res.data);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
}

export function getLatLongAddress(value) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            Http.post(DEMO_URL + `/api/get-lat-long-with-fulladdress`, value)
                .then((res) => {
                    return resolve(res.data);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
}

export function GetWistlistDataUser(value) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            Http.get(DEMO_URL + `/api/get-wishlist/${value}`)
                .then((res) => {
                    return resolve(res.data);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
}

export function getNotifications(value) {
    return (dispatch) =>
        new Promise((resolve, reject) => {

            Http.get(DEMO_URL + `/api/get-notification`)
                .then((res) => {
                    return resolve(res.data);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
}

export function getCouponUser(value) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            Http.get(DEMO_URL + `/api/coupon/${value}`)
                .then((res) => {
                    return resolve(res.data);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
}

export function getRecommendItem() {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            Http.get(DEMO_URL + `/api/recommended-courses`)
                .then((res) => {
                    return resolve(res.data);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
}
export function getBanner(value) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            Http.get(DEMO_URL + `/api/get-banner/${value}`)
                .then((res) => {
                    return resolve(res.data);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
}

export function getCartData() {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            Http.get(DEMO_URL + `/api/user-cart-data`)
                .then((res) => {
                    return resolve(res.data);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
}

export function getEnquiriesByVendorID(value) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            Http.get(DEMO_URL + `/api/vendor-contacts/${value}`)
                .then((res) => {
                    return resolve(res.data);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
}



export function getBlogCommentByBlogId(value) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            Http.get(DEMO_URL + `/api/blog-comment/${value}`)
                .then((res) => {
                    return resolve(res.data);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
}

export function getCourses(value) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            Http.get(DEMO_URL + `/api/get-courses${value}`)
                .then((res) => {
                    return resolve(res.data);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
}


export function getVendorPayments() {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            Http.get(DEMO_URL + `/api/get-recent-bookings`)
                .then((res) => {
                    return resolve(res.data);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
}

export function getCourseDetails(value) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            Http.get(DEMO_URL + `/api/get-course/${value}`)
                .then((res) => {
                    return resolve(res.data);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
}

export function getUserCourses(value) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            Http.get(DEMO_URL + `/api/user-courses${value}`)
                .then((res) => {
                    return resolve(res.data);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
}


export function getOffer() {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            Http.get(DEMO_URL + "/api/offers")
                .then((res) => {
                    return resolve(res.data);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
}



