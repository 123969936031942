import React,{useState ,useEffect} from "react";
import * as getServices from "../../services/getServices"
import { useDispatch } from "react-redux";
import Header from "../../components/Header";
import Footer from "../../components/Footer/footer";
const TermCondition = () => {
    const dispatch = useDispatch();
    const [pageData, setPageData] = useState();

    useEffect(() => {
        dispatch(getServices?.getPageDetails('term-condition'))
            .then((response) => {
      
                setPageData(response?.page);
            })
            .catch((err) => {
                console.error({ err });
            });
    }, []);
    return (
        <>
            <div className="blog-container">
                <div className="blog-banner-container">
                    <div className="blog-cover-text">
                        <h2 className="blog-cover-text-heading">{pageData?.title} </h2>
                        <p className="blog-cover-text-subhead">
                            {pageData?.summary}
                        </p>
          </div>
        </div>
          <div className="blogs-tab-container">           
                    <div dangerouslySetInnerHTML={{ __html: pageData?.description }}></div>
           
            </div>
            
      </div>
        </>
    );
}

export default TermCondition;