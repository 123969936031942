import React, { useEffect, useState } from "react";
import Sidebar from "../../../components/Sidebar/sidebar";
import { Button, Input, Form, Col, Row, Image, Modal, Checkbox, Upload } from "antd";
import { CiUser } from "react-icons/ci";
import { CiMail } from "react-icons/ci";
import { CiCalendar } from "react-icons/ci";
import { CiLock } from "react-icons/ci";
import { IoEyeOutline } from "react-icons/io5";
import { FiEdit } from "react-icons/fi";
import { useSelector } from "react-redux";
import * as UserServices from "../../../services/userServices";
import { useDispatch } from "react-redux";
import { setUserData } from "../../../store/actions/user";
import { Avatar, } from "../../../constant/images";
import * as updateServices from "../../../services/updateServices";
import Swal from "sweetalert2";
import { BsTelephone } from "react-icons/bs";

export default function MyProfile() {
    const dispatch = useDispatch();
    const userData = useSelector((state) => state.user.userData);
    const [chanePasswordCheckbox, setChangePasswordCheckbox] = useState(false);
    const [viewType, setViewType] = useState("")
    const [profileImage, setProfileImage] = useState(null)
    const handleFileChange = (e) => {
        setProfileImage(e.file.originFileObj)
    };

    useEffect(() => {
        if (profileImage) {
            const formData = new FormData();
            formData.append('images', profileImage);

            dispatch(updateServices?.updateProfileImage(formData)).then((response) => {
                userDetail()
                Swal.fire(
                    "Profile Updated Successfully!",
                    "You have successfully updated your profile",
                    "success"
                ).then((result) => {
                    window.location.reload();
                }).catch((err) => {
                    Swal.fire(
                        "Error",
                        "Failed to update profile",
                        "error"
                    )
                })
            }).catch((err) => {
                console.error({ err });
            });
        }
    }, [profileImage]);



    const userDetail = () => {
        if (userData?.user?.id) {
            dispatch(
                UserServices.getUserDetails(userData?.user?.id)
            )
                .then((res) => {
                    dispatch(setUserData(res));
                })
                .catch((err) => {
                    console.error(err);
                });
        }
    }
    useEffect(() => {
        userDetail()
    }, []);

    function convertDate(dateString) {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    }


    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = (value) => {
        value === "edit" ? setViewType("edit") : setViewType("view")
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setViewType("")
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setViewType("")
        setIsModalOpen(false);
    };
    const onFinish = (values) => {
        const originalDate = values?.dob;

        const formattedDate = convertDate(originalDate);
        const formData = new FormData();
        formData.append("name", values?.fullName);
        formData.append("email", values?.email);
        formData.append("phone", (values?.phoneNumber).toString());
        if (values?.alternate_number) {
            formData.append("alternate_number", (values?.alternate_number).toString());
        }
        formData.append("dob", formattedDate);
        if (values?.password) {
            formData.append("password", values?.password);
        }

        dispatch(UserServices.userUpdate(formData))
            .then((res) => {

                Swal.fire({
                    icon: "success",
                    title: "Profile updated successfully",
                });

                dispatch(setUserData(res?.data));
                userDetail()
            })
            .catch((err) => {
                Swal.fire({
                    icon: "error",
                    title: err.response.data.message,
                })
            })

    }

    const onFinishPasswordChange = (values) => {
        let formData;
        formData = {
            email: userData?.user?.email,
            password: values?.password,
            new_password: values?.new_password
        }

        dispatch(updateServices.changePassword(formData))
            .then((response) => {
                Swal.fire({
                    title: "Success",
                    text: response?.message,
                    icon: "success",
                    confirmButtonText: "Okay",
                }).then(() => {
                    window.location.reload();
                })
            })
            .catch((err) => {
                Swal.fire({
                    title: "Failed",
                    text: err?.response?.data?.message,
                    icon: "error",
                    confirmButtonText: "Okay",
                })
            });

    };

    const onFinishFailed = () => { };
    return (
        <>
            <Row>
                <Col xl={4} className="d-xl-block d-md-none">
                    <Sidebar />
                </Col>
                <Col xl={20} md={24}>
                    <div className="mainContent">
                        <div className="user-profile-container">
                            <Row className="user-profile-row">
                                <Col className="user-profile-col-one justify-content-start px-0" xl={6} md={8} sm={24}>
                                    <div className="edit-view-user-container">
                                        <img className="user-profile-picture" src={userData?.user?.images ? userData?.user?.images : Avatar} />
                                        <div className="edit-view-icon-container">
                                            <FiEdit onClick={() => showModal("edit")} className='edit-icon-user' />
                                            <IoEyeOutline onClick={() => showModal("view")} className='view-icon-user' />
                                        </div>
                                    </div>
                                </Col>

                                <Col className="user-profile-col-two" xl={18} md={16} sm={24}>

                                    <Form
                                        name="basic"
                                        labelCol={{
                                            span: 8,
                                        }}
                                        wrapperCol={{
                                            span: 24,
                                        }}
                                        initialValues={{
                                            fullName: userData?.user?.name,
                                            email: userData?.user?.email,
                                            phoneNumber: userData?.user?.phone,
                                            alternate_number: userData?.user?.alternate_number,
                                            dob: userData?.user?.dob,
                                            images: userData?.user?.image ? userData?.user?.image : '',
                                        }}
                                        onFinish={onFinish}
                                        onFinishFailed={onFinishFailed}
                                        autoComplete="off"
                                        className="user-profile-form"
                                    >

                                        <Row className="user-form-row">

                                            <Col md={12} className="user-form-col">
                                                <p
                                                    style={{
                                                        textAlign: "start",
                                                        fontFamily: "POPPINS",
                                                    }}
                                                >
                                                    Full Name
                                                </p>

                                                <Form.Item
                                                    className="form-item"
                                                    name="fullName"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Please enter full name.",
                                                        },
                                                    ]}
                                                >
                                                    <Input
                                                        prefix={<CiUser />}
                                                        placeholder="Please enter full name"
                                                        className="input-box-login"
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col md={12} className="user-form-col">
                                                <p
                                                    style={{
                                                        textAlign: "start",
                                                        fontFamily: "POPPINS",
                                                    }}
                                                >
                                                    Email
                                                </p>

                                                <Form.Item
                                                    className="form-input-class"
                                                    name="email"
                                                    type="email"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Email is not valid",
                                                        },
                                                    ]}
                                                >
                                                    <Input
                                                        prefix={<CiMail />}
                                                        placeholder="Please enter email"
                                                        className="input-box-login"
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row className="user-form-row">
                                            <Col md={12} className="user-form-col">
                                                <p
                                                    style={{
                                                        textAlign: "start",
                                                        fontFamily: "POPPINS",
                                                    }}
                                                >
                                                    Phone Number
                                                </p>

                                                <Form.Item
                                                    className="form-item"
                                                    name="phoneNumber"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Please enter phone number.",
                                                        },
                                                        ({ getFieldValue }) => ({
                                                            validator(_, value) {
                                                                const initialPhoneNumber = getFieldValue('phoneNumber');

                                                                if (initialPhoneNumber === value) {
                                                                    return Promise.resolve();
                                                                }
                                                                if (value && value.length === 10) {
                                                                    return Promise.resolve();
                                                                }
                                                                return Promise.reject(
                                                                    new Error("Phone number must be exactly 10 digits.")
                                                                );
                                                            },
                                                        }),
                                                    ]}
                                                >
                                                    <Input
                                                        prefix={<BsTelephone />}
                                                        placeholder="Please enter phone number"
                                                        className="input-box-login"
                                                        maxLength={10}
                                                        onKeyPress={(e) => {
                                                            if (!/[0-9]/.test(e.key)) {
                                                                e.preventDefault();
                                                            }
                                                        }}
                                                        onPaste={(e) => {
                                                            const paste = (e.clipboardData || window.clipboardData).getData("text");
                                                            if (!/^[0-9]+$/.test(paste)) {
                                                                e.preventDefault();
                                                            }
                                                        }}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col md={12} className="user-form-col">
                                                <p
                                                    style={{
                                                        textAlign: "start",
                                                        fontFamily: "POPPINS",
                                                    }}
                                                >
                                                    Add Alternate phone number
                                                </p>

                                                <Form.Item
                                                    className="form-item"
                                                    name="alternate_number"
                                                    rules={[

                                                        ({ getFieldValue }) => ({
                                                            validator(_, value) {
                                                                const initialPhoneNumber = getFieldValue('alternate_number');

                                                                if (initialPhoneNumber === value) {
                                                                    return Promise.resolve();
                                                                }
                                                                if (value && value.length === 10) {
                                                                    return Promise.resolve();
                                                                }
                                                                return Promise.reject(
                                                                    new Error("Phone number must be exactly 10 digits.")
                                                                );
                                                            },
                                                        }),
                                                    ]}
                                                >
                                                    <Input
                                                        prefix={<BsTelephone />}
                                                        placeholder="Please enter alternate phone number"
                                                        className="input-box-login"
                                                        maxLength={10}
                                                        onKeyPress={(e) => {
                                                            if (!/[0-9]/.test(e.key)) {
                                                                e.preventDefault();
                                                            }
                                                        }}
                                                        onPaste={(e) => {
                                                            const paste = (
                                                                e.clipboardData || window.clipboardData
                                                            ).getData("text");
                                                            if (!/^[0-9]+$/.test(paste)) {
                                                                e.preventDefault();
                                                            }
                                                        }}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col md={12} className="user-form-col">
                                                <p
                                                    style={{
                                                        textAlign: "start",
                                                        fontFamily: "POPPINS",
                                                    }}
                                                >
                                                    DOB (Date Of Birth)
                                                </p>

                                                <Form.Item
                                                    className="form-input-class"
                                                    name="dob"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Please enter date of birth",
                                                        },
                                                    ]}
                                                >
                                                    <Input
                                                        prefix={<CiCalendar />}
                                                        placeholder="please select the DOB"
                                                        className="input-box-login"
                                                        type="date"
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row className="user-form-row">
                                            <Col md={24} className="user-form-col">
                                                <Form.Item
                                                    className="mb-0"
                                                    wrapperCol={{
                                                        offset: 8,
                                                        span: 24,
                                                    }}
                                                >
                                                    <Button className="update-button" htmlType="submit">
                                                        Update Profile
                                                    </Button>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Form>
                                    <div className="password-change-container">
                                        <div className="change-password-checkbox-container">
                                            <Checkbox
                                                onChange={(e) =>
                                                    setChangePasswordCheckbox(e.target.checked)
                                                }
                                            >
                                                <p className="change-password-text">Change Password</p>
                                            </Checkbox>
                                        </div>
                                        {chanePasswordCheckbox ? (
                                            <Form
                                                name="basic"
                                                labelCol={{
                                                    span: 8,
                                                }}
                                                wrapperCol={{
                                                    span: 24,
                                                }}
                                                onFinish={onFinishPasswordChange}
                                                onFinishFailed={onFinishFailed}
                                                autoComplete="off"
                                                className="user-profile-form"
                                            >
                                                <Row>
                                                    <Col md={12} className="custom-padding">
                                                        <p
                                                            style={{
                                                                textAlign: "start",
                                                                fontFamily: "POPPINS",
                                                            }}
                                                        >
                                                            Current Password
                                                        </p>

                                                        <Form.Item
                                                            className="form-input-class"
                                                            name="password"
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: "Please enter your current password!",
                                                                },
                                                            ]}
                                                        >
                                                            <Input.Password
                                                                prefix={<CiLock />}
                                                                placeholder="**********"
                                                                suffix={<IoEyeOutline />}
                                                                type="password"
                                                                className="input-box-login"
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col md={12} className="custom-padding">
                                                        <p
                                                            style={{
                                                                textAlign: "start",
                                                                fontFamily: "POPPINS",
                                                            }}
                                                        >
                                                            New Password
                                                        </p>

                                                        <Form.Item
                                                            className="form-input-class"
                                                            name="new_password"
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: "Please enter new password!",
                                                                },
                                                                {
                                                                    min: 6,
                                                                    message:
                                                                        "Password must be at least 6 characters long!",
                                                                },
                                                                ({ getFieldValue }) => ({
                                                                    validator(_, value) {
                                                                        if (
                                                                            !value ||
                                                                            /[!@#$%^&*(),.?":{}|<>]/.test(value)
                                                                        ) {
                                                                            return Promise.resolve();
                                                                        }
                                                                        return Promise.reject(
                                                                            new Error(
                                                                                "Password must contain at least one special character!"
                                                                            )
                                                                        );
                                                                    },
                                                                }),
                                                            ]}
                                                        >
                                                            <Input.Password
                                                                prefix={<CiLock />}
                                                                placeholder="**********"
                                                                suffix={<IoEyeOutline />}
                                                                type="password"
                                                                className="input-box-login"
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col md={12} className="custom-padding">
                                                        <p
                                                            style={{
                                                                textAlign: "start",
                                                                fontFamily: "POPPINS",
                                                            }}
                                                        >
                                                            Confirm Password
                                                        </p>

                                                        <Form.Item
                                                            className="form-input-class"
                                                            name="confirmPassword"
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: "Please confirm your password!",
                                                                },

                                                                ({ getFieldValue }) => ({
                                                                    validator(_, value) {
                                                                        if (
                                                                            !value ||
                                                                            getFieldValue("new_password") === value
                                                                        ) {
                                                                            return Promise.resolve();
                                                                        }
                                                                        return Promise.reject(
                                                                            new Error(
                                                                                "The new password that you entered do not match!"
                                                                            )
                                                                        );
                                                                    },
                                                                }),
                                                            ]}
                                                        >
                                                            <Input.Password
                                                                prefix={<CiLock />}
                                                                placeholder="**********"
                                                                suffix={<IoEyeOutline />}
                                                                className="input-box-login"
                                                                type="password"
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                                <Row className="user-form-row">
                                                    <Col md={24} className="user-form-col">
                                                        <Form.Item
                                                            className="mb-0"
                                                            wrapperCol={{
                                                                offset: 8,
                                                                span: 24,
                                                            }}
                                                        >
                                                            <Button className="update-button" htmlType="submit">
                                                                Update Password
                                                            </Button>
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        ) : ("")}

                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <Modal className="upload-user-profile-picture-modal" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>

                            <Image className="user-profile-picture" width={300} height={300} src={userData?.user?.image} preview={false} />
                            {viewType === "edit" ? (
                                <Upload
                                    listType="picture"
                                    maxCount={1}
                                    onChange={handleFileChange}
                                >
                                    <button className="update-profile-button">Upload profile picture</button>
                                </Upload>
                            ) : ("")}

                        </Modal>
                    </div>
                </Col>
            </Row>
        </>
    );
}
