function StudentEnrollmentChart( data, type ) {

  const preprocessData = (data, type) => {
    
    let xAxisCategories = [];
    let seriesData = [];
    let courseTitles = [];

    if (type === 1) {
      xAxisCategories = data.map(item => item.day);
      seriesData = data.map(item => item.enrollments);
   
    } else if (type === 0 || type === 2) {
      xAxisCategories = data.map(item => item.period);
      seriesData = data.map(item => item.enrollments);

    }

    return { xAxisCategories, seriesData, courseTitles };
  };

  const { xAxisCategories, seriesData, courseTitles } = preprocessData(data, type);

  const options = {
    chart: {
      type: 'column',
    },
    title: {
      text: '',
    },
    exporting: {
      enabled: true,
    },
    plotOptions: {
      series: {
        pointWidth: 50,
      },
      column: {
        colorByPoint: true,
      },
    },
    accessibility: {
      announceNewData: {
        enabled: true,
      },
    },
    xAxis: {
      categories: xAxisCategories,
      title: {
        text: type === 1 ? 'Weekly enrollment' : type === 0 ? 'Monthly enrollment':  type === 2?'Yearly enrollment':'',
      },
    },
    yAxis: {
      title: {
        text: 'Total Enrollments',
      },
      labels: {
        formatter: function () {
          return this.value;
        },
      },
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      headerFormat: '<span style="font-size:11px">{point.key}</span><br>',
      formatter: function () {
        const courses = courseTitles[this.x];
        let tooltipString = `<b>${this.y}</b> Enrollments<br/>`;
        if (courses && courses.length > 0) {
          tooltipString += `<br/>Courses: ${courses.join(', ')}`;
        }
        return tooltipString;
      },
    },
    series: [
      {
        name: 'Total Enrollments',
        data: seriesData,
      },
    ],
  };

  return options
}
export {StudentEnrollmentChart}