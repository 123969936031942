import React, { useState, useEffect, useRef } from "react";
import {
    Row,
    Col,
    Input,
    Button,
    Form,
    Select,
    Switch,
    Modal,
    Upload,
    Image,
    Checkbox,
    DatePicker,
    TimePicker,
} from "antd";
import Sidebar from "../../components/Sidebar/sidebar";
import { GoArrowLeft } from "react-icons/go";
import { LuUpload } from "react-icons/lu";
import { CiUser } from "react-icons/ci";
import { LiaCalendarAltSolid } from "react-icons/lia";
import { BsInfoCircle } from "react-icons/bs";
import { FaArrowRightLong } from "react-icons/fa6";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { MdOutlineCategory } from "react-icons/md";
import { CiDollar } from "react-icons/ci";
import { BsFileCode } from "react-icons/bs";
import { FiPlusCircle } from "react-icons/fi";
import * as getServices from "../../services/getServices";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import * as uploadServices from "../../services/uploadServices";
import Swal from "sweetalert2";
import { FaClipboardQuestion } from "react-icons/fa6";
import { MdOutlineQuestionAnswer } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { HiMiniCheckBadge } from "react-icons/hi2";
import StripePaymentForm from "../paymentPrcess/paymentForm";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import dayjs from "dayjs";
import CoursePolicy from "./coursePolicy";
import moment from "moment";
import { IoIosHelpCircleOutline } from "react-icons/io";
const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

export default function AddNewCourse() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { RangePicker } = TimePicker;
    const [faqForm] = Form.useForm();
    const vendordata = useSelector((state) => state.user.userData);
    const { TextArea } = Input;
    const [nestedForm] = Form.useForm();
    const timePickerRef = useRef();
    const [disabledHours, setDisabledHours] = useState([]);

    //------------------------------------------------//

    const [courseImageCover, setCourseImageCover] = useState([]);
    const [videoAndThumbnail, setVideoAndThumbnail] = useState({
        video: null,
        thumbnail: null,
    });
    const [videolUrl, setVideoUrl] = useState(null);
    const [instructorProfileImage, setInstructorProfileImage] = useState([]);
    const [courseSlots, setCourseSlots] = useState({
        course_slots: [],
    });

    const allDays = [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
    ];

    const handleCourseCoverChange = ({ fileList }) => {
        setCourseImageCover(fileList.slice(-1));
    };
    const handleVideoUpload = ({ fileList }) => {
        const selectedFile = fileList[0]?.originFileObj;
        setVideoAndThumbnail((prevState) => ({
            ...prevState,
            video: selectedFile,
        }));

        if (selectedFile) {
            const videoUrl = URL.createObjectURL(selectedFile);
            setVideoUrl(videoUrl);
        } else {
            setVideoUrl(null);
        }
    };

    const handleThumbnailUpload = ({ fileList }) => {
        const selectedFile = fileList[0]?.originFileObj;
        setVideoAndThumbnail((prevState) => ({
            ...prevState,
            thumbnail: selectedFile,
        }));
    };

    const handleRemoveVideo = () => {
        setVideoAndThumbnail((prevState) => ({
            ...prevState,
            video: null,
            thumbnail: null,
        }));
        setVideoUrl(null);
    };

    const handleRemoveThumbnail = () => {
        setVideoAndThumbnail((prevState) => ({
            ...prevState,
            thumbnail: null,
        }));
    };

    const handleInstructorProfileImageChange = ({ fileList }) => {
        setInstructorProfileImage(fileList.slice(-1));
    };

    const handleDayChange = (day, checked) => {
        const { course_slots } = courseSlots;

        if (checked) {
            setCourseSlots((prevDetails) => ({
                ...prevDetails,
                course_slots: [
                    ...course_slots,
                    { week_day: day, start_time: "", end_time: "" },
                ],
            }));
        } else {
            setCourseSlots((prevDetails) => ({
                ...prevDetails,
                course_slots: course_slots.filter((slot) => slot.week_day !== day),
            }));
        }
    };

    const handleTimeChange = (day, time) => {
        const { course_slots } = courseSlots;
        const updatedSlots = course_slots.map((slot) => {
            if (slot.week_day === day) {
                return {
                    ...slot,
                    start_time: time[0].format("HH:mm"),
                    end_time: time[1].format("HH:mm"),
                };
            }
            return slot;
        });

        setCourseSlots((prevDetails) => ({
            ...prevDetails,
            course_slots: updatedSlots,
        }));
    };

    const onFinishFailed = () => { };
    const [categories, setCategories] = useState("");
    const [childCategories, setChildCategories] = useState("");

    const [file, setFile] = useState(null);
    const [document, setDocument] = useState(null);
    const [faqs, setFAQs] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const [video, setVideo] = useState(null);
    const [videoThumbnail, setVideoThumbnail] = useState(null);
    const [isModalOpenThumbnail, setIsModalOpenThumbnail] = useState(false);
    // const [upload, setUpload] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState("");
    const [selectedSubCategory, setSelectedSubCategory] = useState("");
    const [selectedChildCategory, setSelectedChildCategory] = useState("");
    const [filteredSubCategories, setFilteredSubCategories] = useState([]);
    const [paymentForm, setPaymentForm] = useState(false);
    const [addedCourseData, setAddedCourseData] = useState([]);
    const [sponsoredCourse, setSponsoredCourse] = useState(false);
    const [sponsorshipData, setSponsorshipData] = useState([]);
    const [sponrshipPlan, setSponsorshipPlan] = useState();
    const [days, setDays] = useState("");
    const [courseAmount, setCourseAmount] = useState("");

    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState("");
    const [fileList, setFileList] = useState([]);
    const [classes, setClasses] = useState([]);
    const [selectedClassLevel, setSelectedCLassLevel] = useState();
    const fileInputRef = useRef(null);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isPlanModalVisible, setIsPlanModalVisible] = useState(false);
    const [isDurationModal, setIsDurationModal] = useState(false);
    const [coursePolicy, setCoursePolicy] = useState({
        refund_policy: "",
        is_premium: "paid",
        trial: 0,
        trial_days: 0,
        refundable: 0,
    });

    const [selectedKey, setSelectedKey] = useState('');
    const [stripePromise, setStripePromise] = useState(
        loadStripe(
            "pk_test_51NuUdDDhhEVxDG993lu35F4QwDa8YaZcktRtzp4d5f0Mtplas4c5Hc01lJFPH0R9XgshjLP7BWpoE6IYCanmu8JT00Il8HHzf6"
        )
    );
    const [clientSecret, setClientSecret] = useState("");
    const [addCourse, setAddCourse] = useState(false);
    const [startDate, setStartData] = useState("");
    const [endDate, setEndData] = useState("");
    const [helpModel, setHelpModel] = useState(false);
    // const [selectedValues, setSelectedValues] = useState([]);
    const fetchData = async () => {
        if (days !== "") {
            try {
                dispatch(uploadServices.getCalculatedCustom({ days })).then(
                    (response) => {
                        setCourseAmount(response.totalAmount);
                    }
                );
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        }
    };

    fetchData();

    const handleDaysChange = (e) => {
        setDays(e.target.value);
    };

    useEffect(() => {
        dispatch(getServices.getSponsorshipData())
            .then((response) => {
                setSponsorshipData(response?.data);
            })
            .catch((err) => {
                console.error(err);
            });
    }, [sponsoredCourse]);

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
    };

    const uploadButton = (
        <button
            style={{
                border: 0,
                background: "none",
            }}
            type="button"
        >
            <PlusOutlined />
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </button>
    );

    const getPaymentIntent = (fee) => {
        dispatch(
            uploadServices.PaymentIntent({
                amount: parseFloat(fee),
                currency: "SGD",
            })
        ).then((response) => {
            setClientSecret(response?.clientSecret);
        }).catch((err) => {
            console.error(err);
        });
    }

    const onFinish = (value) => {
        setAddCourse(true);
        const formData = new FormData();
        const files = fileInputRef.current.fileList;
        files.forEach((file, index) => {
            formData.append(`school_images[${index}]`, file.originFileObj);
        });
        formData.append("title", value?.title);
        formData.append("category_id", value?.category ? value?.category : "");
        formData.append("sub_category_id", value?.child_categories ? value?.child_categories : "");
        formData.append("child_category_id", value?.sub_categories ? value?.sub_categories : "");
        formData.append(
            "class_level",
            selectedClassLevel ? selectedClassLevel : ""
        );
        formData.append(
            "about_course",
            value?.about_this_course ? value?.about_this_course : ""
        );
        formData.append("fee", value?.fee_include ? value?.fee_include : "");
        formData.append(
            "special_instruction",
            value?.special_instruction ? value?.special_instruction : ""
        );
        if (courseImageCover[0]?.originFileObj !== undefined) {
            formData.append("image", courseImageCover[0]?.originFileObj);
        }
        if (document !== null) {
            formData.append("document", document);
        }
        formData.append("instructor_name", value?.instructor_name || "");
        formData.append(
            "work_experience",
            value?.work_experience !== undefined && value?.work_experience !== null
                ? parseInt(value?.work_experience)
                : ""
        );
        if (instructorProfileImage[0]?.originFileObj !== undefined) {

            formData.append("profile_photo", instructorProfileImage[0]?.originFileObj);
        }
        formData.append("about", value?.about || "");
        formData.append("course_start", startDate);
        formData.append("course_end", endDate);
        formData.append("visibility", value?.visibility ? value?.visibility : "");
        if (video !== null) {
            formData.append("video", videoAndThumbnail?.video);
        }
        if (videoThumbnail !== null) {
            formData.append("thumbnail", videoThumbnail?.thumbnail);
        }
        formData.append("refundable", coursePolicy.refundable);
        formData.append("refund_policy", coursePolicy.refund_policy);
        formData.append("trial", coursePolicy.trial);
        formData.append("trail_days", coursePolicy.trial_days);
        formData.append("is_premium", coursePolicy?.is_premium);
        formData.append("sponsored", sponsoredCourse ? 1 : 0);
        formData.append("address", value?.address ? value?.address : "");
        formData.append("postal_code", value?.postal_code ? value?.postal_code : "");
        if (faqs.length > 0) {
            formData.append("faq", JSON.stringify(faqs));
        }

        if (selectedKey) {
            formData.append("approach", selectedKey);
        }

        if (courseSlots?.course_slots?.length > 0) {
            formData.append("time_slots", JSON.stringify(courseSlots?.course_slots));
        }
        formData.append("course_status", value?.wsq === true ? 1 : 0)
        formData.append("is_sfc_status", value?.sfc === true ? 1 : 0)
        formData.append("course_code", value?.course_code);
        dispatch(uploadServices.addNewCourse(formData))
            .then((response) => {
                setAddedCourseData(response);
                if (sponsoredCourse) {
                    getPaymentIntent(response?.course?.fee)
                    localStorage.setItem("paymentType", "sponsoredCourse");
                    setIsModalVisible(true);
                } else {
                    Swal.fire(
                        "Course Added Successful!",
                        "You have successfully added course!",
                        "success"
                    ).then((result) => {
                        navigate("/vendor/my-courses");
                    });
                }
            })
            .catch((error) => {
                setAddCourse(false);

                Swal.fire({
                    title: error.response.data.error,
                    icon: "error",
                });
            });
    };


    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        faqForm.resetFields(); // Reset form fields
        setIsModalOpen(false); // Close modal
    };

    const handleCancel = () => {
        faqForm.resetFields(); // Reset form fields
        setIsModalOpen(false); // Close modal
    };

    const afterClose = () => {
        faqForm.resetFields();  // Reset fields after modal has fully closed
    };

    const showModalThumbnail = () => {
        setIsModalOpenThumbnail(true);
    };

    const handleOkThumbnail = () => {
        setIsModalOpenThumbnail(false);
    };

    const handleCancelThumbnail = () => {
        setIsModalOpenThumbnail(false);
    };

    useEffect(() => {
        dispatch(getServices.getCategories())
            .then((response) => {
                setCategories(response?.categories);
            })
            .catch((err) => { });

        dispatch(getServices.getClasses())
            .then((response) => {
                setClasses(response?.classes);
            })
            .catch((err) => {
                // console.log({ err });
            });
    }, [dispatch]);

    const handleFileChangeDoc = (e) => {
        const selectedFile = e.target.files[0];
        setDocument(selectedFile);
    };
    const handleVideo = ({ fileList }) => {
        const selectedFile = fileList[0]?.originFileObj;
        setVideo(selectedFile);
    };

    const handleThumbnail = ({ fileList }) => {
        const selectedFile = fileList[0]?.originFileObj;
        setVideoThumbnail(selectedFile);
    };

    const onFinishFAQ = (e) => {
        let currentFAQs = faqs;
        let question = e.question;
        let answer = e.answer;
        let generateId = currentFAQs.length + 1;

        let record = { id: generateId, question, answer };

        // Add the new record to the currentFAQs array
        let updatedFAQs = [...currentFAQs, record];

        // Update the state with the new array
        setFAQs(updatedFAQs);
        setIsModalOpen(false);
    };

    const onFinishFailedFAQ = (e) => { };

    const hangleVideoModal = () => {
        showModalThumbnail(true);
    };

    const handleUploadButton = () => {
        Swal.fire(
            " Added Successful!",
            "Video and thumbnail added successfully!",
            "success"
        ).then((result) => {
            setIsModalOpenThumbnail(false);
            // setUpload(true);
        });
    };

    const onChange = (date, dateString) => {
        setStartData(dateString);
    };

    const onEndDataChange = (date, dateString) => {
        setEndData(dateString);
    };

    const handlecategory = (value) => {
        setSelectedCategory(value);
        setSelectedSubCategory(null);
        setFilteredSubCategories([]);
    };
    const options = [
        { label: 'Physical', value: 2 },
        { label: 'Online', value: 1 },
        { label: 'Combined', value: 3 },
    ];

    const handleApproachChange = (value) => {
        setSelectedKey(value);
    };
    useEffect(() => {
        if (selectedCategory) {
            const filtered = categories.flatMap((category) =>
                category.child_category.filter(
                    (item) => item.parent_id === selectedCategory
                )
            );

            const childCategoryOptions = filtered.map((data) => ({
                name: data.name,
                id: data.id,
            }));

            const subCategoryOptions = filtered.flatMap((child) => {
                return (
                    child.sub_categories?.map((subData) => ({
                        name: subData.name,
                        id: subData.id,
                        category_id: subData.parent_id,
                    })) || []
                );
            });

            if (childCategoryOptions.length > 0) {
                setChildCategories(childCategoryOptions);
            }

            setFilteredSubCategories(
                subCategoryOptions.filter(
                    (item) => item.category_id === selectedChildCategory
                )
            );
            setSelectedSubCategory(null);
        }
    }, [categories, selectedCategory, selectedChildCategory]);

    const beforeUpload = (file) => {
        const isImage = file.type.startsWith("image/");
        if (!isImage) {
            alert("You can only upload image files!");
        }
        return isImage || Upload.LIST_IGNORE;
    };

    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };

    const handleClass = (e) => {
        setSelectedCLassLevel(e);
    };

    const handleCheckboxChange = (e) => {
        if (e.target.checked) {
            setSponsoredCourse(true);
        }
    };

    const handleModalOk = () => {
        setIsModalVisible(false);
        setIsPlanModalVisible(true);
    };

    const handleModalCancel = () => {
        setIsModalVisible(false);
    };

    const handlePlanModalOk = () => {
        setIsPlanModalVisible(false);
    };

    const handlePlanModalCancel = () => {
        setIsPlanModalVisible(false);
    };

    const handleDurationModalOk = () => {
        setPaymentForm(true);
        setIsDurationModal(false);
    };

    const handleDurationModalCancel = () => {
        setIsDurationModal(false);
        setIsPlanModalVisible(true);
    };

    const openDurationModal = () => {
        setIsDurationModal(true);
        setIsPlanModalVisible(false);
    };

    const handlePayment = (value) => {
        setSponsorshipPlan(value);
        setPaymentForm(true);
        setIsPlanModalVisible(false);
    };

    const handlePaymentFormCancle = () => {
        setPaymentForm(false);
    };

    const handlePaymentForm = () => {
        setPaymentForm(false);
    };

    const handlehelp = () => {
        setHelpModel(true);
    };
    const handleHelpCancle = () => {
        setHelpModel(false);
    };
    const handlehelpOk = () => {
        setHelpModel(false);
    };

    const renderDayRow = (day) => {
        const dayInfo = courseSlots?.course_slots?.find(
            (slot) => slot.week_day === day
        );
        const startTime = dayInfo?.start_time
            ? dayjs(dayInfo.start_time, "HH:mm")
            : null;
        const endTime = dayInfo?.end_time ? dayjs(dayInfo.end_time, "HH:mm") : null;

        return (
            <Row key={day} gutter={[16, 16]} align="middle" className='mb-3'>
                <Col span={3}>
                    <Checkbox
                        onChange={(e) => handleDayChange(day, e.target.checked)}
                        checked={!!dayInfo}
                    >
                        {day}
                    </Checkbox>
                </Col>
                <Col span={11}>
                    <Form.Item className='mb-0'>
                        <RangePicker
                            format="HH:mm"
                            onChange={(time) => handleTimeChange(day, time)}
                            value={startTime && endTime ? [startTime, endTime] : null}
                        />
                    </Form.Item>
                </Col>
            </Row>
        );
    };

    return (
        <>
            <Row className="add-course-main-row">
                <Col xl={4} className="d-xl-block d-md-none">
                    <Sidebar />
                </Col>
                <Col xl={20} md={24}>
                    <div className="mainContent add-new-course">
                        <div className="new-course-container">
                            <div className="new-course-inner-container">
                                <div className="header-div">
                                    <p className="add-new-course">Add New Item</p>
                                    <Button
                                        className="go-back-button"
                                        onClick={() => navigate("/vendor/my-courses")}
                                    >
                                        <span>
                                            <GoArrowLeft className="left-arrow-icon" />
                                        </span>
                                        Go Back
                                    </Button>
                                </div>

                                <div className="form-container">
                                    <Form
                                        name="basic"
                                        initialValues={{
                                            remember: true,
                                            wsq: false,
                                            sfc: false,
                                        }}
                                        onFinish={onFinish}
                                        onFinishFailed={onFinishFailed}
                                        autoComplete="off"
                                        className="add-new-course-form"
                                        layout="vertical"
                                    >
                                        <Row className="form-row">
                                            <Col md={24} className="custom-padding laptop-padd">
                                                <Form.Item
                                                    className="form-input-class"
                                                    label="Title"
                                                    name="title"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Please enter title.",
                                                        },
                                                    ]}
                                                >
                                                    <Input
                                                        className="input-box-login"
                                                        placeholder="Type here....."
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={8} className="input-new-course input_right_spacing">
                                                <Form.Item
                                                    className="form-input-class"
                                                    name="category"
                                                    label="Select Categories"
                                                // rules={[
                                                //     {
                                                //         required: true,
                                                //         message: "Please select category.",
                                                //     },
                                                // ]}
                                                >
                                                    <Select
                                                        className="input-box-login select-category"
                                                        placeholder="Category"
                                                        prefix={<MdOutlineCategory />}
                                                        onChange={(value) => handlecategory(value)}
                                                    >
                                                        {categories &&
                                                            categories.map((category) => (
                                                                <Select.Option
                                                                    key={category.id}
                                                                    value={category.id}
                                                                >
                                                                    {category.name}
                                                                </Select.Option>
                                                            ))}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col md={8} className="input-new-course input_left_spacing input_right_spacing">
                                                <Form.Item
                                                    className="form-input-class"
                                                    name="child_categories"
                                                    label="Select Sub-Categories"
                                                >
                                                    <Select
                                                        className="input-box-login sub-select-category"
                                                        placeholder="Sub-Categories"
                                                        prefix={<MdOutlineCategory />}
                                                        onChange={(value) =>
                                                            setSelectedChildCategory(value)
                                                        }
                                                        disabled={!selectedCategory}
                                                    >
                                                        {childCategories &&
                                                            childCategories.map((childCategory) => (
                                                                <Select.Option
                                                                    key={childCategory.id}
                                                                    value={childCategory.id}
                                                                >
                                                                    {childCategory.name}
                                                                </Select.Option>
                                                            ))}
                                                    </Select>
                                                </Form.Item>
                                            </Col>

                                            <Col md={8} className="input-new-course input_left_spacing pe-0">
                                                {/* as per the data this id sub Categories */}
                                                <Form.Item
                                                    className="form-input-class"
                                                    name="sub_categories"
                                                    label="Select Child Categories"
                                                >
                                                    <Select
                                                        className="input-box-login sub-select-category"
                                                        placeholder="Child-Categories"
                                                        prefix={<MdOutlineCategory />}
                                                        value={selectedSubCategory}
                                                        onChange={(value) => setSelectedSubCategory(value)}
                                                        disabled={!selectedCategory}
                                                    >
                                                        {filteredSubCategories &&
                                                            filteredSubCategories.map((subCategory) => (
                                                                <Select.Option
                                                                    key={subCategory.id}
                                                                    value={subCategory.id}
                                                                    disabled={
                                                                        selectedSubCategory === subCategory.id
                                                                    }
                                                                >
                                                                    {subCategory.name}
                                                                </Select.Option>
                                                            ))}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col
                                                md={8}
                                                className="custom-padding fee-instruction-upload-col special-instruction ps-0 input_right_spacing"
                                            >
                                                <Form.Item
                                                    className="form-input-class"
                                                    name="visibility"
                                                    label="Visibility"
                                                >
                                                    <Select
                                                        className="input-box-login select-category"
                                                        placeholder="Choose Visibility"
                                                        prefix={<MdOutlineCategory />}
                                                        label
                                                    >
                                                        <Select.Option value={"public"} key={"public"}>
                                                            Public
                                                        </Select.Option>
                                                        <Select.Option value={"private"} key={"private"}>
                                                            Private
                                                        </Select.Option>
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col md={8} className="input-new-course input_left_spacing input_right_spacing">
                                                <Form.Item
                                                    className="form-input-class"
                                                    name="course_class"
                                                    label="Select Session"
                                                // rules={[
                                                //     {
                                                //         required: true,
                                                //         message: "Please Select Session.",
                                                //     },
                                                // ]}
                                                >
                                                    <Select
                                                        className="input-box-login select-class"
                                                        placeholder="Class"
                                                        prefix={<MdOutlineCategory />}
                                                        onChange={(value) => handleClass(value)}
                                                    >
                                                        {classes &&
                                                            classes.map((classe) => (
                                                                <Select.Option
                                                                    key={classe.id}
                                                                    value={classe.id}
                                                                >
                                                                    {classe.name}
                                                                </Select.Option>
                                                            ))}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col md={8} className="input-new-course input_left_spacing input_right_spacing">
                                                <div className="d-flex justify-content-center align-items-center">
                                                    <Form.Item
                                                        className="form-input-class me-3"
                                                        name="wsq" valuePropName="checked" >
                                                        <Checkbox
                                                            key="wsq"
                                                            value="wsq"
                                                        >
                                                            WSQ
                                                        </Checkbox>
                                                    </Form.Item>
                                                    <Form.Item
                                                        className="form-input-class"
                                                        name="sfc" valuePropName="checked" >
                                                        <Checkbox
                                                            key="sfc"
                                                            value="sfc"
                                                        >
                                                            SFC
                                                        </Checkbox>
                                                    </Form.Item>
                                                </div>
                                            </Col>

                                        </Row>
                                        <Row>
                                            <Col md={8} className="input-new-course input_left_spacing input_right_spacing">
                                                <Form.Item
                                                    className="form-input-class"
                                                    name="course_class"
                                                    label="Select Approach"

                                                >
                                                    {options.map(option => (
                                                        <Checkbox
                                                            key={option.value}
                                                            checked={selectedKey === option.value}
                                                            onChange={() => handleApproachChange(option.value)}
                                                        >
                                                            {option.label}
                                                        </Checkbox>
                                                    ))}
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row className="form-row">
                                            <Col md={24} className="custom-padding laptop-padd">
                                                <Form.Item
                                                    className="form-input-class"
                                                    name="about_this_course"
                                                    label="Description"
                                                // rules={[
                                                //     {
                                                //         required: true,
                                                //         message: "Please enter brief about the course.",
                                                //     },
                                                // ]}
                                                >
                                                    <TextArea
                                                        className="leave-comment-text-area"
                                                        rows={4}
                                                        placeholder="Type here....."
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row className="form-row">
                                            <Col md={24} className="custom-padding laptop-padd">
                                                <Form.Item
                                                    className="form-input-class"
                                                    name="school_images"
                                                    valuePropName="file"
                                                    label="Upload Organisation Image"
                                                // rules={[
                                                //     {
                                                //         required: true,
                                                //         message: "Please upload images.",
                                                //     },
                                                // ]}
                                                >
                                                    <div className="upload-school-images-continer">
                                                        <Upload
                                                            ref={fileInputRef}
                                                            listType="picture-card"
                                                            multiple
                                                            getValueFromEvent={normFile}
                                                            beforeUpload={beforeUpload}
                                                        >
                                                            {fileList.length >= 8 ? null : uploadButton}
                                                        </Upload>
                                                        {previewImage && (
                                                            <Image
                                                                wrapperStyle={{
                                                                    display: "none",
                                                                }}
                                                                preview={{
                                                                    visible: previewOpen,
                                                                    onVisibleChange: (visible) =>
                                                                        setPreviewOpen(visible),
                                                                    afterOpenChange: (visible) =>
                                                                        !visible && setPreviewImage(""),
                                                                }}
                                                                src={previewImage}
                                                            />
                                                        )}
                                                    </div>
                                                </Form.Item>
                                            </Col>
                                        </Row>

                                        <Row className="form-row fee-instruction-upload-row">
                                            <Col md={12}
                                                className="fee-instruction-upload-col fee-include input_right_spacing"
                                            >
                                                <Form.Item
                                                    className="form-input-class"
                                                    name="fee_include"
                                                    label="Enter Fee"
                                                // rules={[
                                                //     {
                                                //         required: true,
                                                //         message: "Please enter fee.",
                                                //     },
                                                // ]}
                                                >
                                                    <Input
                                                        type="number"
                                                        className="input-box-login"
                                                        placeholder="Type here....."
                                                        prefix={<CiDollar />}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col
                                                md={12}
                                                className="fee-instruction-upload-col special-instruction input_left_spacing "
                                            >
                                                <Form.Item
                                                    className="form-input-class"
                                                    name="special_instruction"
                                                    label="Special Instruction (Optional)"
                                                >
                                                    <Input
                                                        className="input-box-login"
                                                        placeholder="Enter Instructions If Any?"
                                                        prefix={<BsFileCode />}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col
                                                md={12}
                                                className="fee-instruction-upload-col input_right_spacing"
                                            >
                                                <Form.Item
                                                    name="course_start"
                                                    label="Start Date"
                                                // rules={[
                                                //     {
                                                //         required: true,
                                                //         message: "Please select start date!",
                                                //     },
                                                // ]}
                                                >
                                                    <DatePicker onChange={onChange} />
                                                </Form.Item>
                                            </Col>

                                            <Col
                                                md={12}
                                                className="fee-instruction-upload-col input_left_spacing"
                                            >
                                                <Form.Item name=" course_end" label="End Date">
                                                    <DatePicker onChange={onEndDataChange} />
                                                </Form.Item>
                                            </Col>

                                            <Col md={12}>
                                                <Form.Item
                                                    className="form-input-class input_right_spacing"
                                                    name="postal_code"
                                                    label='Postal Code'
                                                >
                                                    <Input
                                                        className="input-box-login"
                                                        placeholder="Enter postal code"
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col md={12}>
                                                <Form.Item
                                                    className="form-input-class input_left_spacing"
                                                    name="address"
                                                    label='Address'
                                                >
                                                    <Input
                                                        className="input-box-login"
                                                        placeholder="Enter address"
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col md={12}>
                                                <Form.Item
                                                    className="form-input-class input_right_spacing"
                                                    name="course_code"
                                                    label='Course Code'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Please enter item code.",
                                                        },
                                                    ]}
                                                >
                                                    <Input
                                                        className="input-box-login"
                                                        placeholder="Item Code"
                                                    />
                                                </Form.Item>
                                            </Col>

                                            <Col
                                                md={8}
                                                className="input-new-course fee-instruction-upload-col upload-image"
                                            >
                                                <Form.Item
                                                    className="form-input-class"
                                                    name="course_image"
                                                    label="Upload Item Image"
                                                >
                                                    <Upload
                                                        action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
                                                        listType="picture-card"
                                                        fileList={courseImageCover}
                                                        onPreview={handlePreview}
                                                        onChange={handleCourseCoverChange}
                                                        maxCount={1}
                                                    >
                                                        {courseImageCover.length > 0 ? null : uploadButton}
                                                    </Upload>
                                                    {previewImage && (
                                                        <Image
                                                            wrapperStyle={{
                                                                display: "none",
                                                            }}
                                                            preview={{
                                                                visible: previewOpen,
                                                                onVisibleChange: (visible) =>
                                                                    setPreviewOpen(visible),
                                                                afterOpenChange: (visible) =>
                                                                    !visible && setPreviewImage(""),
                                                            }}
                                                            src={previewImage}
                                                        />
                                                    )}
                                                </Form.Item>
                                            </Col>
                                        </Row>

                                        <div class="course_details_divider"></div>

                                        <p className="instructor-detains">
                                            Instructor’s Detail (Optional)
                                        </p>

                                        <Row className="form-row instructor-details-container">
                                            <Col
                                                md={24}
                                                className="input-new-course fee-instruction-upload-col upload-image"
                                            >
                                                <Form.Item
                                                    className="form-input-class"
                                                    name="profile_photo"
                                                    label="Profile Photo"
                                                // rules={[
                                                //   {
                                                //     required: true,
                                                //     message: "Please upload profile photo.",
                                                //   },
                                                // ]}
                                                >
                                                    <Upload
                                                        action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
                                                        listType="picture-card"
                                                        fileList={instructorProfileImage}
                                                        onPreview={handlePreview}
                                                        onChange={handleInstructorProfileImageChange}
                                                        maxCount={1}
                                                        beforeUpload={(file) => {
                                                            const isValidType =
                                                                file.type === "image/jpeg" ||
                                                                file.type === "image/png" ||
                                                                file.type === "image/jpg" ||
                                                                file.type === "image/gif";
                                                            if (!isValidType) {
                                                                console.info({ isValidType })
                                                                Swal.fire(
                                                                    "Failed to upload",
                                                                    "You can only upload JPEG, PNG, JPG, or GIF files!",
                                                                    "error",
                                                                )
                                                            }
                                                            return isValidType;
                                                        }}
                                                    >
                                                        {instructorProfileImage.length > 0
                                                            ? null
                                                            : uploadButton}
                                                    </Upload>
                                                    {previewImage && (
                                                        <Image
                                                            wrapperStyle={{
                                                                display: "none",
                                                            }}
                                                            preview={{
                                                                visible: previewOpen,
                                                                onVisibleChange: (visible) =>
                                                                    setPreviewOpen(visible),
                                                                afterOpenChange: (visible) =>
                                                                    !visible && setPreviewImage(""),
                                                            }}
                                                            src={previewImage}
                                                        />
                                                    )}
                                                </Form.Item>
                                            </Col>
                                            <Col
                                                md={12}
                                                className="fee-instruction-upload-col input_right_spacing"
                                            >
                                                <Form.Item
                                                    className="form-input-class"
                                                    name="instructor_name"
                                                    label="Instructor Name"
                                                // rules={[
                                                //   {
                                                //     required: true,
                                                //     message: "Please enter instructor name.",
                                                //   },
                                                // ]}
                                                >
                                                    <Input
                                                        className="input-box-login"
                                                        placeholder="Enter name here"
                                                        prefix={<CiUser color="#7c8597" />}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col md={12} className="input-new-course input_left_spacing">
                                                <Form.Item
                                                    className="form-input-class"
                                                    name="work_experience"
                                                    label="Work Experience"
                                                // rules={[
                                                //   {
                                                //     required: true,
                                                //     message: "Please enter work experience.",
                                                //   },
                                                // ]}
                                                >
                                                    <Input
                                                        type="number"
                                                        className="input-box-login"
                                                        placeholder="Enter Expirence"
                                                        prefix={<LiaCalendarAltSolid color="#7c8597" />}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>

                                        <Row className="form-row">
                                            <Col md={24} className="input-new-course">
                                                <Form.Item
                                                    className="form-input-class"
                                                    name="about"
                                                    label="About"
                                                // rules={[
                                                //   {
                                                //     required: true,
                                                //     message: "Please enter about the instructor .",
                                                //   },
                                                // ]}
                                                >
                                                    <Input
                                                        className="input-box-login"
                                                        placeholder="Type something"
                                                        prefix={<BsInfoCircle color="#7c8597" />}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <div class="course_details_divider"></div>
                                        <div className="course-faq-container">
                                            <p className="frequently-asked-que">
                                                Frequently Asked Questions (FAQ’s)❓
                                            </p>
                                            <Button onClick={showModal}>
                                                Add More <FiPlusCircle width={20} height={20} />
                                            </Button>
                                        </div>
                                        {faqs && faqs.length > 0 ? (
                                            <>
                                                <Row className="faq-box-shadow-none course_faq_box">
                                                    {faqs.map((item) => (
                                                        <Col md={12}>
                                                            <Accordion>
                                                                <AccordionSummary
                                                                    expandIcon={<FaArrowRightLong />}
                                                                    aria-controls="panel1-content"
                                                                    id={item.key}
                                                                >
                                                                    {item.question}
                                                                </AccordionSummary>
                                                                <AccordionDetails>
                                                                    {item.answer}
                                                                </AccordionDetails>
                                                            </Accordion>
                                                        </Col>
                                                    ))}
                                                </Row>
                                            </>
                                        ) : (
                                            ""
                                        )}
                                        <div class="course_details_divider"></div>
                                        <CoursePolicy
                                            setCoursePolicy={setCoursePolicy}
                                            coursePolicy={coursePolicy}
                                            handlehelp={handlehelp}
                                            type={"add"}
                                        />

                                        <div className="cancellation-pollicy-checkbox-container p-0">
                                            {/* <p className="instructor-detains">Mark as Sponsored Course</p> */}
                                            <Checkbox
                                                className="cancellation-pollicy-checkbox"
                                                onChange={handleCheckboxChange}
                                            >
                                                <span className="cancellation-policy-text">cancellation-pollicy-checkbox-container
                                                    Is this items is sponsored items ?
                                                </span>
                                            </Checkbox>
                                        </div>

                                        <div class="course_details_divider"></div>

                                        <Form.Item className="add_course_time_slots">
                                            <p className="course_main_title">
                                                Item Slots
                                            </p>
                                            {allDays.map((day) => renderDayRow(day))}
                                        </Form.Item>

                                        <div class="course_details_divider"></div>

                                        <Row className="form-row">
                                            <Col
                                                md={24}
                                                className="input-new-course upload-documents"
                                            >
                                                <p
                                                    style={{
                                                        textAlign: "start",
                                                        fontFamily: "POPPINS",
                                                    }}
                                                >
                                                    Upload Documents
                                                </p>

                                                <Form.Item
                                                    className="form-input-class"
                                                    name="upload-document"
                                                // rules={[
                                                //   {
                                                //     required: true,
                                                //     message: "Please upload document.",
                                                //   },
                                                // ]}
                                                >
                                                    <label
                                                        for="logo-document"
                                                        className="custom-upload-logo d-flex justify-content-between upload-document-label"
                                                    >
                                                        <span className="custom-input-logo-left d-flex align-items-center gap-2">
                                                            <LuUpload className="form-icons" />
                                                            {document
                                                                ? document?.name
                                                                : "Drop or Upload Document and Photos"}
                                                        </span>
                                                    </label>
                                                    <Input
                                                        style={{ display: "none !important" }}
                                                        type="file"
                                                        id="logo-document"
                                                        className="input-box-login upload-document-input"
                                                        placeholder="www.welloffun.com"
                                                        hidden
                                                        onChange={handleFileChangeDoc}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <div class="course_details_divider"></div>
                                        <Row className="form-row">
                                            <Col md={24} className="input-new-course">
                                                <p
                                                    style={{
                                                        textAlign: "start",
                                                        fontFamily: "POPPINS",
                                                    }}
                                                >
                                                    Upload Video and Thumbnail
                                                </p>
                                                <Form.Item
                                                    className="form-input-class upload_course_video"
                                                    name="upload-document"
                                                >
                                                    <>
                                                        <Upload
                                                            listType="text"
                                                            maxCount={1}
                                                            accept="video/*"
                                                            onChange={(e) => handleVideoUpload(e)}
                                                            onRemove={handleRemoveVideo} // Handle video removal
                                                        >
                                                            <Button className="video-upload">
                                                                Click to Upload Video
                                                            </Button>
                                                        </Upload>

                                                        {videolUrl && (
                                                            <div style={{ marginTop: 20, marginBottom: 20 }}>
                                                                <video width="400" controls>
                                                                    <source src={videolUrl} type="video/mp4" />
                                                                    Your browser does not support the video tag.
                                                                </video>
                                                            </div>
                                                        )}

                                                        <Upload
                                                            listType="picture"
                                                            maxCount={1}
                                                            accept="image/*"
                                                            onChange={(e) => handleThumbnailUpload(e)}
                                                            onRemove={handleRemoveThumbnail} // Handle thumbnail removal
                                                        >
                                                            <Button className="thumbnail-upload">
                                                                Click to Upload Thumbnail
                                                            </Button>
                                                        </Upload>
                                                    </>
                                                </Form.Item>
                                            </Col>
                                        </Row>

                                        <Form.Item
                                            className="mb-0 add-course-form-item"
                                            wrapperCol={{
                                                offset: 8,
                                                span: 24,
                                            }}
                                        >
                                            <Button
                                                htmlType="submit"
                                                className="register-button"
                                                disabled={addCourse}
                                            >
                                                Add Items
                                            </Button>
                                        </Form.Item>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>

            <Modal
                className="faq_modal"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                style={{ backgroundColor: "#fff", margin: "5rem auto" }}
                afterClose={afterClose}
            >
                <Form
                    name="basic"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 24 }}
                    onFinish={onFinishFAQ}
                    onFinishFailed={onFinishFailedFAQ}
                    form={faqForm} // Pass form instance here
                >
                    <Row className="form-row instructor-details-container">
                        <Col md={24} className="input-new-courses fee-instruction-upload-col upload-image model-input-p24">
                            <p style={{ textAlign: "start", fontFamily: "POPPINS", fontSize: "13px", fontWeight: "400" }}>Question</p>
                            <Form.Item
                                className="form-input-class"
                                name="question"
                                rules={[
                                    { required: true, message: "Please enter question." }
                                ]}
                            >
                                <Input
                                    className="input-box-login"
                                    placeholder="Enter question here"
                                // prefix={<FaClipboardQuestion color="#7c8597" />}
                                />
                            </Form.Item>
                        </Col>
                        <Col md={24} className="input-new-courses model-input-p24">
                            <p style={{ textAlign: "start", fontFamily: "POPPINS", fontSize: "13px", fontWeight: "400" }}>Answer</p>
                            <Form.Item
                                className="form-input-class"
                                name="answer"
                                rules={[
                                    { required: true, message: "Please enter answer." }
                                ]}
                            >
                                <TextArea
                                    className="input-box-login"
                                    placeholder="Enter answer here"
                                    prefix={<MdOutlineQuestionAnswer color="#7c8597" />}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item className="mb-0" wrapperCol={{ offset: 8, span: 24 }}>
                        <Button className="register-button" htmlType="submit">Add FAQ</Button>
                    </Form.Item>
                </Form>
            </Modal>

            <Modal
                style={{ backgroundColor: "#fff" }}
                open={isModalOpenThumbnail}
                onOk={handleOkThumbnail}
                onCancel={handleCancelThumbnail}
            >
                <h3 className="video-thumbnail-hear">Upload Video and Thumbnail</h3>
                <div className="upload-video-yhumbnail-container">
                    <Upload
                        listType="text"
                        maxCount={1}
                        accept="video/*"
                        onChange={handleVideo}
                    >
                        <Button className="video-upload">Click to Upload Video</Button>
                    </Upload>
                    <Upload
                        listType="picture"
                        maxCount={1}
                        accept="image/*"
                        onChange={handleThumbnail}
                    >
                        <Button className="thumbnail-upload">
                            Click to Upload Thumbnail
                        </Button>
                    </Upload>
                </div>
                <div className="video-thumbnail-upload-container">
                    <Button
                        className="video-thumbnail-upload-button"
                        onClick={() => handleUploadButton()}
                    >
                        Upload
                    </Button>
                </div>
            </Modal>

            {/* Plan Select Modal */}
            <Modal
                title="Unlock premium exposure by subscribing to our exclusive plan!"
                open={isModalVisible}
                onOk={handleModalOk}
                onCancel={handleModalCancel}
                centered
                className="planModal"
                okText="Yes"
            >
                <div className="planBody">
                    <p className="upperText">
                        With a subscription, you can list your items in the high-visibility
                        Sponsorship section and reach more students than ever before.
                    </p>
                    <p className="mainText">
                        Would you like to proceed with marking this items as sponsored?
                    </p>
                </div>
            </Modal>

            {/*PaymentForm */}

            <Modal
                title="Payment"
                open={paymentForm}
                onOk={handlePaymentForm}
                onCancel={handlePaymentFormCancle}
                centered
                className="paymentModal"
                okText="Done"
                width={980}
            >
                <Elements stripe={stripePromise} options={{ clientSecret }}>
                    <StripePaymentForm
                        paymentCourseData={addedCourseData}
                        sponrshipPlan={sponrshipPlan}
                    />
                </Elements>
            </Modal>

            {/* Custom Duration Modal */}
            <Modal
                title="Add Custom Duration"
                open={isDurationModal}
                onOk={handleDurationModalOk}
                onCancel={handleDurationModalCancel}
                centered
                className="planModal"
                okText="Pay"
            >
                <div className="planBody">
                    <p className="upperText text-center">
                        Set a custom item duration to tailor the learning experience to your
                        students' needs. Adjust start and end dates now.
                    </p>
                </div>

                <Row>
                    <Col span={12}>
                        <Input
                            placeholder="Enter days count"
                            type="number"
                            value={days}
                            onChange={(e) => setDays(e.target.value)}
                            onKeyUp={handleDaysChange}
                            style={{ marginBottom: "1rem", width: "90%" }}
                        />
                    </Col>
                    <Col span={12}>
                        <Input
                            placeholder="item amount"
                            value={courseAmount}
                            onChange={(e) => setCourseAmount(e.target.value)}
                            style={{ width: "90%" }}
                        />
                    </Col>
                </Row>
            </Modal>

            {/* Plan Pricing Modal */}
            <Modal
                open={isPlanModalVisible}
                onOk={handlePlanModalOk}
                onCancel={handlePlanModalCancel}
                centered
                className="planPriceModal"
                footer={false}
                width={1140}
            >
                <div className="modalHeader">
                    <div className="textSection">
                        <h4 className="modalTitle">
                            Unlock premium exposure by subscribing to our exclusive plan!
                        </h4>
                        <p className="modalSubTItle">Boost Your item with Sponsorship</p>
                    </div>
                    <div className="btnSection">
                        <Button
                            className="durationButton"
                            onClick={() => openDurationModal()}
                        >
                            Custom Duration
                        </Button>
                    </div>
                </div>
                <div className="modalBody">
                    <div className="row d-flex justify-content-center">
                        {sponsorshipData &&
                            sponsorshipData?.map((sponsorData, index) => {
                                return (
                                    <div className="col-xl-4 col-md-6 mb-4">
                                        <div
                                            className={
                                                index === 0
                                                    ? "cards priceCard starterCard"
                                                    : index === 1
                                                        ? "cards priceCard proCard"
                                                        : "cards priceCard eliteCard"
                                            }
                                        >
                                            <div className="cardBody">
                                                <div className="cardTopSection">
                                                    <h4 className="cardTitle">{sponsorData?.name}</h4>
                                                    <p className="cardSubText">
                                                        {sponsorData?.desciption
                                                            ?.replace(/<\/?p>/g, "")
                                                            .replace(/&nbsp;/g, "\n")}
                                                    </p>
                                                    <div className="cardDetailList">
                                                        {sponsorData?.benefits?.map((benefit) => (
                                                            <div className="cardDetailListItem">
                                                                <span className="icon">
                                                                    <HiMiniCheckBadge />
                                                                </span>
                                                                <span className="dtlText">{benefit}</span>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                                <div className="cardBottomSection">
                                                    <button
                                                        type="button"
                                                        className="btn buyNowButton"
                                                        onClick={(e) => handlePayment(sponsorData)}
                                                    >
                                                        Buy Now
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                    </div>
                </div>
            </Modal>
            <Modal
                title="Standard list"
                open={helpModel}
                onOk={handlehelpOk}
                onCancel={handleHelpCancle}
                centered
                className="course_policy_popup"
            >
                <div>
                    <h3>Eligibility for Refund</h3>
                    <p>
                        <strong>Timeframe:</strong> Refunds are available if the request is
                        made within [X] days of purchase or before the course starts.
                    </p>
                    <p>
                        <strong>Course Access:</strong> No refunds will be issued after the
                        course has started or if access to course materials has been
                        granted.
                    </p>

                    <h3>Refund Request Process</h3>
                    <p>
                        <strong>How to Request:</strong> To request a refund, please contact
                        our support team at [support email/phone number] with your order
                        details and reason for the refund.
                    </p>
                    <p>
                        <strong>Required Information:</strong> Include your order number,
                        the date of purchase, and any other relevant information to process
                        your request.
                    </p>

                    <h3>Refund Amount</h3>
                    <p>
                        <strong>Full Refund:</strong> A full refund is available if the
                        request is made within [X] days of purchase and before accessing the
                        course materials.
                    </p>
                    <p>
                        <strong>Partial Refund:</strong> A partial refund may be available
                        if the request is made after accessing the course but before
                        [specific time or condition].
                    </p>
                    <p>
                        <strong>Non-Refundable:</strong> Certain fees, such as
                        administrative or processing fees, may be non-refundable.
                    </p>

                    <h3>Processing Time</h3>
                    <p>
                        <strong>Refund Processing:</strong> Refunds will be processed within
                        [X] business days of approval. The time it takes for the refund to
                        appear in your account may vary depending on your payment method and
                        financial institution.
                    </p>

                    <h3>Non-Eligibility for Refund</h3>
                    <p>
                        <strong>No Show:</strong> No refunds will be issued for failure to
                        attend the course without prior notice.
                    </p>
                    <p>
                        <strong>Content Downloads:</strong> Once downloadable content has
                        been accessed or downloaded, it may not be eligible for a refund.
                    </p>

                    <h3>Cancellation by Provider</h3>
                    <p>
                        <strong>Course Cancellation:</strong> If we cancel the course for
                        any reason, a full refund will be issued, or you will be given the
                        option to transfer to a different course.
                    </p>
                    <p>
                        <strong>Rescheduling:</strong> If the course is rescheduled, you
                        will be offered the option to attend the rescheduled course or
                        request a refund.
                    </p>

                    <h3>Refund Policy Changes</h3>
                    <p>
                        <strong>Policy Updates:</strong> We reserve the right to change this
                        refund policy at any time. Any changes will be communicated to you
                        via email or posted on our website.
                    </p>

                    <h3>Contact Information</h3>
                    <p>
                        <strong>Support Contact:</strong> For any questions or concerns
                        regarding the refund policy, please contact us at [support
                        email/phone number].
                    </p>
                </div>
            </Modal>
        </>
    );
}
