import React, { useState, useEffect } from "react";
import { Rate, Card, Image, Tabs, Row, Col, Flex } from "antd";
import { MdFileCopy } from "react-icons/md";
import { TiSocialTwitter } from "react-icons/ti";
import { FaFacebookF } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa";
import { FaGithub } from "react-icons/fa";
import * as uploadService from "../../services/uploadServices";
import * as getServices from "../../services/getServices";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Slider from "react-slick";
import { FaLock } from "react-icons/fa";
import { FaUnlock } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { featureImg2 } from "../../constant/images";
import Swal from "sweetalert2";
import {
	FunAcademyLogo
} from "../../constant/images";
import { MdOutlineLocalPhone } from "react-icons/md";
import { TfiEmail } from "react-icons/tfi";
import { IoLocationOutline } from "react-icons/io5";
export default function VendorDetails() {

	const isUserLoggedIn = useSelector((state) => state.user.isUserLoggedIn);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const userData = useSelector((state) => state.user.userData);
	const [paymentDetails, setPaymentDetails] = useState();
	const [sponsoredCourses, setSponsoredCourses] = useState([]);
	const [wishlistData, setWishlistData] = useState([])

	const getWishlistData = () => {
		dispatch(getServices.GetWistlistDataUser(userData?.user?.id)).then((response) => {
			setWishlistData(response?.course_details)
		}).catch((error) => {
			console.error({ error });
		})
	}


	useEffect(() => {
		if (
			userData?.user?.id !== undefined
		) {
			dispatch(
				getServices?.getPaymentDetails(
					userData?.user?.id
				)
			)
				.then((response) => {
					setPaymentDetails(response?.payments);
				})
				.catch((err) => {
					console.error({ err });
				});
		}
		if (isUserLoggedIn) {
			getWishlistData()
		}
	}, []);

	const categories = []

	const handleWishlistList = (course) => {

		let formData = new FormData();
		formData.append("course_id", course?.id)

		try {

			dispatch(uploadService.addRemoveCourseWishlist(formData)).then((response) => {
				if (isUserLoggedIn) {
					getWishlistData()
				}
				Swal.fire(
					"Success!",
					`${response?.message}`,
					"success"
				)
			}).catch((error) => {
				Swal.fire(
					"Failed",
					"Unable to add item to wishlist.",
					"error"
				)
			})

		} catch (e) {
			Swal.fire(
				"Failed",
				"Unable to add item to wishlist.",
				"error"
			)
		}

	}



	useEffect(() => {
		dispatch(getServices?.getSponsoredCourses())
			.then((response) => {
				setSponsoredCourses(response?.data);
			})
			.catch((err) => {
				console.error({ err });
			});
	}, [])


	const Category = (category_id) => {
		const category = categories?.find(
			(category) => category?.id === category_id
		);
		return category?.name;
	};

	const handleCourseData = (values) => {
		navigate(`/course/${values?.id}/${values?.title}`, {
			state: { courseData: values?.course, vendorData: values?.vendor },
		});
	};

	const settingsFeature = {
		dots: true,
		speed: 500,
		arrow: true,
		slidesToShow: 3,
		slidesToScroll: 1,
		infinite: false,
		autoplay: false,
		autoplaySpeed: 1000,
		arrows:
			sponsoredCourses && sponsoredCourses?.filter((course) => course?.course?.status !== "pending")?.length > 3,
		responsive: [
			{
				breakpoint: 1025,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1
				}
			},
			{
				breakpoint: 992,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					initialSlide: 1
				}
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}
		]
	};

	// const handlePurchase = (coursedata) => {
	//     if (isUserLoggedIn || isVendorLoggedIn) {
	//         setPaymentCourseData(coursedata);
	//         setIsModalOpen(!isModalOpen);
	//     } else {
	//         navigate("/login");
	//     }
	// };

	const DateFormatter = (date) => {
		const inputDate = date;

		const dateObj = new Date(inputDate);

		const monthNames = [
			"January",
			"February",
			"March",
			"April",
			"May",
			"June",
			"July",
			"August",
			"September",
			"October",
			"November",
			"December",
		];

		const day = dateObj.getDate();
		const month = monthNames[dateObj.getMonth()];
		const year = dateObj.getFullYear();

		const formatDate = `${day} ${month} ${year}`;
		return formatDate;
	};

	const handleFreeCourse = (courseData) => {
		try {
			const formData = new FormData();
			formData.append(
				"course_id", courseData?.id
			);
			formData.append("email", userData?.user?.email);
			formData.append("amount", 0);
			formData.append("course_fee", 0);
			dispatch(uploadService.payment(formData)).then((response) => {
				Swal.fire(
					"Item Enrolled Successfully",
					"You have been successfully enrolled in the Item.",
					"success"
				)

			}).catch((error) => {
				Swal.fire("Failed", error.message);
			})
		} catch (e) {
			console.error('Error in free item purchase', e)
			return
		}
	}



	const items = [
		{
			key: "1",
			label: <p className="vendor-about">About</p>,
			children: (
				<>
					<Row className="vendor-about-row">
						<Col className="vendor-about-col-one" md={2}>
							<Image
								className="review-person-image"
								preview={false}
								src={FunAcademyLogo}
							/>
						</Col>
						<Col className="vendor-about-col-two" md={22}>
							<div className="name-date-container">
								<p className="reviewer-name">Fiza Khan</p>
								<p className="reviewer-date">March 13, 2024</p>
							</div>
							<Rate className="vendor-rating" defaultValue={4} />
							<p className="reviewer-review">
								Quisque nec non amet quis. Varius tellus justo odio parturient
								mauris curabitur lorem in. Pulvinar sit ultrices mi ut eleifend
								luctus ut. Id sed faucibus bibendum augue id cras purus. At eget
								euismod cursus non. Molestie dignissim sed volutpat feugiat vel.
							</p>
						</Col>
					</Row>
					<Row className="vendor-about-row">
						<Col className="vendor-about-col-one" md={2}>
							<Image
								className="review-person-image"
								preview={false}
								src={FunAcademyLogo}
							/>
						</Col>
						<Col className="vendor-about-col-two" md={22}>
							<div className="name-date-container">
								<p className="reviewer-name">Fiza Khan</p>
								<p className="reviewer-date">March 13, 2024</p>
							</div>
							<Rate className="vendor-rating" defaultValue={4} />
							<p className="reviewer-review">
								Quisque nec non amet quis. Varius tellus justo odio parturient
								mauris curabitur lorem in. Pulvinar sit ultrices mi ut eleifend
								luctus ut. Id sed faucibus bibendum augue id cras purus. At eget
								euismod cursus non. Molestie dignissim sed volutpat feugiat vel.
							</p>
						</Col>
					</Row>
				</>
			),
		},
		{
			key: "2",
			label: <p className="vendor-review-rating">Reviews & Ratings</p>,
			children: (
				<>
					<Row className="vendor-about-row">
						<Col className="vendor-about-col-one" md={2}>
							<Image
								className="review-person-image"
								preview={false}
								src={FunAcademyLogo}
							/>
						</Col>
						<Col className="vendor-about-col-two" md={22}>
							<div className="name-date-container">
								<p className="reviewer-name">Fiza Khan</p>
								<p className="reviewer-date">March 13, 2024</p>
							</div>
							<Rate className="vendor-rating" defaultValue={4} />
							<p className="reviewer-review">
								Quisque nec non amet quis. Varius tellus justo odio parturient
								mauris curabitur lorem in. Pulvinar sit ultrices mi ut eleifend
								luctus ut. Id sed faucibus bibendum augue id cras purus. At eget
								euismod cursus non. Molestie dignissim sed volutpat feugiat vel.
							</p>
						</Col>
					</Row>
					<Row className="vendor-about-row">
						<Col className="vendor-about-col-one" md={2}>
							<Image
								className="review-person-image"
								preview={false}
								src={FunAcademyLogo}
							/>
						</Col>
						<Col className="vendor-about-col-two" md={22}>
							<div className="name-date-container">
								<p className="reviewer-name">Fiza Khan</p>
								<p className="reviewer-date">March 13, 2024</p>
							</div>
							<Rate className="vendor-rating" defaultValue={4} />
							<p className="reviewer-review">
								Quisque nec non amet quis. Varius tellus justo odio parturient
								mauris curabitur lorem in. Pulvinar sit ultrices mi ut eleifend
								luctus ut. Id sed faucibus bibendum augue id cras purus. At eget
								euismod cursus non. Molestie dignissim sed volutpat feugiat vel.
							</p>
						</Col>
					</Row>
				</>
			),
		},
	];

	const onChange = (key) => {
		// console.log(key);
	};

	return (
		<>
			<div className="vendor-details-container">
				<div className="vendor-details-banner-outer-conatiner">
					<div className="vendor-details-banner-inner-conatiner container">

						<Row>
							<Col xl={16} md={12}>
								<div className="vendor-details-banner-one">
									<div className="field-container">
										<p className="vendor-field">Art & Craft</p>
										<p className="vendor-field">Web Development</p>
									</div>
									<p className="no-of-lessons">
										<span className="copy-icon-span">
											<MdFileCopy color="#fff" className="copy-icon" />
										</span>{" "}
										20 Lessons
									</p>
									<p className="vendor-name">Fun Academy</p>
									<p className="vendor-description">
										Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
										eiusmod tempor incididunt ut labore et dolore magna aliqua.
									</p>
									<div className="vendor-rating-container">
										<p className="rate-text">4.8</p>
										<Rate className="vendor-rate" defaultValue={4} />
									</div>
								</div>
							</Col>
							<Col xl={8} md={12}>
								<div className="vendor-details-banner-two">
									<div className="social-icon-container">
										<span className="twitter-icon-span">
											<TiSocialTwitter color="#fff" className="twitter-icon" />
										</span>
										<span className="facebook-icon-span">
											<FaFacebookF color="#fff" className="facebook-icon" />
										</span>
										<span className="instagram-icon-span">
											<FaInstagram color="#fff" className="instagram-icon" />
										</span>
										<span className="git-icon-span">
											<FaGithub color="#fff" className="git-icon" />
										</span>
									</div>
									<div className="vendor-details-card-container">
										<Card
											className="vendor-list-card"
											cover={
												<Image
													className="vendor-card-image"
													preview={false}
													src={FunAcademyLogo}
												/>
											}
										>
											<div className="card-body-container">
												<div className="phone-container">
													<p className="telephone-outer">
														<MdOutlineLocalPhone
															color="#106AB3"
															className="telephone-icon"
														/>
													</p>
													<p className="phone-number">+91 9876543210</p>
												</div>
												<div className="mail-container">
													<p className="mail-outer">
														<TfiEmail color="#106AB3" className="mail-icon" />
													</p>
													<p className="mail-address">info@welloffun.com</p>
												</div>
												<div className="location-container">
													<p className="location-outer">
														<IoLocationOutline
															color="#106AB3"
															className="location-icon"
														/>
													</p>
													<p className="location-address">
														203, St. Mountain View, San Francisco, California, USA
													</p>
												</div>
											</div>
										</Card>
									</div>
								</div>
							</Col>
						</Row>
					</div>
				</div>
				<div className="vendor-information-container container">
					<div className="about-review-tab-conatiner">
						<Tabs defaultActiveKey="1" items={items} onChange={onChange} />
					</div>

					{/* Carousal Section */}
					<div className="sponsoredCourses-section" >
						<h1 className="feature-header border-bottom-heading bottom-border-center">
							Our Items
						</h1>
					</div>
					<div className="container" style={{ width: "100%" }}>
						<Slider className="slider-outer-2 row" {...settingsFeature}>
							{sponsoredCourses
								?.filter((courseData) => courseData?.course !== null)
								?.map((course) => (
									<>

										<Card
											className="feature-card home-card"
											hoverable
											cover={
												<img
													// style={{ height: "250px" }}
													src={course?.course?.image ? course?.course?.image : featureImg2}
													alt="Course Image"
												/>
											}
										>
											<div
												style={{
													position: "absolute",
													left: "0",
													top: "2rem",
													zIndex: "99999",
													padding: "10px 20px",
													backgroundColor: "#106ab369",
													borderRadius: "0 10px 10px 0",
												}}
												className="expire-container"
											>
												{paymentDetails?.some(
													(item) => item?.course_id == course?.id
												) ? (
													<span className="Lock-icon">
														<FaUnlock color="#fff" />
													</span>
												) : (
													<span className="Lock-icon">
														<FaLock color="#fff" />
													</span>
												)}
											</div>
											<div
												style={{
													position: "absolute",
													right: "0",
													top: "2rem",
													zIndex: "99999",
													padding: "10px 20px",
													backgroundColor: "#106ab369",
													borderRadius: "10px 0 0 10px",
												}}
												className="expire-container"
											>
												<div className="start-end-date-container">
													<p
														style={{ color: "#fff", margin: 0 }}
														className="start-enddate"
													>
														{course?.course_start && course?.course_end
															? `${DateFormatter(
																course?.course_start
															)} - ${DateFormatter(course?.course?.course_end)}`
															: "12 July 2024 - 15 August 2025"}
													</p>
												</div>
											</div>
											<div
												onClick={(e) => handleCourseData(course)}
												style={{ cursor: "pointer" }}
											>
												<Flex
													gap="middle"
													className="feature-course-subhead-price"
												>
													<div>
														<p className="course-Name" >
															{Category(course?.course?.category_id)
																? Category(course?.course?.category_id)
																: "Web Development"}
														</p>
													</div>
													<div>
														<p
															className="course-fee"

														>
															{course?.fee !== 0 ? `$${course.fee}` : 'Free'}

														</p>
													</div>
												</Flex>
												<Flex className="feature-course-subhead-price">
													<h4>{course?.course?.title}</h4>
												</Flex>
											</div>
											<div>
												<p>
													<p className="description-of-course">
														{course?.course?.about_course}
													</p>
												</p>
											</div>
											<Flex className="feature-course-subhead-price customButtonSection">
												{paymentDetails?.some(
													(item) => item?.course_id == course?.id
												) ? (
													<>
														<button
															type="button"
															class="btn feature-button view-course-button"
															onClick={(e) => handleCourseData(course?.course)}
														>
															View Item
														</button>
													</>
												) : (
													<>
														<button
															type="button"
															class="btn feature-button add-to-wishlist"
															onClick={() => handleWishlistList(course?.course)}
														>
															{wishlistData?.some((wish) => parseInt(wish?.id) === parseInt(course?.course?.id))
																? "Remove Wishlist"
																: "Add to Wishlist"
															}
														</button>
														<button
															type="button"
															class="btn feature-button enroll-button"
														// onClick={() => course?.course?.is_premium === "free" ? handleFreeCourse(course?.course) : handlePurchase(course?.course)}
														>
															Enquiry  Now
														</button>
													</>
												)}
											</Flex>
										</Card>

									</>
								))}
						</Slider>
					</div>

					{/* Map Section */}
					<div className="google-map-container">
						<iframe
							title="map"
							className="google-map"
							src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3560.7039875360224!2d75.86552107522081!3d26.817552476704105!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396dc83bcc4f4189%3A0xfad2a652e92b4fc2!2sAvenue%20-%2016!5e0!3m2!1sen!2sin!4v1716365454452!5m2!1sen!2sin"
							loading="lazy"
							referrerpolicy="no-referrer-when-downgrade"
						></iframe>
						<p className="google-map-location">
							203, St. Mountain View, San Francisco, California, USA
						</p>
					</div>
				</div>
			</div>
		</>
	);
}
