import React, { useEffect, useState } from "react";
import Sidebar from "../../components/Sidebar/sidebar";
import { Pagination, Table, Dropdown, Space, Spin,Row,Col  } from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from "react-redux";
import * as getServices from "../../services/getServices";
import { useNavigate } from "react-router-dom";
import { Avatar } from "../../constant/images";
import { DownOutlined } from '@ant-design/icons';
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";

const Bookings = () => {
    const pageSize = 5;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(1);
    const [bookingData, setBookingData] = useState([]);
    const [sortedData, setSortedData] = useState([]);
    const [sortOrder, setSortOrder] = useState(null);
    const [loader, setLoader] = useState(false);

    const vendorData = useSelector((state) => state.user.userData);
    const totalItems = sortedData.length;
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = Math.min(startIndex + pageSize, totalItems);
    const displayedItems = sortedData.slice(startIndex, endIndex);
    const CurrentData = displayedItems.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));


    useEffect(() => {
        setLoader(true)
        dispatch(getServices.getVendorBookings(
            vendorData?.vendor?.id
        ))
            .then((response) => {
                setLoader(false)
                setBookingData(response.data);
                setSortedData(response.data);
            })
            .catch((err) => {
                setLoader(false)
                // console.log({ err });
            });
    }, [vendorData]);

    const handleSort = (order) => {
        console.log(order, "order")
        setSortOrder(order);
        const sorted = [...bookingData].sort((a, b) => {
            const nameA = a?.user?.name?.toLowerCase();
            const nameB = b?.user?.name?.toLowerCase();
            if (nameA < nameB) return order === 'asc' ? -1 : 1;
            if (nameA > nameB) return order === 'asc' ? 1 : -1;
            return 0;
        });
        setSortedData(sorted);
        setCurrentPage(1);
    };
   
    const columns = [
        {
            title: "S.No",
            dataIndex: "S.No",
            key: "S.No",
            render: (_, record, index) => (currentPage - 1) * pageSize + index + 1,
        },
        {
            title: "Student Name",
            dataIndex: "name",
            key: "name",
            render: (_, record, index) => (
                <div className="row">
                
                    <div className="col-md-5"><img width={36} height={36} className="rounded-circle" src={record?.user?.images || Avatar} /></div>
                    <div className="col-md-7">
                        <p>{record?.user?.name}</p>
                        {/* <p>{record?.user_id}</p> */}
                    </div>
                </div>
            ),
        },
        {
            title: "Student Email",
            dataIndex: "email",
            key: "email",
            render: (_, record, index) => (
                <div>
                    <p>{record?.user?.email}</p>
                </div>
            ),
        },
        {
            title: "Purchase date",
            dataIndex: "created_at",
            key: "created_at",
            render: (_, record, index) => (
                <div>
                    <p>{DateFormatter(record?.created_at)}</p>
                    
                </div>
            ),
        },
        {
            title: "Items Fee",
            dataIndex: "course_fee",
            key: "course_fee",
            render: (_, record, index) => (
                <div>
                    <p>${record?.course_fee}</p>
                </div>
            ),
        },
        {
            title: "Sponsorship Plan",
            dataIndex: "Sponsorship Plan",
            key: "sponsorship_plan",
            render: (_, record, index) => (
                <div>
                    <p>{record?.sponsorship !== null ? "Yes" : "NO"}</p>
                </div>
            ),
        },
        // {
        //     title: "Reward Points",
        //     dataIndex: "Reward Points",
        //     key: "reward_points",
        //     render: (_, record, index) => (
        //         <div>
        //             <p>05</p>
        //         </div>
        //     ),
        // },
    ];

    const DateFormatter = (date) => {
        const inputDate = new Date(date);
        const monthNames = [
            "January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];
        const day = inputDate.getDate();
        const month = monthNames[inputDate.getMonth()];
        const year = inputDate.getFullYear();
        return `${day} ${month} ${year}`;
    };

    const handleCourseNameClick = (courseId) => {
        navigate(`/vendor/view-course-detail`, { state: { data: courseId } });
    };

    const handleChangePage = (page) => {
        setCurrentPage(page);
    };

    const items = [
        {
            key: '1',
            label: "Sort by Name (Ascending)",
            onClick: () => handleSort('asc'),
        },
        {
            key: '2',
            label: "Sort by Name (Descending)",
            onClick: () => handleSort('desc'),
        },
    ];

    const exportToExcel = (data, fileName) => {
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        const excelBuffer = XLSX.write(workbook, {
            bookType: "xlsx",
            type: "array",
        });
        const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
        saveAs(blob, `${fileName}.xlsx`);
    };

    const transformData = (data) => {
        
        return data.map((item, index) => ({

            "S.NO": (index + 1),
            "Student Name": item?.user?.name,
            "Student Email": item?.email,
            "Purchase Date": DateFormatter(item?.created_at),
            "Course": item,
            "Course Fee": item?.course_fee,
            "Sponsorship Plan": item?.sponsorship !== null ? "Yes" : "No",
            "Reward Points": "05",
        }));
    };

    return (
        <>
            <Row>
        <Col xl={4} className="d-xl-block d-md-none">
      <Sidebar />
        </Col>
        <Col xl={20} md={24}>
            <div className="mainContent">

            <div className="mainContent sales-summary">
                <div className="my-courses-container">
                    <div className="courses-head-container">
                        <div className="row w-100">
                            <div className="col-md-6 col-sm-12">
                                <p className="my-course-heading">Sales Summary</p>
                                <p className="total-transaction">Total Transactions: <span className="total-transaction-count">({bookingData.length})</span></p>
                            </div>
                            <div className="col-md-6  col-sm-12 button-short d-flex justify-content-end align-item-center flex-wrap gap-5">
                                <div className="sm-w-100">
                                    <Dropdown
                                        menu={{
                                            items,
                                        }}
                                    >
                                        <a onClick={(e) => e.preventDefault()}>
                                            <Space>
                                                Sort by
                                                <DownOutlined />
                                            </Space>
                                        </a>
                                    </Dropdown>
                                </div>
                                <div className="sm-w-100">
                                    <button className="download-csv-button" onClick={() => exportToExcel(transformData(bookingData), "bookings")}>{loader ? <Spin indicator={<LoadingOutlined spin />} /> :"Download CSV"}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="my-course-table-container">
                        <Table
                            className="my-course-table table-responsive"
                            columns={columns}
                            dataSource={CurrentData}
                            pagination={false}
                        />
                        <Pagination
                            className="mt-4"
                            current={currentPage}
                            pageSize={pageSize}
                            total={totalItems}
                            onChange={handleChangePage}
                        />
                    </div>
                </div>
            </div>
            </div>
            </Col>
        </Row>
        </>
    );
};

export default Bookings;
