import React, { useEffect, useState, useContext } from "react";
import { Row, Col, Button, message } from "antd";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { removeFromCart } from "../../store/actions/user";
import { featureImg2 } from "../../constant/images";
import Swal from "sweetalert2";
import * as getServices from "../../services/getServices";
import * as updateServices from "../../services/updateServices";
import { useAtom } from "jotai";
import { cartCount } from "../../Helpers/atoms/freeCoursesAtom";
import { CartContext } from "../../Helpers/CartContext";
// import Offer from '../Offer/offer'

export default function Cart() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isUserLoggedIn = useSelector((state) => state.user.isUserLoggedIn);
    const isVendorLoggedIn = useSelector((state) => state.user.isVendorLoggedIn);
    const cartItems = useSelector((state) => state.user.cart);
    const totalAmount = useSelector((state) => state.user.total_amount);
    const totalItem = useSelector((state) => state.user.total_item);
    const { addItemToCart } = useContext(CartContext);
    const [cartData, setCartData] = useState([]);
    const [cartCountValue, setCartCountValue] = useAtom(cartCount);

    const handleCheckout = () => {
        navigate("/checkout");
    };

    const fetchCartItems = async () => {
        try {
            const response = await dispatch(getServices.getCartData());
            addItemToCart(response?.data?.length);
            setCartCountValue(response?.data?.length || 0)
            setCartData(response?.data || []);
        } catch (error) {
            console.error("Error fetching cart data", error);
        }
    };

    useEffect(() => {
        if (isUserLoggedIn) {
            fetchCartItems();
        } else {
            addItemToCart(cartItems?.length)
        }
    }, [isUserLoggedIn, isVendorLoggedIn]);

    const handleRemoveFromCart = (id) => {
        Swal.fire({
            title: "Remove Item",
            text: "Are you sure you want to remove?",
            icon: "info",
            showCancelButton: true,
            confirmButtonText: "Yes, remove it!",
            cancelButtonText: "No, keep it",
        }).then((result) => {
            if (result.isConfirmed) {
                if (isVendorLoggedIn || isUserLoggedIn) {
                    dispatch(updateServices.removeCartItem(id)).then((response) => {
                        if (isUserLoggedIn) {
                            fetchCartItems();
                        }
                        message.success(`${response.message}`);
                    }).catch((err) => {
                        message.error(`${err.message}`);
                    })
                } else {
                    dispatch(removeFromCart(id))
                    message.success("Item removed successfully.");
                }
            }
        }
        ).catch((err) => {
            console.error("Error removing item from cart", err);
        });
    };

    const handleCourseData = (values) => {
        navigate(`/course/${values?.course?.id}/${values?.course?.title}`, {
            state: { course_id: values?.course?.id },
        });
    };

    const renderCartItem = (item,index) => {
        const productTitle = item?.course?.title || item?.title;
        const productDescription = item?.course?.about_course || item?.about_course;
        const productPrice = item?.course?.fee || item?.fee;
        const productImage = item?.image || item?.course?.image || featureImg2;
        const productCategory = item?.course?.parent_category_name !== null ? item?.course?.parent_category_name : ''

        return (
            <Col className="shop-cart-items-content px-0" xl={24} md={24} key={item?.id || index}>
                <Row>
                    <Col className="cart-items-col px-2" md={16} sm={24} onClick={() => handleCourseData(item)} style={{ cursor: "pointer" }}>
                        <div className="product-detail single-pro-items d-flex">
                            <div className="item-image-thumb">
                                <img src={productImage} alt="Course Image" />
                            </div>
                            <div className="item-content-tab">
                                <h6>{productCategory}</h6>
                                <h4>{productTitle}</h4>
                                <p>{productDescription}</p>
                            </div>
                        </div>
                    </Col>
                    <Col className="cart-items-col px-2" md={4} sm={24}>
                        <div className="product-price">${productPrice}</div>
                    </Col>
                    <Col className="cart-items-col" md={4} sm={24}>
                        <div className="remove-item">
                            <RiDeleteBin6Line style={{ cursor: "pointer" }} onClick={() => handleRemoveFromCart(item?.id)} />
                        </div>
                    </Col>
                </Row>
            </Col>
        );
    };

    const totalFee = cartData?.reduce((acc, curr) => acc + parseFloat(curr?.course?.fee || 0), 0);

    return (
        <>
            <div className="cart-page-section">
                <div className="container">
                    <Row className="cart-page-row">
                        <Col className="cart-items-col px-4" xl={16} md={24}>
                            <Row>
                                <Col className="shopping-cart-header d-flex flex-wrap justify-content-between" xl={24} md={24}>
                                    <h3 className="section-title">Shopping Cart</h3>
                                    <div className="header-item">
                                        <span className="header-item-count">{isUserLoggedIn || isVendorLoggedIn ? cartData.length : totalItem}</span> Items
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="shop-cart-items-head px-0" xl={24} md={24}>
                                    {((isUserLoggedIn || isVendorLoggedIn) && cartData.length === 0) ? (
                                        <div className="empty-cart-text">
                                            Your shopping cart is empty. Add some items to proceed.
                                        </div>
                                    ) : (!isUserLoggedIn && !isVendorLoggedIn && cartItems.length === 0) ? (
                                        <div className="empty-cart-text">
                                            Your shopping cart is empty. Add some items to proceed.
                                        </div>
                                    ) : (
                                        <Row>
                                            <Col className="cart-items-col px-2" md={16} sm={24}>
                                                <div className="product-detail title-table-cart">Items Details</div>
                                            </Col>
                                            <Col className="cart-items-col px-2" md={4} sm={24}>
                                                <div className="product-price title-table-cart">Price</div>
                                            </Col>
                                            <Col className="cart-items-col" md={4} sm={24}>
                                                <div className="remove-item title-table-cart"></div>
                                            </Col>
                                        </Row>
                                    )}
                                </Col>
                            </Row>
                            <Row className="product-items-row">
                                {isUserLoggedIn || isVendorLoggedIn ? cartData.map(renderCartItem) : cartItems.map(renderCartItem)}
                            </Row>
                        </Col>

                        <Col className="cart-checkout-col px-4" xl={8} md={24}>
                            <div className="order-summary">
                                <div className="summary-head">
                                    <h3 className="section-title">Order summary</h3>
                                    <div className="order-summary-total d-flex flex-wrap justify-content-between">
                                        <h5 className="summary-items">
                                            Items <span className="summary-items-total">{(isUserLoggedIn || isVendorLoggedIn) ? cartData.length : totalItem}</span>
                                        </h5>
                                        <h5 className="summary-items-price">${(isUserLoggedIn || isVendorLoggedIn) ? totalFee : totalAmount}</h5>
                                    </div>
                                </div>

                                {(isUserLoggedIn || isVendorLoggedIn) && cartData.length > 0 ? (
                                    <div className="checkout-button d-flex justify-content-center">
                                        <Button onClick={handleCheckout} type="button">
                                            Checkout
                                        </Button>
                                    </div>
                                ) : (
                                    (!isUserLoggedIn && !isVendorLoggedIn && cartItems.length > 0) ? (
                                        <div className="checkout-button d-flex justify-content-center">
                                            <Button onClick={handleCheckout} type="button">
                                                Checkout
                                            </Button>
                                        </div>
                                    ) : (
                                        <></>
                                    )
                                )}


                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    );
}
