import React, { useState, useEffect } from "react";
import {
    Row,
    Col,
    Button,
    Rate,
    Tabs,
    Image,
    Form,
    Input,
    Modal,
    Collapse,
    Table,
    Spin,
} from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import { IoIosArrowRoundBack } from "react-icons/io";
import {
    CourseBannerImage,
    UserProfileDefault,
} from "../../constant/images";
import { Avatar } from "../../constant/images";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Payment from "../paymentPrcess/index";
import * as getServices from "../../services/getServices";
import RecentCourse from "../HomePage/RecentCourse";

import * as uploadService from "../../services/uploadServices";
import Swal from "sweetalert2";
import { add_To_Cart } from "../../store/actions/user";
import { useAtom } from "jotai";
import { cartCount } from "../../Helpers/atoms/freeCoursesAtom";
import { DEMO_IMAGE_URL } from "../../Helper";


export default function CourseDetails() {
    const { Panel } = Collapse;
    const navigate = useNavigate();
    const userData = useSelector((state) => state.user.userData);
    const isUserLoggedIn = useSelector((state) => state.user.isUserLoggedIn);
    const isVendorLoggedIn = useSelector((state) => state.user.isVendorLoggedIn);
    const cartItem = useSelector((state) => state.user.cart);
    // const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedCourse, setSelectedCourse] = useState();
    const [paymentCourseData, setPaymentCourseData] = useState();
    // const [particularCourseData, setParticularCourseData] = useState();
    const [cartData, setCartData] = useState([]);
    const [cartCountValue, setCartCountValue] = useAtom(cartCount)
    const dispatch = useDispatch();
    const [courseData, setCourseData] = useState(null)
    const [loader, setLoader] = useState(false)

    let { state } = useLocation();
    const { TextArea } = Input;
    const [rating, setRating] = useState(0);
    const [reviews, setReviews] = useState([])

    const onFinish = (values) => {
        let formData;
        formData = {
            name: values?.fullName,
            email: values?.email,
            comment: values?.comment,
            rating: rating.toString(),
            status: "pending",
            course_id: courseData?.id,
            vendor_id: courseData?.vendor_id,
        };

        dispatch(uploadService?.postReview(formData))
            .then((response) => {
                Swal.fire(
                    "Review Uploaded!",
                    "Your review has been uploaded successfully.",
                    "success"
                ).then((result) => { });
            })
            .catch((err) => {
                Swal.fire("Failed", "Falied to upload review", "error").then(
                    (result) => { }
                );
                console.error({ err });
            });
    };

    const onFinishFailed = () => { };

    useEffect(() => {
        window.scrollTo(0, 0)
        if (isUserLoggedIn) {
            dispatch(
                getServices?.getPaymentDetails(
                    userData?.user?.id)
            )
                .then((response) => {
                    setPaymentCourseData(response?.payments);
                })
                .catch((err) => {
                    console.error({ err });
                });
        }


        dispatch(getServices?.getParticularCourseReviews(state?.course_id))
            .then((response) => {
                setReviews(response)
            })
            .catch((err) => {
                console.error({ err });
            });
        if (isUserLoggedIn) {
            fetchCartItems()
        }
    }, []);

    useEffect(() => {
        setLoader(true)
        dispatch(
            getServices?.getCourseDetails(
                state?.course_id)
        )
            .then((response) => {
                setLoader(false)
                setCourseData(response?.data);
            })
            .catch((err) => {
                setLoader(false)
                console.error({ err });
            });
    }, [state?.course_id])

    let doesUserPurchasedCourse =
        paymentCourseData?.some(
            (payment) =>
                parseInt(payment?.course_id) === parseInt(courseData?.id)
        ) || false;


    let sumOfRatings = 0;
    if (reviews.length > 0) {
        reviews.forEach(item => {
            sumOfRatings += parseInt(item.rating, 10);
        });
    }


    const averageRating = reviews.length > 0 ? sumOfRatings / reviews.length : 0;

console.log(courseData , "data")

    const multipleTabsArray = [
        {
            key: "1",
            label: "About",
            children: (
                <div className="tabs-description-container">
                    <p className="description">  {courseData?.about_course && courseData?.about_course !== "undefined" ? courseData?.about_course : " "}</p>
                </div>
            ),
        },
        {
            key: "2",
            label: "Instructor",
            children: (
                courseData?.instructor && courseData?.instructor?.map((details) => (
                    <div className="tabs-description-container instructor-container row">
                        <div className="col-md-3">
                            <Image style={{ width: "200px" }} preview={false} src={details?.profile_photo ? `${DEMO_IMAGE_URL}/${details?.profile_photo}` : Avatar} />
                        </div>
                        <div className="col-md-9">
                            <p className="description">
                                <span style={{color:"#000", fontWeight:"500"}}>Name</span> - {details?.instructor_name ? details?.instructor_name : " "}
                            </p>
                            {details?.about ? (
                                <p className="description"><span style={{color:"#000", fontWeight:"500"}}>About</span> - {details?.about}</p>
                            ) : ("")}
                            {details?.work_experience ? (
                                <p className="description">
                                <span style={{color:"#000", fontWeight:"500"}}>Experience</span> - {details?.work_experience} years
                                </p>
                            ) : ("")}
                        </div>
                    </div>
                ))

            ),
        },
        {
            key: "3",
            label: "Refund Policy",
            children: (
                <div className="tabs-description-container">
                    <p dangerouslySetInnerHTML={{ __html: courseData?.refund_policy }} className="description">
                    </p>
                </div>
            ),
        },
        {
            key: "4",
            label: "FAQ",
            children: (
                <div className="tabs-description-container course-faq-container">
                    <Collapse defaultActiveKey={["0"]} className="custom-collapse">
                        {(() => {
                            let faqData = courseData?.vendor_faq;
                            if (typeof faqData === "string") {
                                faqData = JSON.parse(faqData);
                            }

                            // Now map through faqData
                            return faqData?.map((item, index) => (
                                <Panel
                                    header={
                                        <div className="panel-header">
                                            <span className="panel-label">{item.question}</span>
                                        </div>
                                    }
                                    key={index} // using index as a key if item.key is not present
                                >
                                    <p className="panel-description">{item.answer}</p>
                                </Panel>
                            ));
                        })()}
                    </Collapse>

                </div>
            ),
        },
        // {
        //     key: "5",
        //     label: "Vendor",
        //     children: (
        //         <div className="vendor-details-course-container">
        //             <div className="vendor-details-course-image">
        //                 <img className="vendor-logo" src={state?.vendorData?.user?.images ? state?.vendorData?.user?.images : courseData?.vendor?.user?.images ? courseData?.vendor?.user?.images : Avatar} />
        //             </div>
        //             <div className="vendor-details-container">
        //                 <p>Name: &nbsp;<span style={{ color: "#106AB3" }}>{courseData?.vendor?.user?.name ? courseData?.vendor?.user?.name : state?.vendorData?.user?.name}</span></p>
        //                 <p>Email: &nbsp;<span style={{ color: "#106AB3" }}>{courseData?.vendor?.user?.email ? courseData?.vendor?.user?.email : state?.vendorData?.user?.email}</span></p>
        //                 <p>Phone: &nbsp;<span style={{ color: "#106AB3" }}>{courseData?.vendor?.user?.phone ? courseData?.vendor?.user?.phone : state?.vendorData?.user?.phone}</span></p>
        //                 {/* <p>School: &nbsp;<span style={{color:"#106AB3"}}>{courseData?.vendor?.school}</span></p>  */}
        //                 <p>Address: &nbsp;<span style={{ color: "#106AB3" }}>{courseData?.vendor?.address ? courseData?.vendor?.address : "Test Address"}</span></p>
        //             </div>
        //         </div>
        //     ),
        // },
        {
            key: "6",
            label: "Organisation Details",
            children: (
                <div className="vendor-details-course-container">
                    <div className="vendor-details-course-image">
                        <img className="vendor-logo" src={courseData?.vendor?.logo ? `${DEMO_IMAGE_URL}/${courseData?.vendor?.logo}` : Avatar} />
                    </div>
                    <div className="vendor-details-container">
                        {courseData?.vendor?.school ? (
                            <p>Name: &nbsp;<span style={{ color: "#106AB3" }}>{courseData?.vendor?.school}</span></p>
                        ) : (<></>)}
                        {courseData?.vendor?.website_url ? (
                            <p>Company Url: &nbsp;<span style={{ color: "#106AB3" }}>{courseData?.vendor?.website_url}</span></p>
                        ) : (<></>)}
                        {courseData?.vendor?.address ? (
                            <p>Address: &nbsp;<span style={{ color: "#106AB3" }}>{courseData?.vendor?.address}</span></p>
                        ) : (<></>)}
                    </div>
                </div>
            ),
        },
        {
            key: "7",
            label: "Course Details",
            children: (
                <div className="course-details-tab">
                    <p className="course-name">Name: <span>{courseData?.title}</span></p>
                    {courseData?.about_course ? (<p className="course-description">Description: <span>{courseData?.about_course}</span></p>) : ("")}
                    {courseData?.fee ? (<p className="course-fees">Fee: <span>{courseData?.fee}</span></p>) : ("")}
                    {courseData?.approach !==0 ? (<p className="course-fees">Type: <span>{courseData?.approach ===1 ? "Online" : courseData?.approach===2? "Physical":"Combined"}</span></p>) : ("")}
                    {courseData?.vendor?.school ? (<p className="course-created-by">Created By: <span>{courseData?.vendor?.school}</span></p>) : ("")}
                    {courseData?.parent_category_name ? (<p className="course-category">Category: <span>{courseData?.parent_category_name}</span></p>) : ("")}
                    <p className="trail-available">Trail Available: <span>{courseData?.trial === 1 ? "Yes" : "No"}</span></p>
                    {courseData?.refund_policy ? (
                        <p className="refund-policy">Refund Policy: <span>{courseData?.refund_policy}</span></p>
                    ) : ("")}
                    <p className="ratings">Rating: <Rate disabled={true} className="rating-star" key={Math.random()} defaultValue={!isNaN(averageRating) && averageRating !== 0 ? averageRating : 0} /></p> 
                    {courseData?.vendor?.logo === null && courseData?.vendor?.school === null ? (
                        <></>
                    ) : (
                        <>
                            <p className="vendor-details">Organisation Details</p>
                            <div className="vendor-details-course-container">
                                <div className="vendor-details-course-image">
                                    <img className="vendor-logo" src={`${DEMO_IMAGE_URL}/${courseData?.vendor?.logo}` || Avatar} />
                                </div>
                                <div className="vendor-details-container">
                                    {courseData?.vendor?.school ? (
                                        <p>Name: &nbsp;<span style={{ color: "#106AB3" }}>{courseData?.vendor?.school}</span></p>
                                    ) : (<></>)}
                                    {courseData?.vendor?.website_url ? (
                                        <p>Company Url: &nbsp;<span style={{ color: "#106AB3" }}>{courseData?.vendor?.website_url}</span></p>
                                    ) : (<></>)}
                                    {courseData?.vendor?.address ? (
                                        <p>Address: &nbsp;<span style={{ color: "#106AB3" }}>{courseData?.vendor?.address}</span></p>
                                    ) : (<></>)}

                                </div>
                            </div>
                        </>
                    )}


                </div>
            ),
        },
    ];

    const filteredTabsArray = multipleTabsArray.filter(tab => {
        if (tab.key === "3" && !courseData?.refund_policy) {
            return false;
        }
        if (tab.key === "4" && (courseData?.vendor_faq === null ||
            (typeof courseData?.vendor_faq === 'string'
                ? JSON.parse(courseData?.vendor_faq)?.length === 0
                : courseData?.vendor_faq.length === 0))) {
            return false;
        }
        if (tab.key === "2" && (courseData?.instructor?.length === 0)) {
            return false;
        }

        if (tab.key === "6" && (courseData?.vendor?.logo === null && courseData?.vendor?.school === null)) {
            return false;
        }
        return true;
    });

    const fileUrl = `${DEMO_IMAGE_URL}/${courseData?.document}`;

    const slotsColumn = [
        // {
        //     title: "S.No.",
        //     dataIndex: "S.No.",
        //     key: "S.No.",
        //     render: (text, record, index) => (
        //         <p className="sno-text">
        //             {String(index + 1).padStart(2, "0")}
        //         </p>
        //     ),

        // },
        {
            title: "Week Day",
            dataIndex: "week_day",
            key: "week_day",
            render: (text, record) => (
                <p className="week-day">
                    {record?.week_day}
                </p>
            ),
        },
        {
            title: "Start Time",
            dataIndex: "start_time",
            key: "start_time",
            render: (text, record) => (
                <p className="start-time">
                    {record?.start_time}
                </p>
            ),
        },
        {
            title: "End Time",
            dataIndex: "end_time",
            key: "end_time",
            render: (text, record) => (
                <p className="end-time">
                    {record?.end_time}
                </p>
            ),
        },

    ];


    const handleDownload = () => {
        const link = document.createElement("a");
        link.href = fileUrl;
        link.download = "z6nEYKX3Ap8WGULMvsUsZvcmFK4Sx0PWleK7PNRS.xlsx";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    // const showModal = () => {
    //     setIsModalOpen(true);
    // };
    // const handleOk = () => {
    //     setIsModalOpen(false);
    // };
    // const handleCancel = () => {
    //     setIsModalOpen(false);
    // };

    const handleCourseChange = (data) => {
        setSelectedCourse(data);
    };

    const DateFormatter = (date) => {
        const inputDate = date;

        const dateObj = new Date(inputDate);

        const monthNames = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
        ];

        const day = dateObj.getDate();
        const month = monthNames[dateObj.getMonth()];
        const year = dateObj.getFullYear();

        const formatDate = `${day} ${month} ${year}`;
        return formatDate;
    };

    const columns = [
        {
            title: 'Day',
            dataIndex: 'day',
            render: (text, record) => (
                <>
                    <div className='day-container'>
                        <p className='day-text'>{record?.week_day}</p>
                    </div>
                </>
            ),
        },
        {
            title: 'Start Time',
            dataIndex: 'start_time',
            render: (text, record) => (
                <>
                    <div className='start-time-container'>
                        <p className='starttime--text'>{record?.start_time}</p>
                    </div>
                </>
            ),
        },
        {
            title: 'End Time',
            dataIndex: 'end_time',
            render: (text, record) => (
                <>
                    <div className='start-time-container'>
                        <p className='starttime--text'>{record?.end_time}</p>
                    </div>
                </>
            ),
        },
    ];

    const fetchCartItems = async () => {
        try {
            const response = await dispatch(getServices.getCartData());

            setCartCountValue(response?.data?.length || 0)
            setCartData(response?.data || []);
        } catch (error) {
            console.error("Error fetching cart data", error);
        }
    };

    const handleAddToCart = (data) => {

        if (!isUserLoggedIn && !isVendorLoggedIn) {
            dispatch(add_To_Cart(data))
        } else {
            const addToCart = (data) => {
                const formData = new FormData()
                formData.append("course_id", data?.id)
                formData.append("course_price", data?.fee)
                dispatch(uploadService.addToCart(formData)).then((response) => {
                    if (isUserLoggedIn) {
                        fetchCartItems()
                    }
                    Swal.fire(
                        "Success",
                        `${response.message}`,
                        "success"
                    )
                }).catch((error) => {
                    Swal.fire("Failed", error.message);
                })
            }
            addToCart(data)
        }
    }

    const handleBack = () => {
        navigate("/");
    }

    return (
        <>
            {loader ? (
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "100vh" }}>
                    <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
                </div>
            ) : (
                <>
                    <div className="course-details-container">
                        <div className="course-details-banner-container" style={{ backgroundImage: courseData?.image ? `url(${courseData?.image})` : `url(${CourseBannerImage})` }}>
                            <div className="container-fluid"><Button className="back-to-button" onClick={handleBack}>  <IoIosArrowRoundBack /> Back to home</Button></div>
                            <div className="container">
                                <Row className="course-details-banner-row">
                                    <Col className="course-details-banner-col-one" md={12}>
                                        {courseData?.trial !== 0 ? (<p className="trail-available-text">Trail available</p>) : ("")}
                                        {courseData?.approach !==0 ? (<Button className="course-banner-button">{courseData?.approach ===1 ? "Online" : courseData?.approach===2?"Physical":"Combined"}</Button>):""}
                                        <p className="course-details-heading">
                                            {selectedCourse !== undefined
                                                ? selectedCourse.title
                                                : courseData?.title}
                                        </p>
                                        <div className="categories-container">
                                            {courseData?.parent_category_name ? (<p className="parent-category">{courseData?.parent_category_name}</p>) : ("")}
                                            {courseData?.sub_category_name ? (<p className="sub-category">{courseData?.sub_category_name}</p>) : ("")}
                                            {courseData?.child_category_name ? (<p className="child-category">{courseData?.child_category_name}</p>) : ("")}
                                        </div>
                                        <Row className="students-level-lesson-row">
                                            <Col className="students-with-icon" md={10}>
                                                {/* <RiGraduationCapFill className="graguation-cap-icon" />
                                        <p className="no-of-students">10 Client Batch</p> */}
                                            </Col>
                                            <Col className="levels-with-icon" md={10}>
                                                {/* <BsFillBarChartFill className="bar-chart-icon" />
                                        <p className="all-levels">All levels</p> */}
                                            </Col>
                                        </Row>
                                        <Row className="banner-rating-container">
                                            <Col className="banner-rating-col-one" md={2}>
                                                <p className="rating-text">{averageRating % 1 !== 0 ? averageRating.toFixed(1) : averageRating + ".0"}</p>
                                            </Col>
                                            <Col className="banner-rating-col-two" md={22}>
                                                <Rate disabled={true} className="rating-star" key={Math.random()} defaultValue={!isNaN(averageRating) && averageRating !== 0 ? averageRating : 0} />
                                            </Col>
                                        </Row>
                                        <Row>
                                            {courseData?.vendor?.school ? (
                                                <Col md={24}>
                                                    <p style={{ color: "#fff", fontFamily: "Poppins" }}>Created by <span style={{ fontFamily: "Poppins", fontWeight: "600" }}>{courseData?.vendor?.school}</span></p>
                                                </Col>
                                            ) : ("")}

                                        </Row>
                                    </Col>
                                    <Col className="course-details-banner-col-two" md={12}>
                                        <Row className="course-img-video-with-enrol-row">
                                            {/* <Col className="course-img-video-with-enrol-col-one" md={5}>
                                        <Row className="image-video-row">
                                            <Col className="image-video-col" md={24}></Col>
                                        </Row>
                                    </Col> */}
                                            <Col className="course-img-video-with-enrol-col-two" md={19}>
                                                <div className="enroll-container">
                                                    <div className="enroll-banner-image-div">
                                                        <div className="image-div"></div>
                                                    </div>
                                                    <div className="price-with-enroll-outer-div">
                                                        <Row className="price-with-enroll-row">

                                                            {paymentCourseData?.some(
                                                                (item) => item?.course_id == courseData?.id
                                                            ) ? (
                                                                <Col className="price-with-enroll-col-one justify-content-center" md={24}>
                                                                    <p className="latest-price">

                                                                        {courseData?.fee !== 0 && courseData?.fee !== null ? `$${courseData?.fee}` : 'Free'}
                                                                    </p>
                                                                </Col>
                                                            ) : (
                                                                <>
                                                                    <Col className="price-with-enroll-col-one" md={12}>
                                                                        <p className="latest-price">
                                                                            {courseData?.fee !== 0 && courseData?.fee !== null ? `$${courseData?.fee}` : 'Free'}
                                                                        </p>
                                                                    </Col>
                                                                    <Col className="price-with-enroll-col-two" md={12}>
                                                                        {isUserLoggedIn ? (
                                                                            <>
                                                                                {cartData?.length > 0 && cartData?.some(cartItem => cartItem?.course?.id === courseData?.id) ? (
                                                                                    <>

                                                                                        <Button
                                                                                            onClick={(e) => navigate("/cart")}
                                                                                            className="enroll-button"
                                                                                        >
                                                                                            Go to cart
                                                                                        </Button>
                                                                                    </>
                                                                                ) : (
                                                                                    <Button
                                                                                        onClick={(e) => handleAddToCart(courseData)}
                                                                                        className="enroll-button"
                                                                                    >

                                                                                        Add to cart
                                                                                    </Button>
                                                                                )}
                                                                            </>
                                                                        ) : (<>
                                                                            {isVendorLoggedIn ? (
                                                                                <p></p>
                                                                            ) : (
                                                                                cartItem && cartItem.some(cartItem => cartItem?.id === courseData?.id) ? (
                                                                                    <>

                                                                                        <Button
                                                                                            onClick={(e) => navigate("/cart")}
                                                                                            className="enroll-button"
                                                                                        >
                                                                                            Go to cart
                                                                                        </Button>
                                                                                    </>
                                                                                ) : (
                                                                                    <Button
                                                                                        onClick={(e) => handleAddToCart(courseData)}
                                                                                        className="enroll-button"
                                                                                    >

                                                                                        Add to cart
                                                                                    </Button>
                                                                                )
                                                                            )}
                                                                        </>)}


                                                                    </Col>
                                                                </>
                                                            )}

                                                        </Row>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                        <div className="multiple-tabs-container container">
                            <Tabs
                                className="multiple-tabs"
                                defaultActiveKey="1"
                                items={filteredTabsArray}
                            />
                        </div>
                        <div className="downalod-document-container">
                            <Button className="download-button" onClick={handleDownload}>
                                Download Documents
                            </Button>
                        </div>

                        <div className="course_slot_wrapper container">
                            {courseData?.time_slots?.length > 0 ? (
                                <>
                                    <h1 className="course_slot_title border-bottom-heading bottom-border-left mb-4">Slot Available</h1>
                                    <Table
                                        columns={slotsColumn}
                                        dataSource={courseData?.time_slots}
                                        pagination={false}
                                        bordered
                                    />
                                </>
                            ) : (
                                <></>
                            )
                            }

                        </div>
                        {reviews && reviews?.filter(review => review?.status === "approved")?.length > 0 ? (
                            <div className="comments-container container">
                                <div className="comments-head-container">
                                    <p className="comments-heading border-bottom-heading">Comments</p>
                                    <p className="no-of-comments">{reviews.length} Comments</p>
                                </div>
                                <Row className="comments-row">
                                    {reviews?.filter(review => review?.status === "approved")?.map((comment) => {
                                        return (
                                            <Col className="comments-col" md={24}>
                                                <Row className="comments-col-row">
                                                    <Col className="avatar-col" md={1}>
                                                        <Image preview={false} className="avatar-image" src={comment?.user?.images ? comment?.user?.images : UserProfileDefault} />
                                                    </Col>
                                                    <Col className="comment-content-col" md={23}>
                                                        <div className="comment-name-date">
                                                            <p className="comment-name">{comment?.user?.name}</p>
                                                            <p className="comment-date">{DateFormatter(comment?.created_at)}</p>
                                                        </div>
                                                        <Rate
                                                            disabled={true}
                                                            className="comment-rating"
                                                            defaultValue={parseInt(comment?.rating)}
                                                        />
                                                        <p className="comment-description">{comment?.comment}</p>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        );
                                    })}
                                </Row>
                            </div>
                        ) : ("")}
                        <div className="comments-container container"></div>
                        {doesUserPurchasedCourse ? (
                            <div className="leave-comment-form-container container">
                                <Row className="leave-comment-form-row">
                                    <Col className="leave-comment-form-col-one" md={15}>
                                        <p className="leave-comment-heading border-bottom-heading">
                                            Leave a comment
                                        </p>
                                        <p className="leave-comment-not-publish">
                                            Your email address will not be published. Required fields are
                                            marked *
                                        </p>
                                        <Form
                                            name="basic"
                                            labelCol={{
                                                span: 8,
                                            }}
                                            wrapperCol={{
                                                span: 24,
                                            }}
                                            initialValues={{
                                                remember: true,
                                            }}
                                            onFinish={onFinish}
                                            onFinishFailed={onFinishFailed}
                                            autoComplete="off"
                                            className="leave-comment-form"
                                        >
                                            <Row className="leave-comment-form-row">
                                                <Col md={10} className="leave-comment-form-col pe-2">
                                                    <Form.Item
                                                        className="form-input-class"
                                                        name="fullName"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: "Please enter full name.",
                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                            className="leave-comment-input"
                                                            placeholder="Name*"
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col md={14} className="leave-comment-form-col ps-2">
                                                    <Form.Item
                                                        className="form-input-class"
                                                        name="email"
                                                        rules={[
                                                            {
                                                                type: "email",
                                                                required: true,
                                                                message: "Email is not valid",
                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                            className="leave-comment-input"
                                                            placeholder="Email*"
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Row>

                                            <Row className="leave-comment-form-row">
                                                <Col md={24} className="leave-comment-form-col">
                                                    <Form.Item
                                                        className="form-input-class"
                                                        name="comment"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: "Please enter full name.",
                                                            },
                                                        ]}
                                                    >
                                                        <TextArea
                                                            className="leave-comment-text-area"
                                                            rows={4}
                                                            placeholder="Add Comment here..."
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Row className="leave-comment-form-row">
                                                <Col md={24} className="leave-comment-form-col">
                                                    <Form.Item
                                                        className="form-input-class mb-0"
                                                        wrapperCol={{
                                                            offset: 8,
                                                            span: 24,
                                                        }}
                                                    >
                                                        <Button
                                                            className="leave-comment-button"
                                                            htmlType="submit"
                                                        >
                                                            Post Comment
                                                        </Button>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Col>
                                    <Col className="leave-comment-form-col-two" md={9}>
                                        <p className="review-rating-heading">Review & Ratings</p>
                                        <div className="ratings-rate">
                                            <div className="rate-with-text-div">
                                                <Rate
                                                    className="review-rating-star"
                                                    onChange={(e) => setRating(e)}
                                                    defaultValue={rating}
                                                />
                                                <p className="review-rating-text">{rating}</p>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        ) : (
                            ""
                        )}

                            <RecentCourse handleCourseChange={handleCourseChange} />
                    
                    </div>
                </>
            )}
            {/* <Modal
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                className="paymentModal"
                centered
                width={1140}
                footer={false}
            >
                <Payment paymentCourseData={particularCourseData} />
            </Modal> */}
        </>
    );
}
