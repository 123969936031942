


function SalesSummaryChart(data, type) {


  const preprocessData = (data, type) => {
    let xAxisCategories = [];
    let seriesData = [];
    let courseTitles = [];

    if (type === 1) {
      xAxisCategories = data.map(item => item.date);
      seriesData = data.map(item => item.totalAmount);
      courseTitles = data.map(item => item.courses.map((course)=> course.title));
    } else if (type === 0) {
      xAxisCategories = data.map(item => item.month);
      seriesData = data.map(item => item.totalAmount);
      courseTitles = data.map(item => item.courses.map((course)=> course.title));
    }else if ( type === 2) {
      xAxisCategories = data.map(item => item.year);
      seriesData = data.map(item => item.totalAmount);
      courseTitles = data.map(item => item.courses.map((course)=> course.title));
    }

    return { xAxisCategories, seriesData, courseTitles };
  };
  
  const { xAxisCategories, seriesData, courseTitles } = preprocessData(data, type);

 
  const options = {
    chart: {
      type: "column",
    },
    title: {
      text: "", 
    },
    
    exporting: {
      enabled: true
    },
    plotOptions: {
      series: {
        pointWidth: 50,
      },
      column: {
        colorByPoint: true,
      },
    },
   
    xAxis: {
      categories: xAxisCategories,
      title: {
        text: type === 0 ? 'Booking Month' : (type === 1 ? 'Booking Date' : 'Booking Year')
      },
    },
    yAxis: {
      title: {
        text: "Total Items Fee Amount",
      },
      labels: {
        formatter: function () {
          return '$' + this.value;
        },
      },
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      headerFormat: '<span style="font-size:11px">{point.key}</span><br>',
      formatter: function () {
        let tooltipString = `<b>${this.y.toFixed(2)}</b>$<br/>`;
      
        const courses = courseTitles;
        if (courses && courses.length > 0) {
          const courseTitle = courses.filter((item) => item?item.join(','):'');       
          tooltipString += `Courses: ${courseTitle}`;     
          
        }
        return tooltipString;
      }
    },
    series: [
      {
        name: "Total Items Fee",
        data: seriesData,
      },
    ],
  };
  options.series[0].data = options.series[0].data.map((value) => (value === 0 ? null : value));
  return options;
}

export { SalesSummaryChart };
