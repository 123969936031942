import React from "react";
import Sidebar from "../../components/Sidebar/sidebar";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Form, Input, Button } from "antd";
import Swal from "sweetalert2";
import { CiLock } from "react-icons/ci";
import { IoEyeOutline } from "react-icons/io5";
import * as updateServices from "../../services/updateServices";

export default function ChangePassword() {
	const vendorData = useSelector((state) => state.user.userData);
	const dispatch = useDispatch();

	const onFinishPasswordChange = (values) => {
		let formData;
		formData = {
			email: vendorData?.user?.email,
			password: values?.password,
			new_password: values?.new_password,
		};

		dispatch(updateServices.changePassword(formData))
			.then((response) => {
				Swal.fire({
					title: "Success",
					text: response?.message,
					icon: "success",
					confirmButtonText: "Okay",
				}).then(() => {
					window.location.reload();
				});
			})
			.catch((err) => {
				console.error({ changePasswordError: err?.response?.data?.message });
				Swal.fire({
					title: "Failed",
					text: err?.response?.data?.message,
					icon: "error",
					confirmButtonText: "Okay",
				});
			});
	};

	const onFinishFailed = () => { };
	return (
		<>
			<Sidebar />
			<div className="mainContent">
				<p className="change-password-heading">Change Password</p>
				<div className="change-password-container">
					<Row className="change-password-row">
						<Col md={24} className="change-password-col">
							<Form
								name="basic"
								labelCol={{
									span: 8,
								}}
								wrapperCol={{
									span: 24,
								}}
								onFinish={onFinishPasswordChange}
								onFinishFailed={onFinishFailed}
								autoComplete="off"
								className="user-profile-form"
							>
								<Row>
									<Col md={24} className="custom-padding">
										<p
											style={{
												textAlign: "start",
												fontFamily: "POPPINS",
											}}
										>
											Current Password
										</p>

										<Form.Item
											className="form-input-class"
											name="password"
											rules={[
												{
													required: true,
													message: "Please input your password!",
												},
											]}
										>
											<Input.Password
												prefix={<CiLock />}
												placeholder="**********"
												suffix={<IoEyeOutline />}
												type="password"
												className="input-box-login"
											/>
										</Form.Item>
									</Col>
									<Col md={24} className="custom-padding">
										<p
											style={{
												textAlign: "start",
												fontFamily: "POPPINS",
											}}
										>
											New Password
										</p>

										<Form.Item
											className="form-input-class"
											name="new_password"
											rules={[
												{
													required: true,
													message: "Please input your password!",
												},
												{
													min: 6,
													message:
														"Password must be at least 6 characters long!",
												},
												({ getFieldValue }) => ({
													validator(_, value) {
														if (
															!value ||
															/[!@#$%^&*(),.?":{}|<>]/.test(value)
														) {
															return Promise.resolve();
														}
														return Promise.reject(
															new Error(
																"Password must contain at least one special character!"
															)
														);
													},
												}),
											]}
										>
											<Input.Password
												prefix={<CiLock />}
												placeholder="**********"
												suffix={<IoEyeOutline />}
												type="password"
												className="input-box-login"
											/>
										</Form.Item>
									</Col>
									<Col md={24} className="custom-padding">
										<p
											style={{
												textAlign: "start",
												fontFamily: "POPPINS",
											}}
										>
											Confirm Password
										</p>

										<Form.Item
											className="form-input-class"
											name="confirmPassword"
											rules={[
												{
													required: true,
													message: "Please confirm your password!",
												},

												({ getFieldValue }) => ({
													validator(_, value) {
														if (
															!value ||
															getFieldValue("new_password") === value
														) {
															return Promise.resolve();
														}
														return Promise.reject(
															new Error(
																"The new password that you entered do not match!"
															)
														);
													},
												}),
											]}
										>
											<Input.Password
												prefix={<CiLock />}
												placeholder="**********"
												suffix={<IoEyeOutline />}
												className="input-box-login"
												type="password"
											/>
										</Form.Item>
									</Col>
								</Row>
								<Row className="update-password-row">
									<Col md={24} className="update-password-col">
										<Form.Item
											className="mb-0"
											wrapperCol={{
												offset: 8,
												span: 24,
											}}
										>
											<Button className="update-button" htmlType="submit">
												Update Password
											</Button>
										</Form.Item>
									</Col>
								</Row>
							</Form>
						</Col>
					</Row>
				</div>
			</div>
		</>
	);
}
