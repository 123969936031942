import React, { useState } from "react";
import {
    Row,
    Col,
    Button
}
    from "antd";
// import AddressForm from "../../pages/paymentPrcess/addressForm";
import CheckoutCourses from "../../pages/paymentPrcess/checkoutCourses";
import CourseEnquiry from "../courseEnquiry"
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom"

export default function Checkout() {
    const navigate = useNavigate();
    const isUserLoggedIn = useSelector((state) => state.user.isUserLoggedIn);
    const [courseDetails, setCourseDetails] = useState([])

    return (
        <>
            <div className="checkout-page-section">
                <div className="container">
                    <Row className="checkout-mobile-reverce">
                        <Col xl={12} md={12} sm={24}>
                            <CheckoutCourses setCourseDetails={setCourseDetails} />
                        </Col>
                        <Col className="billing-detail-form" xl={12} md={12} sm={24}>
                            {<div className="row">
                                {!isUserLoggedIn ? (
                                    <div className="col-xl-12 col-md-12">
                                        <p className="alreadyTitle">Already have an account ?<Button onClick={() => navigate("/login")}>Log in</Button></p>
                                    </div>
                                ) : (<CourseEnquiry course_details={courseDetails} />)}

                            </div>}

                        </Col>
                    </Row>
                </div>
            </div>
        </>
    );
}
