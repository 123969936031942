import React, { useEffect, useState } from "react";
import { FaRegCalendar } from "react-icons/fa";
import { BiSolidComment } from "react-icons/bi";
import { FaFacebookF } from "react-icons/fa";
import { FaPinterestP } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { RiInstagramFill } from "react-icons/ri";
import { FaYoutube } from "react-icons/fa";
import { Form, Input, Rate } from "antd";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as uploadServices from "../../services/uploadServices";
import * as getServices from "../../services/getServices";
import Swal from "sweetalert2";

export default function BlogDetailPage() {
    const userData = useSelector((state) => state.user.userData);
    const currentUrl = window.location.href;
    const { TextArea } = Input;
    const [form] = Form.useForm();
    let { state } = useLocation();
    const dispatch = useDispatch();
    const isUserLoggedIn = useSelector((state) => state.user.isUserLoggedIn);
    const isVendorLoggedIn = useSelector((state) => state.user.isVendorLoggedIn);

    const [blogRate, setBlogRate] = useState(0)
    const [blogComments, setBlogComments] = useState([])

    const onFinishFailed = (errorInfo) => {
        // console.log("Form failed:", errorInfo);
    };

    const getBlogComment = () => {
        if (state?.blogData?.id) {
            try {
                dispatch(getServices.getBlogCommentByBlogId(state?.blogData?.id)).then((response) => {
                    setBlogComments(response?.blogComments)
                }).catch((error) => {
                    console.error({ error })
                })
            } catch (e) {
                console.error({ e })
            }
        }
    }


    useEffect(() => {
        getBlogComment()
    }, [])

    const onFinish = (values) => {
        try {
            const formData = new FormData();
            formData.append("blog_id", state?.blogData?.id);
            formData.append("name", values?.fullName);
            formData.append("email", values?.email);
            formData.append("comment", values?.comment);
            formData.append("rating", blogRate);
            dispatch(uploadServices.addBlogComment(formData)).then((response) => {
                getBlogComment()
                form.resetFields()
                setBlogRate(0)
                Swal.fire(
                    "Success",
                    `${response?.message}`,
                    "success"
                )
            }).catch((error) => {
                Swal.fire(
                    "Failed",
                    `${error?.message}`,
                    "error"
                )
            })
        } catch (error) {
            console.error({ error })
        }
    };

    const DateFormatter = (date) => {
        const inputDate = date;

        const dateObj = new Date(inputDate);

        const monthNames = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
        ];

        const day = dateObj.getDate();
        const month = monthNames[dateObj.getMonth()];
        const year = dateObj.getFullYear();

        const formatDate = `${day} ${month} ${year}`;
        return formatDate;
    };

    const BlogContent = ({ content }) => {
        let formattedContent = content?.replace(/\n/g, '<br />');

        return (
            <div
                dangerouslySetInnerHTML={{
                    __html: formattedContent,
                }}
            />
        );
    };
    const openShareWindow = (url) => {
        window.open(
            url,
            'popup',
            'width=800,height=800,scrollbars=no,resizable=no'
        );
    };

    return (
        <>
            <div className="blog-details-container container">
                <p className="blog-heading">{state?.blogData?.title}</p>
                <div className="blog-created-date-comment">
                    <p className="blog-created-date">
                        <span>
                            <FaRegCalendar className="calendar-icon" />
                        </span>
                        {DateFormatter(state?.blogData?.created_at)}
                    </p>
                    <p className="blog-total-comments">
                        <span>
                            <BiSolidComment className="comment-icon" />
                        </span>
                        {blogComments.length}
                    </p>
                </div>
                <div className="blog-image-container">
                    <img className="blog-image" src={state?.blogData?.images[0]} />
                </div>
                <div className="blog-content-container">

                    <BlogContent content={state?.blogData?.content} />
                </div>
                <div className="socail-share-container">
                    <p className="share-text">Share:</p>
                    <div className="social-icon-container">
                        <p onClick={() => openShareWindow(`https://www.facebook.com/sharer/sharer.php?u=${currentUrl}`)}>
                            <FaFacebookF className="facebook" />
                        </p>
                        <p onClick={() => openShareWindow(`https://pinterest.com/pin/create/button/?url=${currentUrl}`)}>
                            <FaPinterestP className="pinterest" />
                        </p>
                        <p onClick={() => openShareWindow(`https://twitter.com/intent/tweet?url=${currentUrl}`)}>
                            <FaXTwitter className="twitter" />
                        </p>
                        <p onClick={() => openShareWindow(currentUrl)}>
                            <RiInstagramFill className="instagram" />
                        </p>
                        <p onClick={() => openShareWindow(currentUrl)}>
                            <FaYoutube className="youtube" />
                        </p>
                    </div>
                </div>

                {isUserLoggedIn || isVendorLoggedIn ? (
                    <div className="row form-row">
                        <div className="col-xl-6 col-md-10 form-col">
                            <div className="blog-form-container">
                                <p className="leave-comment-heading border-bottom-heading">Leave a comment</p>
                                <p className="blog-form-sub-heading">
                                    Your email address will not be published. Required fields are
                                    marked *
                                </p>
                                <Form
                                    name="blog-comment"
                                    onFinish={onFinish}
                                    onFinishFailed={onFinishFailed}
                                    initialValues={{
                                        fullName: userData?.user?.name,
                                        email: userData?.user?.email,

                                    }}
                                    form={form}
                                    className="blog-comment-form"
                                    autoComplete="off"
                                >
                                    <div className="row form-row">
                                        <div className="col-md-4 form-col">
                                            <Form.Item
                                                name="fullName"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Please enter full name.",
                                                    },
                                                ]}
                                            >
                                                <Input disabled placeholder="Name*" className="name-input" />
                                            </Form.Item>
                                        </div>
                                        <div className="col-md-8 form-col">
                                            <Form.Item
                                                name="email"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Please enter a valid email address.",
                                                    },
                                                ]}
                                            >
                                                <Input disabled type="email" placeholder="Email*" className="email-input" />
                                            </Form.Item>
                                        </div>
                                        <div className="col-md-12 form-col">
                                            <Form.Item name="comment" rules={[
                                                {
                                                    required: true,
                                                    message: "Please add comment.",
                                                },
                                            ]}>
                                                <TextArea
                                                    rows={4}
                                                    placeholder="Add Comment here..."
                                                    className="comment-input"
                                                />
                                            </Form.Item>
                                        </div>
                                        <div className="col-md-6 form-col">
                                            <Form.Item name="upload-button">
                                                <button type="submit" className="post-comment-button">
                                                    Post Comment
                                                </button>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </div>
                        <div className="col-xl-6 col-md-2 text-center ">
                            <div className="blog-form-container">
                                <p className="leave-comment-heading">Rating</p>
                            </div>

                            <Rate className="blog-detail-rating mt-5" onChange={(e) => setBlogRate(e)} />
                        </div>
                    </div>
                ) : (<></>)}

                <div className="blog-comments-container">
                    {blogComments?.length > 0 ? (
                        <div className="header d-flex justify-content-between align-items-center">
                            <p className="comment-heading border-bottom-heading">Comments</p>
                            <p className="total-comments">{blogComments.length} Comments</p>
                        </div>
                    ) : (<></>)}


                    {blogComments.length > 0 && blogComments.map((item) => (
                        <div className="comment-list-container">
                            <div className="row comment-row">
                                <div className="col-xl-1 col-md-2 comment-col">
                                    <img className="user-profile" src={item?.user?.images} />
                                </div>
                                <div className="col-xl-11 col-md-10 comment-col">
                                    <div className="d-flex justify-content-between align-items-center name-date-container">
                                        <p className="name">{item?.name}</p>
                                        <p className="date">{DateFormatter(item?.created_at)}</p>
                                    </div>
                                    <Rate disabled defaultValue={item?.rating} className="rating" />
                                    <p className="comment-text">
                                        {item?.comment}
                                    </p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
}
