import React, { useState, useEffect } from 'react';
import './styles/style.scss';
import { BrowserRouter } from "react-router-dom";
import AppRoute from "./routes/routes";
import { toast, ToastContainer } from "react-toastify";
import { onMessage } from "firebase/messaging";
import { messaging } from "./Utils/firebaseUtils";
import 'react-toastify/dist/ReactToastify.css';
import Message from './components/messages';
import Cookie from "./components/cookies/index";
import * as getServices from "./services/getServices";
import { useDispatch } from 'react-redux';
import {setNotification} from "./store/actions/user";
import { useSelector } from 'react-redux';
function App() {
  const dispatch = useDispatch();
  const [notificationCount, setNotificationCount] = useState(0);
  const isUserLoggedIn = useSelector((state) => state.user.isUserLoggedIn);
  const isVendorLoggedIn = useSelector((state) => state.user.isVendorLoggedIn);

  useEffect(() => {
    if(isUserLoggedIn || isVendorLoggedIn){
    onMessage(messaging, (payload) => {
      if (payload.notification) {
        try{
         dispatch(getServices.getNotifications()).then((response)=>{
             dispatch(setNotification(response?.data));
         }).catch((err)=>{
           console.error("Failed to fetch notifications",err);
         })
        }catch(err){
          console.error("Failed to get notificaion",err);
        }
        setNotificationCount(prevCount => prevCount + 1);
        toast(<Message notification={payload.notification} />);
      }
    });

    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.register('/firebase-messaging-sw.js')
        .then(function(registration) {
      
        }).catch(function(error) {
          // console.log('Service Worker registration failed:', error);
        });
    }
  }
  }, []);

  return (
    <>
    <BrowserRouter>
      <main className="wrapper">
        <ToastContainer />
          <AppRoute />
          <Cookie />
      </main>
    </BrowserRouter>
   
</>

  );
}

export default App;
