import React, { useState, useEffect, useContext } from "react";
import {
    Row,
    Col,
    Input,
    Button,
    Spin,
    Alert,
    message
} from "antd";
import { useSelector, useDispatch } from "react-redux";
import { featureImg2 } from "../../constant/images";
import { RiDeleteBin6Line } from "react-icons/ri";
import { removeFromCart } from "../../store/actions/user";
import Swal from "sweetalert2";
import * as UploadServices from "../../services/uploadServices";
import { LoadingOutlined } from "@ant-design/icons";
import * as getServices from "../../services/getServices";
import * as updateServices from "../../services/updateServices";
import { useAtom } from "jotai";
import { cartCount } from "../../Helpers/atoms/freeCoursesAtom"
import { CartContext } from '../../Helpers/CartContext';
import { useNavigate } from "react-router-dom";

export default function CheckoutCourses({setCourseDetails }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isUserLoggedIn = useSelector((state) => state.user.isUserLoggedIn);
    const isVendorLoggedIn = useSelector((state) => state.user.isVendorLoggedIn);
    const cartItems = useSelector((state) => state.user.cart);
    const totalAmount = useSelector((state) => state.user.total_amount);
    const { addItemToCart } = useContext(CartContext);


    // Store coupon state for each item
    const [couponData, setCouponData] = useState({});
    const [cartData, setCartData] = useState([])
    const [cartCountValue, setCartCountValue] = useAtom(cartCount);


    const fetchCartItems = async () => {
        try {
            const response = await dispatch(getServices.getCartData());
            if (response?.data?.length > 0) {
                let cartDataItems = []
                response?.data?.map((item) => {
                    cartDataItems.push({
                        course_id: item?.course?.id,
                        vendor_id: item?.course?.vendor_id
                    })
                })

                setCourseDetails(cartDataItems)
            } else {
                setCourseDetails([])
            }
            addItemToCart(response?.data?.length);
            setCartCountValue(response?.data?.length || 0)
            setCartData(response?.data || []);
        } catch (error) {
            console.error("Error fetching cart data", error);
        }
    };

    useEffect(() => {
        if (isUserLoggedIn) {
            fetchCartItems();
        } else {
            addItemToCart(cartItems?.length)
        }
    }, [isUserLoggedIn]);

    const handleRemoveFromCart = (id) => {
        Swal.fire({
            title: "Remove Item",
            text: "Are you sure you want to remove?",
            icon: "info",
            showCancelButton: true,
            confirmButtonText: "Yes, remove it!",
            cancelButtonText: "No, keep it",
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(removeFromCart(id));
                message.success("Item removed successfully.");
            }
        }).catch((err) => { });
    };

    const totalFee = cartData?.reduce((acc, curr) => acc + parseFloat(curr?.course?.fee || 0), 0);

    const handleApplyCoupon = (id, fee) => {
        const formData = new FormData();
        const couponCode = couponData[id]?.code;

        if (couponCode) {
            setCouponData((prev) => ({
                ...prev,
                [id]: { ...prev[id], loader: true },
            }));

            formData.append("coupon_code", couponCode);
            formData.append("purchase_amount", fee);

            dispatch(UploadServices.applyCoupon(formData))
                .then((response) => {
                    setCouponData((prev) => ({
                        ...prev,
                        [id]: {
                            ...prev[id],
                            loader: false,
                            discount: response.discount,
                            inputDisplay: false,
                            finalAmount: response.final_amount
                        },
                    }));
                })
                .catch(() => {
                    setCouponData((prev) => ({
                        ...prev,
                        [id]: { ...prev[id], loader: false },
                    }));
                    Swal.fire(
                        "Coupon Code",
                        "Invalid coupon code or coupon expired.",
                        "error"
                    );
                });
        } else {
            Swal.fire("Coupon Code", "Please enter coupon code.", "info");
        }
    };

    const handleInputChange = (id, value) => {
        setCouponData((prev) => ({
            ...prev,
            [id]: { ...prev[id], code: value },
        }));
    };

    const handleCloseAlert = (id) => {
        setCouponData((prev) => ({
            ...prev,
            [id]: { code: null, discount: null, inputDisplay: true },
        }));
    };

    const totalDiscount = Object.values(couponData).reduce((acc, curr) => {
        if (cartData.length === 0) {
            return 0;
        } else {
            return parseFloat(acc) + (parseFloat(curr.discount) || 0);
        }
    }, 0);

    const totalDue = ((isUserLoggedIn || isVendorLoggedIn) && totalFee ? totalFee : (!isUserLoggedIn && !isVendorLoggedIn && totalAmount) ? totalAmount : 0) - totalDiscount;

    const handleRemoveItem = (id) => {
        try {
            Swal.fire({
                title: "Remove Item",
                text: "Are you sure you want to remove?",
                icon: "info",
                showCancelButton: true,
                confirmButtonText: "Yes, remove it!",
                cancelButtonText: "No, keep it",
            }).then((result) => {
                if (result.isConfirmed) {
                    dispatch(updateServices.removeCartItem(id)).then((response) => {
                        if (isUserLoggedIn) {
                        fetchCartItems();
                        }
                        message.success(`${response.message}`);

                    }).catch((err) => {
                        message.error(`${err.message}`);
                    })
                }
            }).catch((err) => { });
        } catch (e) {
            Swal.fire(
                "Error",
                `${e.message}`,
                "error"
            );
        }
    }

    const handleCourseData = (values) => {
        console.log(values , "values")
                navigate(`/course/${values?.id}/${values?.title}`, {
                    state: { course_id: values?.id },
                });
            };

    return (
        <>
            <div className="checkout-cart-section">
                <div className="row">
                    <div className="col-xl-12 col-md-12">
                        <h4 className="formTitle">Order Summary</h4>
                    </div>
                </div>
                <Row className="checkout-cart-row">
                    <Col className="cart-items-col" xl={24} md={24}>
                        <Row className="product-items-row">
                            {isUserLoggedIn  && cartData && cartData.length > 0 ? (
                                cartData.map((item) => (
                                    <Col
                                        className="shop-cart-items-content"
                                        xl={24}
                                        md={24}
                                        key={item?.id}
                                    >
                                        <Row>
                                            <Col className="cart-items-col px-2" md={16} sm={24}>
                                                <div className="product-detail single-pro-items d-flex">
                                                    <div className="item-image-thumb">
                                                        <img
                                                            src={item?.image ? item?.image : item?.course?.image ? item?.course?.image : featureImg2}
                                                            alt="Course Image"
                                                        />
                                                    </div>
                                                    <div className="item-content-tab">
                                                        {
                                                            item?.course?.parent_category_name !== null ? <h5 className="main-category mb-0">{item?.course?.parent_category_name}</h5 > : ''
                                                        }
                                                        
                                                        <h4>{item?.course?.title}</h4>
                                                        <p>{item?.course?.about_course}</p>
                                                        {isUserLoggedIn && couponData[item.id]?.inputDisplay !== false && (
                                                            <div className="coupon-codes">
                                                                <Input
                                                                    onChange={(e) => handleInputChange(item?.id, e.target.value)}
                                                                />
                                                                <Button
                                                                    onClick={() => handleApplyCoupon(item?.id, item?.course?.fee)}
                                                                    type="button"
                                                                >
                                                                    {couponData[item.id]?.loader ? (
                                                                        <Spin indicator={<LoadingOutlined spin />} />
                                                                    ) : (
                                                                        "Apply"
                                                                    )}
                                                                </Button>
                                                            </div>
                                                        )}
                                                        {couponData[item.id]?.discount && (
                                                            <Alert
                                                                className="couponRibbon mb-4"
                                                                message={
                                                                    <>
                                                                        <p className="couponText">{couponData[item.id]?.code}</p>
                                                                        <span>
                                                                            {couponData[item.id]?.code} - $
                                                                            {couponData[item.id]?.discount}
                                                                        </span>
                                                                    </>
                                                                }
                                                                type="success"
                                                                showIcon
                                                                closable
                                                                onClose={() => handleCloseAlert(item?.id)}
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col className="cart-items-col px-2" md={4} sm={24}>
                                                <div className="product-price">
                                                    {couponData[item.id]?.finalAmount
                                                        ? `$${couponData[item.id]?.finalAmount}`
                                                        : `$${item?.course?.fee}`}
                                                </div>
                                            </Col>
                                            <Col className="cart-items-col" md={2} sm={24}>
                                                <div className="remove-item">
                                                    <RiDeleteBin6Line
                                                        style={{ cursor: "pointer" }}
                                                        onClick={() => handleRemoveItem(item?.id)}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                ))
                            ) : !isUserLoggedIn && !isVendorLoggedIn && cartItems && cartItems.length > 0 ? (
                                cartItems.map((item) => (
                                    <Col
                                        className="shop-cart-items-content"
                                        xl={24}
                                        md={24}
                                        key={item?.id}
                                    >
                                        <Row>
                                            <Col className="cart-items-col px-2" md={16} sm={24}>
                                                <div className="product-detail single-pro-items d-flex">
                                                    <div className="item-image-thumb">
                                                        <img
                                                            src={item?.image ? item?.image : featureImg2}
                                                            alt="Course Image"
                                                        />
                                                    </div>
                                                    <div className="item-content-tab">
                                                        <h6>{item?.title}</h6>
                                                        <p>{item?.about_course}</p>

                                                        {isUserLoggedIn ? (
                                                            <>
                                                                {couponData[item.id]?.inputDisplay !== false && (
                                                                    <div className="coupon-codes">
                                                                        <Input
                                                                            onChange={(e) =>
                                                                                handleInputChange(item?.id, e.target.value)
                                                                            }
                                                                        />
                                                                        <Button
                                                                            onClick={() =>
                                                                                handleApplyCoupon(item?.id, item?.fee)
                                                                            }
                                                                            type="button"
                                                                        >
                                                                            {couponData[item.id]?.loader ? (
                                                                                <Spin
                                                                                    indicator={<LoadingOutlined spin />}
                                                                                />
                                                                            ) : (
                                                                                "Apply"
                                                                            )}
                                                                        </Button>
                                                                    </div>
                                                                )}

                                                                {couponData[item.id]?.discount && (
                                                                    <Alert
                                                                        className="couponRibbon mb-4"
                                                                        message={
                                                                            <>
                                                                                <p className="couponText">
                                                                                    {couponData[item.id]?.code}
                                                                                </p>
                                                                                <span>
                                                                                    {couponData[item.id]?.code} - $
                                                                                    {couponData[item.id]?.discount}
                                                                                </span>
                                                                            </>
                                                                        }
                                                                        type="success"
                                                                        showIcon
                                                                        closable
                                                                        onClose={() => handleCloseAlert(item?.id)}
                                                                    />
                                                                )}
                                                            </>
                                                        ) : (
                                                            <></>
                                                        )}
                                                    </div>
                                                </div>
                                            </Col>

                                            <Col className="cart-items-col px-2" md={4} sm={24}>
                                                <div className="product-price">
                                                    {couponData[item.id]?.finalAmount
                                                        ? `$${couponData[item.id]?.finalAmount}`
                                                        : `$${item?.fee}`}
                                                </div>
                                            </Col>
                                            <Col className="cart-items-col" md={2} sm={24}>
                                                <div className="remove-item">
                                                    <RiDeleteBin6Line
                                                        style={{ cursor: "pointer" }}
                                                        onClick={() => handleRemoveFromCart(item?.id)}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                ))
                            ) : (
                                <p>No items in the cart.</p>
                            )}
                        </Row>
                    </Col>
                </Row>

                <div className="totalSection mt-4">
                    <div className="totalList">
                        <span className="leftText">Subtotal</span>
                        <span className="rightText">${isUserLoggedIn || isVendorLoggedIn ? totalFee : totalAmount}</span>
                    </div>
                    <div className="totalList">
                        <span className="leftText">Coupon Discount</span>
                        <span className="rightText discountText">-${totalDiscount}</span>
                    </div>
                    <div className="totalList">
                        <span className="leftText dueText">Total Amount</span>
                        <span className="rightText dueText">${totalDue}</span>
                    </div>
                </div>
            </div>
        </>
    );
}
