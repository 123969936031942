import React, { useEffect, useState, useMemo } from "react";
import {
    Row,
    Col,
    Table,
    Rate,
    Select,
    Image,
    Carousel,
    Pagination,
    Tooltip,
    Modal,
    Button,
    Spin,
} from "antd";
import Sidebar from "../../components/Sidebar/sidebar";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { SalesSummaryChart } from "../../Helpers/salesSummaryChart";
import { LoadingOutlined } from "@ant-design/icons";
import { Avatar } from "../../constant/images";
import { useDispatch, useSelector } from "react-redux";
import * as getServices from "../../services/getServices";
import * as uploadService from "../../services/uploadServices";
import { Circle } from "rc-progress";
import { LuEye } from "react-icons/lu";
import { useNavigate } from "react-router-dom";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import { StudentEnrollmentChart } from "../../Helpers/studentEnrollmentChart";

const VendorDashboard = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const pageSize = 5;
    const vendorData = useSelector((state) => state.user.userData);
    const categories = useSelector((state) => state.user.categoriesData);
    const [courseList, setCourseList] = useState();
    const [categoryId, setCategoryId] = useState();
    const [filterType, setFilterType] = useState(0);
    const [interval, setInterval] = useState(0);
    const [courseDetail, setCourseDetail] = useState();
    const [totalStudent, setTotalStudent] = useState([]);
    const [reviewDetail, setReviewDetail] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [salesData, setSalesData] = useState();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalContent, setModalContent] = useState(null);
    const [loader, setLoader] = useState(true);
    const [paymentData, setPaymentData] = useState([]);
    const [years, setYears] = useState([]);


    const categoryList = useMemo(() => categories.map((item) => ({
        label: item.name,
        value: item.id,
    })), []);

    useEffect(() => {
        dispatch(getServices?.getCourseByVendorID(vendorData?.vendor?.id))
            .then((response) => {
                setCourseDetail(response?.courses);
                if (response?.courses) {
                    const transformedOptions = response?.courses.map((item) => ({
                        label: item.title,
                        value: item.id,
                    }));
                    setCourseList(transformedOptions);
                }
            })
            .catch((err) => {
                // console.log({ err });
            });
console.log(vendorData?.vendor?.id ,"dashfg")
        dispatch(getServices?.getReviews(vendorData?.vendor?.id))
            .then((response) => {
                setReviewDetail(response.reviews);
            })
            .catch((err) => {
                // console.log({ err });
            });
        // totalEnrolledStudent(categoryList?categoryList[0]?.value:'', "category");
    }, [vendorData?.vendor?.id]);



    useEffect(() => {
        dispatch(getServices?.getVendorPayments())
            .then((response) => {
                setPaymentData(response.data);
            })
            .catch((err) => {
                // console.log({ err });
            });

    }, []);

    const handleChangePage = (page) => {
        setCurrentPage(page);
    };
    const DateFormatter = (date) => {
        const inputDate = date;

        const dateObj = new Date(inputDate);

        const monthNames = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
        ];

        const day = dateObj.getDate();
        const month = monthNames[dateObj.getMonth()];
        const year = dateObj.getFullYear();

        const formatDate = `${day} ${month} ${year}`;
        return formatDate;
    };

    const handleViewClick = (record) => {
        setModalContent(record);
        setIsModalVisible(true);
    };

    const handleModalClose = () => {
        setIsModalVisible(false);
        setModalContent(null);
    };
    const handleCourseNameClick = (courseId) => {
        navigate(`/vendor/view-course-detail`, { state: { data: courseId } });
    };

    const columns = [
        {
            title: "S.No",
            dataIndex: "S.No",
            key: "S.No",
            render: (text, record, index) => index + 1,
        },
        {
            title: "Item Name",
            key: "Course Name",
            sorter: (a, b) => a.course_name.localeCompare(b.course_name),
            render: (_, record, index) => (
                <span
                    style={{ cursor: "pointer" }}
                    onClick={() => handleCourseNameClick(record?.course?.id)}
                >
                    {record?.course_name}
                </span>
            ),
        },
        {
            title: "Booking No.",
            dataIndex: "Booking No.",
            key: "Booking No.",
            sorter: (a, b) => a.id - b.id, // Sort by ID
            render: (text, record, index) =>
                `#${(record?.id).toString().padStart(4, "0")}`,
        },
        {
            title: "Amount",
            key: "Amount",
            dataIndex: "amount",
            sorter: (a, b) => a.amount - b.amount, // Sort by course_fee
        },
        {
            title: "Start Date",
            key: "Start Date",
            sorter: (a, b) => new Date(a.created_at) - new Date(b.created_at), // Sort by created_at date
            render: (_, record, index) => {
                return DateFormatter(record?.created_at);
            },
        },
        {
            title: "Action",
            dataIndex: "action",
            key: "action",
            render: (_, record) => (
                <div className="action-container">
                    <Tooltip key={record.id} title="View and Manage">
                        <button
                            className="btn btnView me-2"
                            onClick={() => handleViewClick(record)}
                        >
                            <LuEye />
                        </button>
                    </Tooltip>
                </div>
            ),
        },
    ];

    const enrolledSelect = [
        { value: 1, label: <p className="weekly-dropdown">Weekly</p> },
        { value: 0, label: <p className="monthly-dropdown">Monthly</p> },
        { value: 2, label: <p className="yearly-dropdown">Yearly</p> },
    ];

    const handleChange = async (value, type) => {
        let data = {
            period: type == "filterType" ? value : filterType,
            pericularcourse: type == 'courseId' ? value : ''

        };
        try {
            const response = await dispatch(uploadService.getSalesData(data));
            setLoader(false)
            setSalesData(response);
        } catch (err) {
            setLoader(false)
            console.error({ err });
        }
    };



    const getLastTenYears = () => {
        const currentYear = new Date().getFullYear();
        const years = [];
        for (let i = 0; i < 10; i++) {
            years.push(currentYear - i);
        }
        return years;
    };
    useEffect(() => {
        const res = getLastTenYears();
        const yearsOptions = res?.map((year) => ({
            label: year,
            value: year,
        }));
        setYears(yearsOptions);
    }, []);

    const totalEnrolledStudent = async (value, type) => {
        console.log(value, "value")
        console.log(interval, "intevale")
        console.log(categoryId, "categoryId")
        let data = {
            interval: type == "interval" ? value : interval,
            category: type == "category" ? value : categoryId,
        };
        if (data.category) {

            try {
                console.warn({ data })
                dispatch(uploadService.getEnrollmentsData(data)).then((res) => {
                    console.info({ res })
                    setTotalStudent(res)
                }).catch((err) => {
                    console.info({ err })
                });

                // setTotalStudent(response);
            } catch (err) {
                console.log("helrfdg")
                console.error({ err });
            }
        }
        else {
            console.error("Please select both interval and category");
        }

    };

    useEffect(() => {
        handleChange(0, "filterType");

    }, []);

    const calculateAverageRating = (data) => {
        const totalRatings = data?.reduce(
            (acc, item) => acc + parseFloat(item.rating),
            0
        );
        const averageRating = totalRatings / data?.length;
        const averagePercentage = (averageRating / 5) * 100;
        return averagePercentage;
    };

    const averageRating = calculateAverageRating(reviewDetail);
    const exportToExcel = (data, fileName) => {
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        const excelBuffer = XLSX.write(workbook, {
            bookType: "xlsx",
            type: "array",
        });
        const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
        saveAs(blob, `${fileName}.xlsx`);
    };
    const transformData = (data) => {
        return data?.map((item, index) => ({
            "S.NO": index + 1,
            "Course Name": item.course_name,
            "Course Fee": item.amount,
            "User name": item.user_name,
            "User email": item.user_email,
        }));
    };
    const hasPendingContent = courseDetail?.some(content => content.status === "pending");
    return (
        <>
            <Row>
                <Col xl={4} className="d-xl-block d-md-none">
                    <Sidebar />
                </Col>
                <Col xl={20} md={24}>
                    <div className="mainContent mainvendor-dshboard">
                        <Row className="vendor-dashboard">
                            <Col className="vendor-dashboard-child-one" md={12}>
                                <div className="vendor-dashboard-child-one-container">
                                    <div className="post-booking-container">
                                        <div className="download-courses-container">
                                            <p className="post-booking-head">Recent Bookings</p>
                                            <Button
                                                className="download-courses-button"
                                                onClick={() =>
                                                    exportToExcel(transformData(paymentData), "Booking data")
                                                }
                                            >
                                                {loader ? (
                                                    <Spin
                                                        indicator={
                                                            <LoadingOutlined style={{ color: "#fff" }} spin />
                                                        }
                                                        size="small"
                                                    />
                                                ) : (
                                                    "Download Items in xlsx/csv"
                                                )}
                                            </Button>
                                        </div>
                                        <div className="post-booking-table-container table-responsive">
                                            <Table
                                                className="post-booking-table"
                                                columns={columns}
                                                dataSource={paymentData}
                                                pagination={false}
                                            />
                                            <Pagination
                                                className="mt-4"
                                                current={currentPage}
                                                pageSize={pageSize}
                                                // total={totalItems}
                                                onChange={handleChangePage}
                                            />
                                        </div>
                                    </div>
                                    <div className="sales-summary-container">
                                        <Row className="sales-summary-row">
                                            <Col className="sales-summary-col-1" md={12}>
                                                <p className="sales-summary-head">Sales Summary</p>
                                            </Col>
                                            <Col className="sales-summary-col-2 d-flex justify-content-around ">
                                                <Select
                                                    className="course-enrolled-inner-select me-2"
                                                    placeholder="Select the items"
                                                    options={courseList}
                                                    onChange={(value) => {
                                                        handleChange(value, "courseId");
                                                    }}
                                                />
                                                <Select
                                                    className="total-students-enrolled-inner-select"
                                                    defaultValue={'Monthly'}
                                                    options={enrolledSelect}
                                                    onChange={(value) => {
                                                        setFilterType(value);
                                                        handleChange(value, "filterType");
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                        {loader ? (
                                            <Spin />
                                        ) : salesData?.length ? (
                                            <HighchartsReact
                                                highcharts={Highcharts}
                                                options={SalesSummaryChart(salesData, filterType)}
                                            />
                                        ) : (
                                            <p>No data Found</p>
                                        )}
                                    </div>
                                    <Carousel>
                                        {reviewDetail?.length ? reviewDetail?.map((item) => {
                                            return (
                                                <div className="vendor-reviews-ratings-container">
                                                    <Row className="vendor-reviews-row">
                                                        <Col className="vendor-reviews-col" md={12}>
                                                            <p className="vendor-reviews-ratings-head">
                                                                Ratings & Reviews
                                                            </p>
                                                            <p className="vendor-reviews-ratings-date">
                                                                {DateFormatter(item?.created_at)}
                                                            </p>
                                                            <p className="vendor-reviews-ratings-p-rate">
                                                                <Rate
                                                                    allowHalf={false}
                                                                    defaultValue={parseInt(item?.rating)}
                                                                />
                                                            </p>
                                                        </Col>
                                                        <Col md={12}>
                                                            <Row className="vendor-reviews-pie-row">
                                                                {averageRating >= 36 ? (
                                                                    <Col md={8}>
                                                                        <Circle
                                                                            percent={averageRating}
                                                                            strokeWidth={10}
                                                                            strokeColor="#1EAA39"
                                                                            trailWidth={10}
                                                                            style={{ width: 80, height: 80 }}
                                                                        />
                                                                        <div className="exclent"
                                                                            style={{
                                                                                position: "absolute",
                                                                                top: "36%",
                                                                                left: "40%",
                                                                                transform: "translate(-50%, -50%)",
                                                                                fontSize: "16px",
                                                                                fontWeight: "bold",
                                                                            }}
                                                                        >
                                                                            {`${averageRating}%`}
                                                                        </div>
                                                                        <p className="pie-excelent">Excellent</p>
                                                                    </Col>
                                                                ) : averageRating >= 36 ? (
                                                                    <Col md={8}>
                                                                        <Circle
                                                                            percent={averageRating}
                                                                            strokeWidth={10}
                                                                            strokeColor="#F4BF08"
                                                                            trailWidth={10}
                                                                            style={{ width: 80, height: 80 }}
                                                                        />
                                                                        <div
                                                                            className="positive"
                                                                            style={{
                                                                                position: "absolute",
                                                                                top: "36%",
                                                                                left: "40%",
                                                                                transform: "translate(-50%, -50%)",
                                                                                fontSize: "16px",
                                                                                fontWeight: "bold",
                                                                            }}
                                                                        >
                                                                            {`${averageRating}%`}
                                                                        </div>
                                                                        <p className="pie-positive">Positive</p>
                                                                    </Col>
                                                                ) : (
                                                                    <Col md={8}>
                                                                        <Circle
                                                                            percent={averageRating}
                                                                            strokeWidth={10}
                                                                            strokeColor="#DA0734"
                                                                            trailWidth={10}
                                                                            style={{ width: 80, height: 80 }}
                                                                        />
                                                                        <div
                                                                            className="negative"
                                                                            style={{
                                                                                position: "absolute",
                                                                                top: "36%",
                                                                                left: "40%",
                                                                                transform: "translate(-50%, -50%)",
                                                                                fontSize: "16px",
                                                                                fontWeight: "bold",
                                                                            }}
                                                                        >
                                                                            {`${averageRating}%`}
                                                                        </div>
                                                                        <p className="pie-negative">Negative</p>
                                                                    </Col>
                                                                )}
                                                            </Row>
                                                        </Col>
                                                    </Row>

                                                    <p className="vendor-reviews-ratings-para">
                                                        {item?.comment}
                                                    </p>

                                                    <div className="vendor-rating-profile-div">
                                                        <div className="image-conatiner">
                                                            <Image
                                                                className="reviewer-image"
                                                                width={37}
                                                                height={37}
                                                                src={item?.user?.images ?? Avatar}
                                                            />
                                                        </div>
                                                        <div className="name-designation-container">
                                                            <p className="reviewer-image">{item?.user?.name}</p>
                                                            <p className="reviewer-designation">
                                                                {item?.course?.category?.name}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        }) : ""}

                                    </Carousel>
                                </div>
                            </Col>
                            <Col className="vendor-dashboard-child-two" md={12}>
                                <Row className="total-students-enrolled-row">
                                    <Col className="total-students-enrolled-container" md={24}>
                                        <Row className="total-students-enrolled-inner-container">
                                            <Col md={12} className="total-students-container">
                                                <p className="total-students-enrolled-head">
                                                    Total Student Enrolled
                                                </p>
                                            </Col>
                                            <Col md={12} className="select-options-container">
                                                <Select
                                                    className="total-students-enrolled-inner-select"
                                                    defaultValue={"Monthly"}
                                                    options={enrolledSelect}
                                                    placement="bottomLeft"
                                                    onChange={(value) => {
                                                        setInterval(value)
                                                        totalEnrolledStudent(value, "interval");
                                                    }}
                                                />
                                                <Select
                                                    className="total-students-enrolled-inner-select"
                                                    placeholder="Select"
                                                    // defaultValue={`${categoryList?categoryList[0]?.label:''}`}
                                                    options={categoryList}
                                                    placement="bottomLeft"
                                                    onChange={(value) => {
                                                        setCategoryId(value)
                                                        totalEnrolledStudent(value, "category");
                                                    }}
                                                />
                                            </Col>
                                            <Col md={24} className="highchart-container">
                                                {totalStudent?.length ? (
                                                    <HighchartsReact
                                                        highcharts={Highcharts}
                                                        options={StudentEnrollmentChart(totalStudent, interval)}
                                                    />
                                                ) : (
                                                    ""
                                                )}
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row className="pending-for-approval-outer-container">
                                    <Col className="pending-for-approval-box" md={24}>
                                        <p className="approval-heading">Pending For Approval</p>
                                        <div className="approval-row-outer" id="style-1">
                                            {hasPendingContent ? (
                                                courseDetail.map((content) => {
                                                    if (content.status === "pending") {
                                                        return (
                                                            <Row className="approval-row" key={content.id}>
                                                                <Col className="approval-image-col" md={5}>
                                                                    <Image
                                                                        preview={false}
                                                                        className="approval-image"
                                                                        src={content?.image}
                                                                    />
                                                                </Col>
                                                                <Col className="approval-content" md={19}>
                                                                    <p className="approval-course">
                                                                        {content?.category?.name}
                                                                    </p>
                                                                    <p className="approval-description">
                                                                        {content?.title}
                                                                    </p>
                                                                </Col>
                                                            </Row>
                                                        );
                                                    }
                                                    return null;
                                                })
                                            ) : (
                                                <p>No data found</p>
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>

            <Modal
                title="View Booking Detail"
                open={isModalVisible}
                onOk={handleModalClose}
                onCancel={handleModalClose}
                className="booking-detail-model"
            >
                <p>
                    <span>Name:</span> {modalContent?.user_name}
                </p>
                <p>
                    <span>Email:</span> {modalContent?.user_email}
                </p>
                <p>
                    <span>Items Name:</span> {modalContent?.course_name}
                </p>
                <p>
                    <span>Items Fee:</span> {modalContent?.amount}
                </p>
                <p>
                    <span>Purchase Date:</span>{" "}
                    {DateFormatter(modalContent?.created_at)}
                </p>
                <p>
                    <span>Rewards Points</span> {modalContent?.rewards?.points}
                </p>
            </Modal>
        </>
    );
};

export default VendorDashboard;
